import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import Header from "../../student_registation/components/Header";
import cities from "../../student_registation/json_datas/countries+states.json";
import { setprogramSearchObjects } from "../../../redux/Test/TestSlice";
import { Select, Space, Tooltip, Button } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import { studentDashboardPageChange } from "../../../redux/studentDashboardPage/StudentDasboardSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SearchPrograms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { totalUniversity, major_subjects } = useSelector(
    (store) => store.testManagement
  );

  const [selectedUniversity, setselectedUniversity] = useState([]);
  const [selectedUniversityType, setselectedUniversityType] = useState([]);
  const [selectedProgram, setselectedProgram] = useState([]);
  const [selectedProgramError, setselectedProgramError] = useState("");
  const [buttonPress, setbuttonPress] = useState(false);
  const [selectedStates, setselectedStates] = useState([]);
  const [showStates, setshowStates] = useState([]);

  const [disable, setdisable] = useState(false);

  const [selectedPrograms, setSelectedPrograms] = useState([]);

  useEffect(() => {
    if (selectedUniversity.length > 0) {
      setdisable(true);
    } else {
      setdisable(false);
    }
  }, [selectedUniversity]);

  const universityType = [
    { id: "1", name: "Public", showname: "Public" },
    {
      id: "2",
      name: "Private, nonprofit",
      showname: "Private, not-for-profit",
    },
    { id: "3", name: "Private, for-profit", showname: "Private, for-profit" },
  ];

  const universityoptions = [];
  for (let i = 0; i < totalUniversity.length; i++) {
    const value = totalUniversity[i].universityName;
    universityoptions.push({
      label: value,
      value,
    });
  }

  const handleUniversityChange = (event) => {
    setselectedUniversity(event);
  };

  let show_programs = [];
  const programoptions = [];
  const program_sort = [...major_subjects].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  for (let i = 0; i < program_sort.length; i++) {
    const value = program_sort[i].name;
    programoptions.push({
      label: value,
      value,
    });
  }

  const handleProgramChange = (value) => {
    setselectedProgram(value);
  };
  const universityTypeoptions = [];
  for (let i = 0; i < universityType.length; i++) {
    const value = universityType[i].showname;
    universityTypeoptions.push({
      label: value,
      value,
    });
  }
  const handleuniversityTypeChange = (value) => {
    setselectedUniversityType(value);
  };
  const stateoptions = [];
  for (let i = 0; i < showStates.length; i++) {
    const value = showStates[i].name;
    stateoptions.push({
      label: value,
      value,
    });
  }
  const handlesatateChange = (value) => {
    setselectedStates(value);
  };

  const filterOption = (input, option) =>
    option.label.toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const filterStates = cities.find((data) => data.name === "United States");
    setshowStates(filterStates.states);
  }, [cities]);
  useEffect(() => {
    if (buttonPress === true) {
      if (
        selectedProgram.length > 0 ||
        selectedUniversity.length > 0 ||
        selectedStates.length > 0 ||
        selectedUniversityType.length > 0
      ) {
        setselectedProgramError("");
      } else {
        setselectedProgramError("Choose any one option");
      }
    }
  }, [
    buttonPress,
    selectedProgram,
    selectedUniversity,
    selectedUniversityType,
    selectedStates,
  ]);

  const pressProgramFetch = () => {
    setbuttonPress(true);
    if (
      selectedProgram.length > 0 ||
      selectedUniversity.length > 0 ||
      selectedStates.length > 0 ||
      selectedUniversityType.length > 0
    ) {
      setselectedProgramError("");
    } else {
      setselectedProgramError("Choose any one option");
    }
    if (
      selectedProgram.length !== 0 ||
      selectedUniversity.length !== 0 ||
      selectedStates.length !== 0 ||
      selectedUniversityType.length !== 0
    ) {
      const get_programs = program_sort.filter((element) =>
        selectedProgram.includes(element.name)
      );
      const get_states = showStates.filter((element) =>
        selectedStates.includes(element.name)
      );
      let universityStates = [];
      for (let i = 0; i < get_states.length; i++) {
        let value = get_states[i].state_code;
        universityStates = [...universityStates, value];
      }
      const formdata = {
        selectedUniversity: selectedUniversity,
        selectedUniversityType: selectedUniversityType,
        selectedProgram: selectedProgram,
        selectedStates: selectedStates,
        program_selected: get_programs,
        states_selected: universityStates,
      };
      dispatch(setprogramSearchObjects(formdata));
      dispatch(studentDashboardPageChange(11));
      // navigate("/results/match-programs");
      setbuttonPress(false);
    }
  };

  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Program Search"
            description="Searching for programs or institutions is made easy. Please choose at least one option to commence your search. We encourage you to research many suitable programs."
          />

          <div className="inputs_container">
            <div className="dn_input_box row_direction_coloum">
              <span className="radio_title">Name of University</span>
              <Select
                mode="multiple"
                status={selectedProgramError !== "" ? "error" : ""}
                maxTagCount="responsive"
                className="input_style"
                style={{
                  height: "7.3vh",
                  width: "100%",
                }}
                placeholder="Choose University"
                options={universityoptions}
                onChange={(e) => handleUniversityChange(e)}
                filterOption={filterOption}
                size="large"
              />
            </div>
            <div className="dn_input_box row_direction_coloum">
              <span className="radio_title">Program Major</span>
              <Select
                mode="multiple"
                status={selectedProgramError !== "" ? "error" : ""}
                maxTagCount="responsive"
                style={{
                  width: "100%",
                  height: "7.3vh",
                }}
                placeholder="Choose Program Major"
                options={programoptions}
                filterOption={filterOption}
                onChange={(e) => handleProgramChange(e)}
                size="large"
              />
            </div>
            <div className="dn_input_box row_direction_coloum">
              <span className="radio_title"> Type of University</span>
              <Select
                mode="multiple"
                status={selectedProgramError !== "" ? "error" : ""}
                maxTagCount="responsive"
                style={{
                  width: "100%",
                  height: "7.3vh",
                }}
                placeholder="Choose Type of University"
                options={universityTypeoptions}
                onChange={(e) => handleuniversityTypeChange(e)}
                size="large"
                disabled={disable}
              />
            </div>
            <div className="dn_input_box row_direction_coloum">
              <span className="radio_title">State</span>
              <Select
                mode="multiple"
                status={selectedProgramError !== "" ? "error" : ""}
                maxTagCount="responsive"
                style={{
                  width: "100%",
                  height: "7.3vh",
                }}
                placeholder="Choose State"
                options={stateoptions}
                filterOption={filterOption}
                onChange={(e) => handlesatateChange(e)}
                size="large"
                disabled={disable}
              />
            </div>
            <div className="button_container">
              <Button
                type="primary"
                // loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={() => {
                  pressProgramFetch();
                }}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPrograms;
