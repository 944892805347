import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { Autoplay, FreeMode, Mousewheel, Pagination } from "swiper/modules";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom/dist";
import moment from "moment/moment";

const OurBlogs = () => {
  const { blogList } = useSelector((store) => store.Blog);
  return (
    <>
      <section className="h8_blog-area pt-110 pb-30">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-8 col-md-8">
              <div className="section-area-8 mb-20">
                <span className="section-subtitle">Blogs</span>
                <h2 className="section-title mb-0">Trending Topics</h2>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="h8_blog-navigation mb-30">
                <div className="h8_blog-prev">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 7H1"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 1L1 7L7 13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="h8_blog-next">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 7H13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 1L13 7L7 13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <Swiper
                  slidesPerView={4}
                  spaceBetween={10}
                  freeMode={true}
                  // pagination={{
                  //   clickable: false,
                  // }}
                  modules={[Pagination, Mousewheel, Autoplay]}
                  loop={true}
                  // autoplay={{
                  //   delay: 1500,
                  //   disableOnInteraction: false,
                  //   pauseOnMouseEnter: true,
                  // }}
                  breakpoints={{
                    360: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    460: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    720: {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    1080: {
                      slidesPerView: 4,
                      // spaceBetween: 5,
                    },
                  }}
                  className="mySwiper p-2"
             
              >
                {blogList.map((individualBlog, index) => (
                  <SwiperSlide key={index}>
                    <div className="swiper-slide">
                      <div className="event-item">
                        <div
                          className="event-img"
                          style={{ minHeight: "155px", maxHeight: "155px" }}
                        >
                          <span>
                            <Link to={`/blog-info/${individualBlog.slugUrl}`}>
                              <img
                                src={individualBlog.slider[0]}
                                className=""
                                alt
                              />
                            </Link>
                          </span>
                        </div>
                        <div className="event-content">
                          <div className="event-content-meta">
                            <span>
                              <i class="fa-thin fa-user"></i>Admin
                            </span>
                            <span>
                              <i className="fa-thin fa-clock" />
                              {moment(individualBlog.createdAt).format(
                                "DD MMM YYYY"
                              )}
                            </span>
                          </div>
                          <h5 className="event-content-title line_elips">
                            <span>
                              <Link to={`/blog-info/${individualBlog.slugUrl}`}>
                                {individualBlog.blogTitle}
                              </Link>
                            </span>
                          </h5>
                          <Link
                            to={`/blog-info/${individualBlog.slugUrl}`}
                            className="t-theme-btn theme-btn event-btn"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurBlogs;
