import React, { useState } from 'react'
import { SlEarphones } from "react-icons/sl";
const Listening = () => {
    const [soundLevel, setSoundLevel] = useState(50);

    const handleSliderChange = (e) => {
        setSoundLevel(e.target.value);
    };

    return (
        <div>
            <div style={{ marginTop: "4.5rem" }}>
                <div className='total_container'>
                    <div className='to_flex_endddddd'>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={soundLevel}
                            className="slider_new"
                            onChange={handleSliderChange}
                        />
                    </div>
                </div>
                <div className='main_cointainerrrrrr'>
                    <div className='continaerrr_Center'>
                        <div className='contentt_togive_under'>
                            <p className='test_test_gen'>Listening Section Directions</p>
                        </div>
                        <div className='sub_contentttttttt'>
                            <div>
                                <p className='text_contttt'>This section measures your ability to understand conversations and lectures in English.
                                </p>
                            </div>
                            <div>
                                <p className='text_contttt'>
                                    The Listening section is divided into 2 separately timed parts. In each part you will listen to 1 conversation and 2 lectures. You will hear each conversation or lecture only <span className='bold_contenttt'>one</span> time.
                                </p>
                            </div>
                            <div>
                                <p className='text_contttt'>
                                    After each conversation or lecture, you will answer some questions about it. The questions typically ask about the main idea and supporting details. Some questions ask about a speaker's purpose or attitude. Answer the questions based on what is stated or implied by the speakers
                                </p>
                            </div>
                            <div>
                                <p className='text_contttt'>
                                    You may take notes while you listen. You may use your notes to help you answer the questions. Your notes will not be scored.
                                </p>
                            </div>
                            <div>
                                <p className='text_contttt22'>

                                    In some questions, you will see this icon: !<span style={{ color: "#000", fontWeight: "bold" }}><SlEarphones /></span> This means that you will hear, but not see, part of the question.                                </p>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    )
}

export default Listening
