import React from "react";
import { Link } from "react-router-dom";

const WhyUseOurPlatform = () => {
  return (
    <>
      <section className="h10_about-area pt-40 pb-50" style={{background:"#f4f7ff"}}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="h8_about-wrap ml-30 mb-50">
                <div className="section-area-8 mb-35">
                  <span className="section-subtitle">Why Use Our Platorm</span>
                  <h2
                    className="section-title mb-20"
                    style={{ textTransform: "capitalize" }}
                  >
                    Amplify your chances of securing positive admission
                    decisions
                  </h2>

                  <p className="section-text">
                    Applying to multiple universities involves investing time,
                    effort, and money, and the disappointment of rejection can
                    be disheartening. However, our platform revolutionizes this
                    process by precisely identifying university programs in the
                    US that align perfectly with your education, career
                    objectives, and other criteria. We ensure a 100% likelihood
                    of acceptance, saving you from the uncertainties and
                    setbacks associated with traditional application methods.
                  </p>
                </div>

                <Link to='/blogs' className="theme-btn theme-btn-8">
                  Learn More
                  <i className="fa-light fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="h10_about-img mr-25">
                <img
                  src="/assets/img/about/10/img-shape.png"
                  alt=""
                  className="h10_about-img-shape d-none d-md-block"
                />
                <img
                  src="/assets/img/about/10/shape-1.png"
                  alt=""
                  className="h10_about-img-shape-1 d-none d-md-block"
                />
                <img
                  src="/assets/img/about/10/5.png"
                  alt=""
                  className="wow fadeInLeftBig"
                  data-wow-delay="0.3s"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyUseOurPlatform;
