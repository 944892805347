import React from "react";
import QuestionAndAnswerr from "./QuestionAndAnswerr";
import ProfileImage from "./ProfileImage";
import Listening from "./Listening";
import VideoContent from "./VideoContent";
import Microphone from "./Microphone";


const ToeflandIels = () => {
  return (
    <>
      <QuestionAndAnswerr />
      <ProfileImage />
      <Listening />
      <VideoContent />
      <Microphone />
    </>
  );
};

export default ToeflandIels;
