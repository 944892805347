import React from "react";

const Careers = () => {
  return (
    <>
      <section className="blog_details-area pt-10 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-40">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#F14D5D",
                  }}
                >
                  Careers
                </span>
                <h2 className="section-title mb-0">Careers</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="blog_details-wrap mb-60">
                <div className="blog_details-content">
                  <div className="blog_details-inner-text mr-80">
                    <h5>
                      Are you looking to change the world at a funded, stable
                      startup in the Iowa City area?
                    </h5>
                    <p className="mb-25">
                     F1 Visa is a higher education technology company
                      that's working to simplify the college search and
                      application process. We help match students with schools
                      based on their interests, fit and affordability, which
                      helps more students succeed and receive the best education
                      for the money!
                    </p>
                    <p className="mb-35">
                      We're looking for people passionate about transforming how
                      the college application process works and helping more
                      students succeed. Visit this page to see roles as they
                      open across the team.
                    </p>
                    <p className="mb-35">
                      Have you heard? We’re part of the Citizens family!
                      Colleagues joiningF1 Visa can take advantage of
                      Citizens’ programs and benefits to support you — in
                      whatever way you need it, when you need it — to help you
                      thrive across all aspects of your life. Read more on the
                      Citizens careers site here.
                    </p>
                    <p className="mb-35">Thank you! -F1 Visa</p>
                  </div>
                  {/* <blockquote className="blog_details-quote">
                    <p>
                      My experience of distance learning has been exciting and
                      enlightening! I have got toknow people from various
                      countries and continents.
                    </p>
                    <h6>Dylan Meringue</h6>
                    <span className="blog_details-quote-icon">
                      <i className="fa-solid fa-quote-right" />
                    </span>
                  </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
