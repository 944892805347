import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { Typography, Radio, Col, Button, Space } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import moment from "moment/moment";
import Header from "../../student_registation/components/Header";
import imagelog from "../dollar.png";
const RenewSubscription = () => {
  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Renew Subscription"
            description="Your subscription is about to expire. Please renew it promptly to ensure uninterrupted access to our portal and services."
          />
          <div className="sub_containere">
            <div className="reg_welcome_box">
              <div className="reg_welcome_box_image_doal">
                <img className="" src={imagelog} alt />
              </div>
              <div className="reg_welcome_box_dis">
                <h2 className="h6_cta-title">Your are active subscriber</h2>
                {/* <h5 className="wel_p">Your are activation valid till</h5> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenewSubscription;
