import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Radio, Col, Button, Space, Modal } from "antd";
import ProgramDetailsModel from "../program/ProgramDetailsModel";
const UniversityPrograms = ({ fetchedPrograms }) => {
  const [selectedProgram, setselectedProgram] = useState("");

  const [open, setOpen] = useState(false);

  const handleOk = () => {
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "programName",
      headerName: "Programs",
      width: 350,
      headerClassName: "custom-cell",
    },
    {
      field: "ACTIONS",
      width: 100,
      headerAlign: "center",
      headerClassName: "custom-cell",
      renderCell: (params) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setselectedProgram(params);
              setOpen(true);
            }}
          >
            View More
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        open={open}
        //   title="Title"
        onOk={handleOk}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
        width={800}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Back
          </Button>,
          // <Button
          //   key="submit"
          //   type="primary"
          //   loading={loading}
          //   onClick={handleOk}
          // >
          //   Applay Now
          // </Button>,
        ]}
      >
        <ProgramDetailsModel selectedProgram={selectedProgram} />
      </Modal>
      <div style={{ height: 450, width: "100%", border: "1px solid #002676" }}>
        <DataGrid
          rows={fetchedPrograms}
          columns={columns}
          getRowId={(row) => row._id}
          pageSize={5}
          className="containerrrdatagrod"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "2px solid #002676",
            },
          }}
        />
      </div>
    </div>
  );
};

export default UniversityPrograms;
