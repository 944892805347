import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Typography, Radio, Col, Button, Space } from "antd";

const ResearchPotential = ({ data_use }) => {
  const { loginData } = useSelector((store) => store.Athentication);
  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
.main_letter_box {
  width: 100%;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.main_letter_box span {
  color: #333;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: justify;
}

.margin_tops{
  margin-top: 1.5vh;
}
  .tex_capita {
  text-transform: capitalize;
}
  .text_lowercase {
  text-transform: lowercase;
}
         </style>
        </head>
        <body>
                <div class="main_letter_box">
                  <span>${moment(data_use.newdate).format("DD/MM/YYYY")}</span>

                  <span class="margin_tops">${data_use.facultyName}</span>
                  <span>${data_use.facultyTitle}</span>
                  <span>${data_use.facultyDepartment}</span>
                  <span>${data_use.facultyInstitution}</span>
                  <span>
                    ${data_use.facultyCity},${data_use.facultyState},
                    ${data_use.facultyZip}
                  </span>
                  <span>${data_use.facultyEmailcnf}</span>
                  <span>${data_use.mobile}</span>
                  <span>Date</span>

                  <span class="margin_tops">${data_use.universityName}</span>
                  <span>${data_use.universityAddress}</span>
                  <span>
                    ${data_use.universityCity},${data_use.universityState},
                    ${data_use.universityZip}
                  </span>
                  <span class="margin_tops">
                    Dear Members of the Admissions Committee,
                  </span>
          <span class="margin_tops">
          I am writing to recommend <span>${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> for admission to
          <span> ${data_use.universityName}</span>. I have worked closely with
          <span> ${loginData.firstname} </span>
          ${loginData.lastname} on several research projects in
          <span> ${data_use.specificField} </span>
          and have been consistently impressed with
          <span> ${data_use.gender} </span> research capabilities and innovative
          thinking.
        </span>
        <span class="margin_tops">
          <span>${loginData.firstname} </span> ${
      loginData.lastname
    } has a unique
          ability to identify and explore complex research questions.
          <span> </span>
          <span class="tex_capita">${data_use.gender2} </span>
          work on <span> ${data_use.specificProject} </span> was particularly
          notable for its originality and depth of analysis.
          <span class="tex_capita"> ${data_use.gender3} </span> approached
          the project with a high degree of professionalism and diligence,
          producing results that were both insightful and valuable to our
          ongoing research.
        </span>
        <span class="margin_tops">
          <span class="tex_capita">${data_use.gender2} </span> aptitude for
          research, combined with a strong theoretical foundation, makes
          <span> ${loginData.firstname} </span> ${
      loginData.lastname
    } an excellent
          candidate for advanced studies at your institution. I am confident
          that <span class="text_lowercase"> ${data_use.gender3} </span> will
          thrive in an environment that encourages intellectual curiosity and
          rigorous academic inquiry.
        </span>
        <span class="margin_tops">
          I wholeheartedly endorse <span> ${loginData.firstname} </span>
          ${loginData.lastname} for admission to
          <span> ${data_use.universityName}</span> and believe
          <span class="text_lowercase"> ${data_use.gender3} </span> will be a
          significant asset to your academic community.
        </span>
                  <span class="margin_tops">Sincerely,</span>
                  <span class="margin_tops">${data_use.facultyName}</span>
                </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };
  return (
    <>
      <div className="main_letter_box">
        <span>{moment(data_use.newdate).format("DD/MM/YYYY")}</span>

        <span className="margin_tops">{data_use.facultyName}</span>
        <span>{data_use.facultyTitle}</span>
        <span>{data_use.facultyDepartment}</span>
        <span>{data_use.facultyInstitution}</span>
        <span>
          {data_use.facultyCity},{data_use.facultyState},{data_use.facultyZip}
        </span>
        <span>{data_use.facultyEmailcnf}</span>
        <span>{data_use.mobile}</span>

        <span className="margin_tops">{data_use.Committee}</span>
        <span>{data_use.universityName}</span>
        <span>{data_use.universityAddress}</span>
        <span>
          {data_use.universityCity},{data_use.universityState},
          {data_use.universityZip}
        </span>
        <span className="margin_tops">
          Dear Members of the Admissions Committee,
        </span>
        <span className="margin_tops">
          I am writing to recommend <span>{loginData.firstname}</span>
          <span> {loginData.lastname}</span> for admission to
          <span> {data_use.universityName}</span>. I have worked closely with
          <span> {loginData.firstname} </span>
          {loginData.lastname} on several research projects in
          <span> {data_use.specificField} </span>
          and have been consistently impressed with
          <span> {data_use.gender} </span> research capabilities and innovative
          thinking.
        </span>
        <span className="margin_tops">
          <span>{loginData.firstname} </span> {loginData.lastname} has a unique
          ability to identify and explore complex research questions.
          <span> </span>
          <span className="tex_capita">{data_use.gender2} </span>
          work on <span> {data_use.specificProject} </span> was particularly
          notable for its originality and depth of analysis.
          <span className="tex_capita"> {data_use.gender3} </span> approached
          the project with a high degree of professionalism and diligence,
          producing results that were both insightful and valuable to our
          ongoing research.
        </span>
        <span className="margin_tops">
          <span className="tex_capita">{data_use.gender2} </span> aptitude for
          research, combined with a strong theoretical foundation, makes
          <span> {loginData.firstname} </span> {loginData.lastname} an excellent
          candidate for advanced studies at your institution. I am confident
          that <span className="text_lowercase"> {data_use.gender3} </span> will
          thrive in an environment that encourages intellectual curiosity and
          rigorous academic inquiry.
        </span>
        <span className="margin_tops">
          I wholeheartedly endorse <span> {loginData.firstname} </span>
          {loginData.lastname} for admission to
          <span> {data_use.universityName}</span> and believe
          <span className="text_lowercase"> {data_use.gender3} </span> will be a
          significant asset to your academic community.
        </span>
        <span className="margin_tops">Sincerely,</span>
        <span className="margin_tops">{data_use.facultyName}</span>
        <div className="button_container">
          <Button
            type="primary"
            size="large"
            onClick={() => handlePrint(false)}
          >
            Print
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResearchPotential;
