import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { Typography, Radio, Col, Button, Space } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import countries from "../json_datas/countries.json";
import cities from "../json_datas/countries+cities.json";
import states from "../json_datas/countries+states.json";
import programs from "../json_datas/UnderGraduationdata.json";
import Header from "./Header";
import { FaInfoCircle } from "react-icons/fa";
import { HiChevronDoubleLeft } from "react-icons/hi2";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setEducationalEvaluation,
  setWorkExperience,
} from "../../../redux/dashboard/PageRenderSlice";

const WorkExperience = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((store) => store.Athentication);
  const { countryList, personalInfo } = useSelector(
    (store) => store.DashboardPage
  );
  const { currentFormStep, studentDetail } = useSelector(
    (store) => store.StudentRegd
  );

  const [workExperience, setWorkEx] = useState(false);
  const [workExperienceArray, setWorkExperienceArray] = useState([
    {
      Employer: "",
      City: "",
      Country: "",
      StartMonth: "",
      StartYear: "",
      EndMonth: "",
      EndYear: "",
      DomainArea: "",
      SkilsUsed: "",
      selectedskils: [],
    },
  ]);
  const [workExperienceError, setWorkExperienceError] = useState("");
  const [buttonPress, setbuttonPress] = useState(false);
  // Error Message
  const [workExperienceIndex, setWorkExperienceIndex] = useState("");
  const [workExperiencePress, setWorkExperiencePress] = useState(false);

  const [showCityByCountry, setshowCityByCountry] = useState([]);
  const [selectedSkills, setselectedSkills] = useState([]);

  const [employerError, setEmployerError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [frommonthError, setFormmonthError] = useState("");
  const [fromYearError, setFormYearError] = useState("");
  const [TomonthError, setTomonthError] = useState("");
  const [ToYearError, setToYearError] = useState("");
  const [domainError, setDomainError] = useState("");
  const [skillError, setSkillError] = useState("");
  const [skillArray, setSkillArray] = useState([]);

  useEffect(() => {
    if (studentDetail.currentFormStep >= 6) {
      setWorkEx(studentDetail.workExperienceStatus);
      setWorkExperienceArray(studentDetail.workExperience);
    }
  }, [studentDetail]);

  useEffect(() => {
    if (workExperiencePress === true) {
      const lastIndex = workExperienceArray.length - 1;

      setWorkExperienceIndex("");
      setEmployerError("");
      setCountryError("");
      setCityError("");
      setFormmonthError("");
      setFormYearError("");
      setTomonthError("");
      setToYearError("");
      setDomainError("");
      setSkillError("");

      if (workExperienceArray[lastIndex].Employer === "") {
        setWorkExperienceIndex(lastIndex);
        setEmployerError("Required");
        setWorkExperiencePress(true);
      }
      if (workExperienceArray[lastIndex].City === "") {
        setWorkExperienceIndex(lastIndex);
        setCityError("Required");
        setWorkExperiencePress(true);
      }
      if (workExperienceArray[lastIndex].Country === "") {
        setWorkExperienceIndex(lastIndex);
        setCountryError("Required");
        setWorkExperiencePress(true);
      }
      if (workExperienceArray[lastIndex].StartMonth === "") {
        setWorkExperienceIndex(lastIndex);
        setFormmonthError("Required");
        setWorkExperiencePress(true);
      }
      if (workExperienceArray[lastIndex].StartYear === "") {
        setWorkExperienceIndex(lastIndex);
        setFormYearError("Required");
        setWorkExperiencePress(true);
      }
      // if (workExperienceArray[lastIndex].EndMonth === "") {
      //   setWorkExperienceIndex(lastIndex);
      //   setTomonthError("Required");
      //   setWorkExperiencePress(true);
      // }
      // if (workExperienceArray[lastIndex].EndYear === "") {
      //   setWorkExperienceIndex(lastIndex);
      //   setToYearError("Required");
      //   setWorkExperiencePress(true);
      // }
      if (workExperienceArray[lastIndex].DomainArea === "") {
        setWorkExperienceIndex(lastIndex);
        setDomainError("Required");
        setWorkExperiencePress(true);
      }
      if (workExperienceArray[lastIndex].SkilsUsed === "") {
        setWorkExperienceIndex(lastIndex);
        setSkillError("Required");
        setWorkExperiencePress(true);
      }
    }
  }, [buttonPress, workExperience, workExperienceArray]);

  const SelectDomain = [
    "Information Technology (IT)",
    "Software Development",
    "Web Development",
    "Mobile App Development",
    "Data Science",
    "Machine Learning",
    "Artificial Intelligence",
    "Cybersecurity",
    "Network Administration",
    "Cloud Computing",
    "Database Administration",
    "Project Management",
    "Digital Marketing",
    "Content Writing",
    "Graphic Design",
    "UI/UX Design",
    "Product Management",
    "Sales and Business Development",
    "Customer Service",
    "Human Resources",
    "Finance and Accounting",
    "Market Research",
    "Supply Chain Management",
    "Operations Management",
    "Quality Assurance and Testing",
    "Legal and Compliance",
    "Healthcare Management",
    "Education and Training",
    "Social Media Management",
    "Public Relations",
    "Event Management",
    "Hospitality Management",
    "Real Estate Management",
    "Retail Management",
    "Environmental Sustainability",
    "Renewable Energy",
    "Aerospace Engineering",
    "Automotive Engineering",
    "Civil Engineering",
    "Electrical Engineering",
    "Mechanical Engineering",
    "Chemical Engineering",
    "Biomedical Engineering",
    "Pharmaceutical Sciences",
    "Food Sciences and Technology",
    "Agriculture and Farming",
    "Forestry and Conservation",
    "Marine Biology and Oceanography",
    "Environmental Engineering",
    "Urban Planning and Development",
  ];

  const SelectSkill = [
    {
      skill: "Programming Languages (e.g., Python, Java, JavaScript, C++)",
      domain: "Software Development",
    },
    {
      skill: "Object-Oriented Design",
      domain: "Software Development",
    },
    {
      skill: "Algorithms and Data Structures",
      domain: "Software Development",
    },
    {
      skill: "Version Control (e.g., Git)",
      domain: "Software Development",
    },
    {
      skill: "Software Development Life Cycle (SDLC)",
      domain: "Software Development",
    },
    {
      skill: "Agile Methodologies",
      domain: "Software Development",
    },
    {
      skill: "Design Patterns",
      domain: "Software Development",
    },
    {
      skill: "Test-Driven Development (TDD)",
      domain: "Software Development",
    },
    {
      skill: "Continuous Integration/Continuous Deployment (CI/CD)",
      domain: "Software Development",
    },
    {
      skill: "Software Architecture",
      domain: "Software Development",
    },
    {
      skill: "Debugging and Profiling",
      domain: "Software Development",
    },
    {
      skill: "Agile Project Management Tools (e.g., Jira, Trello)",
      domain: "Software Development",
    },
    {
      skill: "Troubleshooting",
      domain: "Information Technology (IT)",
    },
    {
      skill: "Hardware Maintenance",
      domain: "Information Technology (IT)",
    },
    {
      skill: "Software Installation and Configuration",
      domain: "Information Technology (IT)",
    },
    {
      skill: "Operating Systems (e.g., Windows, Linux)",
      domain: "Information Technology (IT)",
    },
    {
      skill: "Networking",
      domain: "Information Technology (IT)",
    },
    {
      skill: "System Administration",
      domain: "Information Technology (IT)",
    },
    {
      skill: "IT Security",
      domain: "Information Technology (IT)",
    },
    {
      skill: "Disaster Recovery Planning",
      domain: "Information Technology (IT)",
    },
    {
      skill: "Cloud Computing Services (e.g., AWS, Azure)",
      domain: "Information Technology (IT)",
    },
    {
      skill: "ITIL (Information Technology Infrastructure Library) Framework",
      domain: "Information Technology (IT)",
    },
    {
      skill: "Virtualization Technologies",
      domain: "Information Technology (IT)",
    },
    {
      skill: "Scripting (e.g., Bash, PowerShell)",
      domain: "Information Technology (IT)",
    },

    {
      skill: "HTML, CSS, JavaScript",
      domain: "Web Development",
    },
    {
      skill: "Frontend Frameworks (e.g., React, Angular, Vue.js)",
      domain: "Web Development",
    },
    {
      skill: "Backend Frameworks (e.g., Node.js, Django, Flask)",
      domain: "Web Development",
    },
    {
      skill: "RESTful APIs",
      domain: "Web Development",
    },
    {
      skill: "Responsive Design",
      domain: "Web Development",
    },
    {
      skill: "Web Security",
      domain: "Web Development",
    },
    {
      skill: "Web Performance Optimization",
      domain: "Web Development",
    },
    {
      skill: "Content Management Systems (e.g., WordPress, Drupal)",
      domain: "Web Development",
    },
    {
      skill: "Web Accessibility Standards (WCAG)",
      domain: "Web Development",
    },
    {
      skill: "Browser Developer Tools",
      domain: "Web Development",
    },
    {
      skill: "Web Server Management (e.g., Apache, Nginx)",
      domain: "Web Development",
    },
    {
      skill: "Responsive Web Design Frameworks (e.g., Bootstrap, Foundation)",
      domain: "Web Development",
    },
    {
      skill: "iOS Development (Swift, Objective-C)",
      domain: "Mobile App Development",
    },
    {
      skill: "Android Development (Java, Kotlin)",
      domain: "Mobile App Development",
    },
    {
      skill: "Cross-platform Development (e.g., React Native, Flutter)",
      domain: "Mobile App Development",
    },
    {
      skill: "Mobile UI/UX Design",
      domain: "Mobile App Development",
    },
    {
      skill: "App Deployment (App Store, Google Play)",
      domain: "Mobile App Development",
    },
    {
      skill: "Mobile App Testing",
      domain: "Mobile App Development",
    },
    {
      skill: "Web Performance Optimization",
      domain: "Mobile App Development",
    },
    {
      skill: "Content Management Systems (e.g., WordPress, Drupal)",
      domain: "Mobile App Development",
    },
    {
      skill: "Web Accessibility Standards (WCAG)",
      domain: "Mobile App Development",
    },
    {
      skill: "Browser Developer Tools",
      domain: "Mobile App Development",
    },
    {
      skill: "Web Server Management (e.g., Apache, Nginx)",
      domain: "Mobile App Development",
    },
    {
      skill: "Responsive Web Design Frameworks (e.g., Bootstrap, Foundation)",
      domain: "Mobile App Development",
    },
    {
      skill: "Python/R Programming",
      domain: "Data Science",
    },
    {
      skill: "Statistical Analysis",
      domain: "Data Science",
    },
    {
      skill: "Machine Learning Algorithms",
      domain: "Data Science",
    },
    {
      skill: "Data Visualization (e.g., Matplotlib, Seaborn)",
      domain: "Data Science",
    },
    {
      skill: "Big Data Technologies (e.g., Hadoop, Spark)",
      domain: "Data Science",
    },
    {
      skill: "Data Mining",
      domain: "Data Science",
    },
    {
      skill: "Data Cleaning and Preprocessing Techniques",
      domain: "Data Science",
    },
    {
      skill: "Feature Engineering",
      domain: "Data Science",
    },
    {
      skill: "Time Series Analysis",
      domain: "Data Science",
    },
    {
      skill: "Dimensionality Reduction",
      domain: "Data Science",
    },
    {
      skill: "Data Wrangling Tools (e.g., Pandas, dplyr)",
      domain: "Data Science",
    },
    {
      skill: "Data Storytelling and Visualization Techniques",
      domain: "Data Science",
    },
    {
      skill: "Deep Learning Frameworks (e.g., TensorFlow, PyTorch)",
      domain: "Machine Learning",
    },
    {
      skill: "Neural Networks",
      domain: "Machine Learning",
    },
    {
      skill: "Model Evaluation and Optimization",
      domain: "Machine Learning",
    },
    {
      skill: "Natural Language Processing (NLP)",
      domain: "Machine Learning",
    },
    {
      skill: "Computer Vision",
      domain: "Machine Learning",
    },
    {
      skill: "Reinforcement Learning",
      domain: "Machine Learning",
    },
    {
      skill: "Hyperparameter Tuning",
      domain: "Machine Learning",
    },
    {
      skill: "Transfer Learning",
      domain: "Machine Learning",
    },
    {
      skill: "Ensemble Learning Methods",
      domain: "Machine Learning",
    },
    {
      skill: "Model Deployment Strategies",
      domain: "Machine Learning",
    },
    {
      skill: "Explainable AI Techniques",
      domain: "Machine Learning",
    },
    {
      skill: "Reinforcement Learning Algorithms (e.g., Q-Learning, DQN)",
      domain: "Machine Learning",
    },
    {
      skill: "Knowledge Representation",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Expert Systems",
      domain: "Artificial Intelligence",
    },
    {
      skill: "AI Ethics",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Search Algorithms",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Cognitive Computing",
      domain: "Artificial Intelligence",
    },
    {
      skill: "AI Applications in various fields",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Knowledge Representation Techniques (e.g., Semantic Web)",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Robotics",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Multi-agent Systems",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Genetic Algorithms",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Expert System Development Tools",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Ethical AI Design Principles",
      domain: "Artificial Intelligence",
    },
    {
      skill: "Network Security",
      domain: "Cybersecurity",
    },
    {
      skill: "Penetration Testing",
      domain: "Cybersecurity",
    },
    {
      skill: "Cryptography",
      domain: "Cybersecurity",
    },
    {
      skill: "Security Protocols",
      domain: "Cybersecurity",
    },
    {
      skill: "Incident Response",
      domain: "Cybersecurity",
    },
    {
      skill: "Security Compliance",
      domain: "Cybersecurity",
    },
    {
      skill: "Threat Intelligence Analysis",
      domain: "Cybersecurity",
    },
    {
      skill: "Security Information and Event Management (SIEM)",
      domain: "Cybersecurity",
    },
    {
      skill: "Identity and Access Management (IAM)",
      domain: "Cybersecurity",
    },
    {
      skill: "Secure Coding Practices",
      domain: "Cybersecurity",
    },
    {
      skill: "Security Compliance Frameworks (e.g., NIST, ISO 27001)",
      domain: "Cybersecurity",
    },
    {
      skill: "Reverse Engineering",
      domain: "Cybersecurity",
    },
    {
      skill: "Network Configuration",
      domain: "Network Administration",
    },
    {
      skill: "TCP/IP Protocols",
      domain: "Network Administration",
    },
    {
      skill: "Routing and Switching",
      domain: "Network Administration",
    },
    {
      skill: "Network Monitoring",
      domain: "Network Administration",
    },
    {
      skill: "Firewall Management",
      domain: "Network Administration",
    },
    {
      skill: "VPN Setup and Configuration",
      domain: "Network Administration",
    },
    {
      skill: "Network Automation Tools (e.g., Ansible, Puppet)",
      domain: "Network Administration",
    },
    {
      skill: "Software-Defined Networking (SDN)",
      domain: "Network Administration",
    },
    {
      skill: "Wireless Networking Technologies",
      domain: "Network Administration",
    },
    {
      skill: "Network Performance Monitoring",
      domain: "Network Administration",
    },
    {
      skill: "Network Troubleshooting Tools",
      domain: "Network Administration",
    },
    {
      skill: "Network Capacity Planning",
      domain: "Network Administration",
    },
    {
      skill: "Cloud Platforms (e.g., AWS, Azure, Google Cloud)",
      domain: "Cloud Computing",
    },
    {
      skill: "TCP/IP Protocols",
      domain: "Cloud Computing",
    },
    {
      skill: "Cloud Security",
      domain: "Cloud Computing",
    },
    {
      skill: "Cloud Migration",
      domain: "Cloud Computing",
    },
    {
      skill: "DevOps Practices",
      domain: "Cloud Computing",
    },
    {
      skill: "Containerization (e.g., Docker, Kubernetes)",
      domain: "Cloud Computing",
    },
    {
      skill: "Serverless Computing",
      domain: "Cloud Computing",
    },
    {
      skill: "Cloud Cost Management",
      domain: "Cloud Computing",
    },
    {
      skill: "Cloud Migration Strategies",
      domain: "Cloud Computing",
    },
    {
      skill: "Infrastructure as Code (IaC)",
      domain: "Cloud Computing",
    },
    {
      skill: "Cloud-native Development",
      domain: "Cloud Computing",
    },
    {
      skill: "Cloud Governance Frameworks",
      domain: "Cloud Computing",
    },
    {
      skill:
        "Relational Database Management Systems (e.g., MySQL, PostgreSQL, SQL Server)",
      domain: "Database Administration",
    },
    {
      skill: "TCP/IP Protocols",
      domain: "Database Administration",
    },
    {
      skill: "SQL (Structured Query Language)",
      domain: "Database Administration",
    },
    {
      skill: "Database Backup and Recovery",
      domain: "Database Administration",
    },
    {
      skill: "Performance Tuning",
      domain: "Database Administration",
    },
    {
      skill: "NoSQL Databases (e.g., MongoDB, Cassandra)",
      domain: "Database Administration",
    },
    {
      skill: "High Availability Solutions (e.g., clustering, replication)",
      domain: "Database Administration",
    },
    {
      skill: "Database Security Best Practices",
      domain: "Database Administration",
    },
    {
      skill: "Database Performance Tuning",
      domain: "Database Administration",
    },
    {
      skill: "Database Backup and Recovery Strategies",
      domain: "Database Administration",
    },
    {
      skill: "Database Migration Techniques",
      domain: "Database Administration",
    },
    {
      skill: "Knowledge of NoSQL Databases (e.g., Redis, Cassandra)",
      domain: "Database Administration",
    },
    {
      skill: "Project Planning and Scheduling",
      domain: "Project Management",
    },
    {
      skill: "Risk Management",
      domain: "Project Management",
    },
    {
      skill: "Budgeting and Resource Allocation",
      domain: "Project Management",
    },
    {
      skill: "Stakeholder Communication",
      domain: "Project Management",
    },
    {
      skill: "Agile/Scrum Methodologies",
      domain: "Project Management",
    },
    {
      skill: "Team Leadership",
      domain: "Project Management",
    },
    {
      skill: "Conflict Management and Resolution",
      domain: "Project Management",
    },
    {
      skill: "Change Management Processes",
      domain: "Project Management",
    },
    {
      skill: "Stakeholder Management",
      domain: "Project Management",
    },
    {
      skill: "Earned Value Management (EVM)",
      domain: "Project Management",
    },
    {
      skill: "Critical Path Method (CPM)",
      domain: "Project Management",
    },
    {
      skill: "Project Management Professional (PMP) Certification",
      domain: "Project Management",
    },
    {
      skill: "Search Engine Optimization (SEO)",
      domain: "Digital Marketing",
    },
    {
      skill: "Social Media Marketing (SMM)",
      domain: "Digital Marketing",
    },
    {
      skill: "Email Marketing",
      domain: "Digital Marketing",
    },
    {
      skill: "Content Marketing",
      domain: "Digital Marketing",
    },
    {
      skill: "Pay-Per-Click (PPC) Advertising",
      domain: "Digital Marketing",
    },
    {
      skill: "Analytics and Reporting",
      domain: "Digital Marketing",
    },
    {
      skill: "Conversion Rate Optimization (CRO)",
      domain: "Digital Marketing",
    },
    {
      skill: "Influencer Relationship Management",
      domain: "Digital Marketing",
    },
    {
      skill: "A/B Testing",
      domain: "Digital Marketing",
    },
    {
      skill: "Marketing Automation Platforms (e.g., HubSpot, Marketo)",
      domain: "Digital Marketing",
    },
    {
      skill: "Affiliate Marketing Strategies",
      domain: "Digital Marketing",
    },
    {
      skill: "Video Marketing Techniques",
      domain: "Digital Marketing",
    },
    {
      skill: "Copywriting",
      domain: "Content Writing",
    },
    {
      skill: "Content Strategy",
      domain: "Content Writing",
    },
    {
      skill: "Blogging",
      domain: "Content Writing",
    },
    {
      skill: "Editing and Proofreading",
      domain: "Content Writing",
    },
    {
      skill: "SEO Writing",
      domain: "Content Writing",
    },
    {
      skill: "Research Skills",
      domain: "Content Writing",
    },
    {
      skill: "Search Engine Marketing (SEM)",
      domain: "Content Writing",
    },
    {
      skill: "Guest Blogging",
      domain: "Content Writing",
    },
    {
      skill: "SEO Copywriting",
      domain: "Content Writing",
    },
    {
      skill: "Content Calendar Management",
      domain: "Content Writing",
    },
    {
      skill: "Knowledge of Content Management Systems (CMS)",
      domain: "Content Writing",
    },
    {
      skill: "Email Newsletter Writing",
      domain: "Content Writing",
    },
    {
      skill: "Adobe Creative Suite (Photoshop, Illustrator, InDesign)",
      domain: "Graphic Design",
    },
    {
      skill: "Typography",
      domain: "Graphic Design",
    },
    {
      skill: "Layout Design",
      domain: "Graphic Design",
    },
    {
      skill: "Branding and Identity",
      domain: "Graphic Design",
    },
    {
      skill: "UI/UX Design Principles",
      domain: "Graphic Design",
    },
    {
      skill: "Print Design",
      domain: "Graphic Design",
    },
    {
      skill: "Motion Graphics Design",
      domain: "Graphic Design",
    },
    {
      skill: "Illustration Techniques",
      domain: "Graphic Design",
    },
    {
      skill: "Packaging Design",
      domain: "Graphic Design",
    },
    {
      skill: "User Interface Animation",
      domain: "Graphic Design",
    },
    {
      skill: "Typography Hierarchy",
      domain: "Graphic Design",
    },
    {
      skill: "Brand Identity Guidelines",
      domain: "Graphic Design",
    },
    {
      skill: "User Research",
      domain: "UI/UX Design",
    },
    {
      skill: "Wireframing and Prototyping",
      domain: "UI/UX Design",
    },
    {
      skill: "Interaction Design",
      domain: "UI/UX Design",
    },
    {
      skill: "Usability Testing",
      domain: "UI/UX Design",
    },
    {
      skill: "Information Architecture",
      domain: "UI/UX Design",
    },
    {
      skill: "User-Centered Design",
      domain: "UI/UX Design",
    },
    {
      skill: "User Flow Mapping",
      domain: "UI/UX Design",
    },
    {
      skill: "Prototyping Tools (e.g., Sketch, Adobe XD)",
      domain: "UI/UX Design",
    },
    {
      skill: "Design Systems Creation",
      domain: "UI/UX Design",
    },
    {
      skill: "User Persona Development",
      domain: "UI/UX Design",
    },
    {
      skill: "Usability Heuristics Evaluation",
      domain: "UI/UX Design",
    },
    {
      skill: "A/B Testing for User Interfaces",
      domain: "UI/UX Design",
    },
    {
      skill: "Market Analysis",
      domain: "Product Management",
    },
    {
      skill: "Product Roadmapping",
      domain: "Product Management",
    },
    {
      skill: "Requirement Gathering",
      domain: "Product Management",
    },
    {
      skill: "Product Lifecycle Management",
      domain: "Product Management",
    },
    {
      skill: "Agile Development Principles",
      domain: "Product Management",
    },
    {
      skill: "Cross-functional Team Collaboration",
      domain: "Product Management",
    },
    {
      skill: "User Feedback Analysis",
      domain: "Product Management",
    },
    {
      skill: "Product Metrics and KPIs",
      domain: "Product Management",
    },
    {
      skill: "MVP (Minimum Viable Product) Development",
      domain: "Product Management",
    },
    {
      skill: "Product Launch Strategies",
      domain: "Product Management",
    },
    {
      skill: "Customer Journey Mapping",
      domain: "Product Management",
    },
    {
      skill: "Product Roadmap Visualization Tools",
      domain: "Product Management",
    },
    {
      skill: "Prospecting and Lead Generation",
      domain: "Sales and Business Development",
    },
    {
      skill: "Negotiation Skills",
      domain: "Sales and Business Development",
    },
    {
      skill: "Relationship Building",
      domain: "Sales and Business Development",
    },
    {
      skill: "Sales Presentation",
      domain: "Sales and Business Development",
    },
    {
      skill: "Market Analysis",
      domain: "Sales and Business Development",
    },
    {
      skill: "CRM Software",
      domain: "Sales and Business Development",
    },
    {
      skill: "Sales Pipeline Management",
      domain: "Sales and Business Development",
    },
    {
      skill: "Negotiation Tactics",
      domain: "Sales and Business Development",
    },
    {
      skill: "Key Account Management",
      domain: "Sales and Business Development",
    },
    {
      skill: "Channel Partner Development",
      domain: "Sales and Business Development",
    },
    {
      skill: "Sales Forecasting Models",
      domain: "Sales and Business Development",
    },
    {
      skill:
        "Customer Relationship Management (CRM) Tools (e.g., Salesforce, Zoho CRM)",
      domain: "Sales and Business Development",
    },
    {
      skill: "Communication Skills",
      domain: "Customer Service",
    },
    {
      skill: "Problem-Solving",
      domain: "Customer Service",
    },
    {
      skill: "Empathy",
      domain: "Customer Service",
    },
    {
      skill: "Conflict Resolution",
      domain: "Customer Service",
    },
    {
      skill: "Product Knowledge",
      domain: "Customer Service",
    },
    {
      skill: "Time Management",
      domain: "Customer Service",
    },
    {
      skill: "Multi-channel Support (e.g., phone, email, chat)",
      domain: "Customer Service",
    },
    {
      skill: "Knowledge Base Management",
      domain: "Customer Service",
    },
    {
      skill: "Customer Satisfaction Surveys Analysis",
      domain: "Customer Service",
    },
    {
      skill: "Customer Retention Strategies",
      domain: "Customer Service",
    },
    {
      skill: "Net Promoter Score (NPS) Tracking",
      domain: "Customer Service",
    },
    {
      skill: "Social Media Customer Support",
      domain: "Customer Service",
    },
    {
      skill: "Recruitment and Selection",
      domain: "Human Resources",
    },
    {
      skill: "Employee Relations",
      domain: "Human Resources",
    },
    {
      skill: "Performance Management",
      domain: "Human Resources",
    },
    {
      skill: "Training and Development",
      domain: "Human Resources",
    },
    {
      skill: "HR Compliance",
      domain: "Human Resources",
    },
    {
      skill: "Compensation and Benefits Administration",
      domain: "Human Resources",
    },
    {
      skill: "Succession Planning",
      domain: "Human Resources",
    },
    {
      skill: "Diversity and Inclusion Initiatives",
      domain: "Human Resources",
    },
    {
      skill: "HR Analytics",
      domain: "Human Resources",
    },
    {
      skill: "Employment Law Compliance",
      domain: "Human Resources",
    },
    {
      skill: "HRIS (Human Resources Information System) Management",
      domain: "Human Resources",
    },
    {
      skill: "Employee Engagement Strategies",
      domain: "Human Resources",
    },
    {
      skill: "Financial Analysis",
      domain: "Finance and Accounting",
    },
    {
      skill: "Budgeting and Forecasting",
      domain: "Finance and Accounting",
    },
    {
      skill: "Financial Reporting",
      domain: "Finance and Accounting",
    },
    {
      skill: "Taxation",
      domain: "Finance and Accounting",
    },
    {
      skill: "Auditing",
      domain: "Finance and Accounting",
    },
    {
      skill: "Risk Management",
      domain: "Finance and Accounting",
    },
    {
      skill: "Financial Modeling",
      domain: "Finance and Accounting",
    },
    {
      skill: "Corporate Finance Principles",
      domain: "Finance and Accounting",
    },
    {
      skill: "International Financial Reporting Standards (IFRS)",
      domain: "Finance and Accounting",
    },
    {
      skill: "Forensic Accounting Techniques",
      domain: "Finance and Accounting",
    },
    {
      skill: "Financial Risk Management",
      domain: "Finance and Accounting",
    },
    {
      skill: "Tax Planning Strategies",
      domain: "Finance and Accounting",
    },
    {
      skill: "Survey Design and Analysis",
      domain: "Market Research",
    },
    {
      skill: "Data Collection Methods",
      domain: "Market Research",
    },
    {
      skill: "Market Segmentation",
      domain: "Market Research",
    },
    {
      skill: "Competitive Analysis",
      domain: "Market Research",
    },
    {
      skill: "Trend Analysis",
      domain: "Market Research",
    },
    {
      skill: "Qualitative and Quantitative Research Techniques",
      domain: "Market Research",
    },
    {
      skill: "Conjoint Analysis",
      domain: "Market Research",
    },
    {
      skill: "Market Segmentation Models",
      domain: "Market Research",
    },
    {
      skill: "Ethnographic Research Methods",
      domain: "Market Research",
    },
    {
      skill: "Brand Equity Measurement",
      domain: "Market Research",
    },
    {
      skill: "Competitive Intelligence Gathering",
      domain: "Market Research",
    },
    {
      skill: "Survey Sampling Techniques",
      domain: "Market Research",
    },
    {
      skill: "Inventory Management",
      domain: "Supply Chain Management",
    },
    {
      skill: "Logistics",
      domain: "Supply Chain Management",
    },
    {
      skill: "Supplier Relationship Management",
      domain: "Supply Chain Management",
    },
    {
      skill: "Demand Forecasting",
      domain: "Supply Chain Management",
    },
    {
      skill: "Procurement",
      domain: "Supply Chain Management",
    },
    {
      skill: "Supply Chain Optimization",
      domain: "Supply Chain Management",
    },
    {
      skill: "Supplier Diversity Programs",
      domain: "Supply Chain Management",
    },
    {
      skill: "Vendor Risk Management",
      domain: "Supply Chain Management",
    },
    {
      skill: "Reverse Logistics Management",
      domain: "Supply Chain Management",
    },
    {
      skill: "Lean Supply Chain Practices",
      domain: "Supply Chain Management",
    },
    {
      skill: "Blockchain in Supply Chain Applications",
      domain: "Supply Chain Management",
    },
    {
      skill: "Demand-Driven Supply Chain Strategies",
      domain: "Supply Chain Management",
    },
    {
      skill: "Process Improvement",
      domain: "Operations Management",
    },
    {
      skill: "Quality Management",
      domain: "Operations Management",
    },
    {
      skill: "Resource Allocation",
      domain: "Operations Management",
    },
    {
      skill: "Lean Manufacturing",
      domain: "Operations Management",
    },
    {
      skill: "Six Sigma Methodology",
      domain: "Operations Management",
    },
    {
      skill: "Production Planning and Control",
      domain: "Operations Management",
    },
    {
      skill: "Inventory Forecasting Methods",
      domain: "Operations Management",
    },
    {
      skill: "Lean Six Sigma Black Belt Certification",
      domain: "Operations Management",
    },
    {
      skill: "Operations Research Techniques",
      domain: "Operations Management",
    },
    {
      skill: "Total Quality Management (TQM)",
      domain: "Operations Management",
    },
    {
      skill: "Theory of Constraints (TOC)",
      domain: "Operations Management",
    },
    {
      skill: "Kaizen Principles Implementation",
      domain: "Operations Management",
    },
    {
      skill: "Test Automation",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Test Automation",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Defect Tracking and Management",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Regression Testing",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Performance Testing",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "User Acceptance Testing",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Test Case Management Tools (e.g., TestRail, Zephyr)",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Load Testing",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Security Testing",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Continuous Testing Practices",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Test Automation Frameworks (e.g., Selenium, Appium)",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "DevOps Testing Strategies",
      domain: "Quality Assurance and Testing",
    },
    {
      skill: "Legal Research",
      domain: "Legal and Compliance",
    },
    {
      skill: "Contract Review and Drafting",
      domain: "Legal and Compliance",
    },
    {
      skill: "Compliance Management",
      domain: "Legal and Compliance",
    },
    {
      skill: "Intellectual Property Law",
      domain: "Legal and Compliance",
    },
    {
      skill: "Regulatory Compliance",
      domain: "Legal and Compliance",
    },
    {
      skill: "Dispute Resolution",
      domain: "Legal and Compliance",
    },
    {
      skill: "Corporate Governance Frameworks",
      domain: "Legal and Compliance",
    },
    {
      skill: "Legal Project Management",
      domain: "Legal and Compliance",
    },
    {
      skill: "Compliance Auditing",
      domain: "Legal and Compliance",
    },
    {
      skill: "Contract Lifecycle Management (CLM) Systems",
      domain: "Legal and Compliance",
    },
    {
      skill: "eDiscovery Tools",
      domain: "Legal and Compliance",
    },
    {
      skill: "Intellectual Property Portfolio Management",
      domain: "Legal and Compliance",
    },
    {
      skill: "Healthcare Policy and Regulation",
      domain: "Healthcare Management",
    },
    {
      skill: "Healthcare Economics",
      domain: "Healthcare Management",
    },
    {
      skill: "Health Information Systems",
      domain: "Healthcare Management",
    },
    {
      skill: "Patient Care Management",
      domain: "Healthcare Management",
    },
    {
      skill: "Healthcare Quality Improvement",
      domain: "Healthcare Management",
    },
    {
      skill: "Healthcare Ethics",
      domain: "Healthcare Management",
    },
    {
      skill: "Health Informatics",
      domain: "Healthcare Management",
    },
    {
      skill: "Electronic Health Record (EHR) Systems",
      domain: "Healthcare Management",
    },
    {
      skill: "Healthcare Data Analytics",
      domain: "Healthcare Management",
    },
    {
      skill: "Population Health Management",
      domain: "Healthcare Management",
    },
    {
      skill: "Healthcare Revenue Cycle Management",
      domain: "Healthcare Management",
    },
    {
      skill: "Health Information Exchange (HIE) Standards",
      domain: "Healthcare Management",
    },
    {
      skill: "Curriculum Development",
      domain: "Education and Training",
    },
    {
      skill: "Instructional Design",
      domain: "Education and Training",
    },
    {
      skill: "Classroom Management",
      domain: "Education and Training",
    },
    {
      skill: "Assessment and Evaluation",
      domain: "Education and Training",
    },
    {
      skill: "Training Needs Analysis",
      domain: "Education and Training",
    },
    {
      skill: "E-Learning Development",
      domain: "Education and Training",
    },
    {
      skill: "Learning Management System (LMS) Administration",
      domain: "Education and Training",
    },
    {
      skill: "Gamification in Education",
      domain: "Education and Training",
    },
    {
      skill: "Competency-Based Education Design",
      domain: "Education and Training",
    },
    {
      skill: "Blended Learning Models",
      domain: "Education and Training",
    },
    {
      skill: "Adaptive Learning Technologies",
      domain: "Education and Training",
    },
    {
      skill: "Educational Data Mining Techniques",
      domain: "Education and Training",
    },
    {
      skill: "Content Strategy",
      domain: "Social Media Management",
    },
    {
      skill: "Social Media Analytics",
      domain: "Social Media Management",
    },
    {
      skill: "Community Engagement",
      domain: "Social Media Management",
    },
    {
      skill: "Campaign Management",
      domain: "Social Media Management",
    },
    {
      skill: "Influencer Marketing",
      domain: "Social Media Management",
    },
    {
      skill: "Crisis Management",
      domain: "Social Media Management",
    },
    {
      skill: "Social Listening Tools (e.g., Brandwatch, Sprout Social)",
      domain: "Social Media Management",
    },
    {
      skill: "Social Media Advertising Optimization",
      domain: "Social Media Management",
    },
    {
      skill: "Crisis Communication Planning",
      domain: "Social Media Management",
    },
    {
      skill: "Influencer Identification and Outreach",
      domain: "Social Media Management",
    },
    {
      skill: "Social Media Content Calendar Management",
      domain: "Social Media Management",
    },
    {
      skill: "Social Media ROI Analysis",
      domain: "Social Media Management",
    },
    {
      skill: "Media Relations",
      domain: "Public Relations",
    },
    {
      skill: "Press Release Writing",
      domain: "Public Relations",
    },
    {
      skill: "Crisis Communication",
      domain: "Public Relations",
    },
    {
      skill: "Event Planning and Management",
      domain: "Public Relations",
    },
    {
      skill: "Reputation Management",
      domain: "Public Relations",
    },
    {
      skill: "Stakeholder Engagement",
      domain: "Public Relations",
    },
    {
      skill: "Media Pitching Techniques",
      domain: "Public Relations",
    },
    {
      skill: "Crisis Communication Plan Execution",
      domain: "Public Relations",
    },
    {
      skill: "Media Training for Executives",
      domain: "Public Relations",
    },
    {
      skill: "Event Sponsorship Management",
      domain: "Public Relations",
    },
    {
      skill: "Press Release Distribution Strategies",
      domain: "Public Relations",
    },
    {
      skill: "Online Reputation Management",
      domain: "Public Relations",
    },
    {
      skill: "Event Planning and Coordination",
      domain: "Event Management",
    },
    {
      skill: "Budget Management",
      domain: "Event Management",
    },
    {
      skill: "Vendor Management",
      domain: "Event Management",
    },
    {
      skill: "Marketing and Promotion",
      domain: "Event Management",
    },
    {
      skill: "Risk Management",
      domain: "Event Management",
    },
    {
      skill: "On-Site Management",
      domain: "Event Management",
    },
    {
      skill: "Event ROI Measurement",
      domain: "Event Management",
    },
    {
      skill: "Event Sustainability Practices",
      domain: "Event Management",
    },
    {
      skill: "Event Registration and Ticketing Platforms",
      domain: "Event Management",
    },
    {
      skill: "Event Risk Management",
      domain: "Event Management",
    },
    {
      skill: "Event Technology Integration",
      domain: "Event Management",
    },
    {
      skill: "Post-event Survey Design and Analysis",
      domain: "Event Management",
    },
    {
      skill: "Guest Services",
      domain: "Hospitality Management",
    },
    {
      skill: "Hotel Operations",
      domain: "Hospitality Management",
    },
    {
      skill: "Revenue Management",
      domain: "Hospitality Management",
    },
    {
      skill: "Food and Beverage Management",
      domain: "Hospitality Management",
    },
    {
      skill: "Front Office Management",
      domain: "Hospitality Management",
    },
    {
      skill: "Hospitality Marketing",
      domain: "Hospitality Management",
    },
    {
      skill: "Revenue Management Systems (RMS)",
      domain: "Hospitality Management",
    },
    {
      skill: "Hotel Property Management Systems (PMS)",
      domain: "Hospitality Management",
    },
    {
      skill: "Customer Relationship Management (CRM) for Hospitality",
      domain: "Hospitality Management",
    },
    {
      skill: "Mobile Check-in and Keyless Entry Systems",
      domain: "Hospitality Management",
    },
    {
      skill: "Sustainable Hospitality Practices",
      domain: "Hospitality Management",
    },
    {
      skill: "Food and Beverage Cost Control Techniques",
      domain: "Hospitality Management",
    },
    {
      skill: "Property Management",
      domain: "Real Estate Management",
    },
    {
      skill: "Lease Negotiation",
      domain: "Real Estate Management",
    },
    {
      skill: "Facilities Management",
      domain: "Real Estate Management",
    },
    {
      skill: "Real Estate Investment Analysis",
      domain: "Real Estate Management",
    },
    {
      skill: "Property Valuation",
      domain: "Real Estate Management",
    },
    {
      skill: "Tenant Relations",
      domain: "Real Estate Management",
    },
    {
      skill: "Real Estate Investment Trust (REIT) Analysis",
      domain: "Real Estate Management",
    },
    {
      skill: "Property Portfolio Optimization",
      domain: "Real Estate Management",
    },
    {
      skill: "Lease Administration Software (e.g., MRI Software, Yardi)",
      domain: "Real Estate Management",
    },
    {
      skill: "Real Estate Crowdfunding Platforms",
      domain: "Real Estate Management",
    },
    {
      skill: "Real Estate Appraisal Methods",
      domain: "Real Estate Management",
    },
    {
      skill: "Real Estate Syndication Structures",
      domain: "Real Estate Management",
    },
    {
      skill: "Visual Merchandising",
      domain: "Retail Management",
    },
    {
      skill: "Inventory Control",
      domain: "Retail Management",
    },
    {
      skill: "Sales Forecasting",
      domain: "Retail Management",
    },
    {
      skill: "Retail Analytics",
      domain: "Retail Management",
    },
    {
      skill: "Customer Relationship Management (CRM)",
      domain: "Retail Management",
    },
    {
      skill: "Store Operations Management",
      domain: "Retail Management",
    },
    {
      skill: "Retail Analytics Platforms (e.g., RetailNext, Vend)",
      domain: "Retail Management",
    },
    {
      skill: "Visual Merchandising Software (e.g., InVision, Sketch)",
      domain: "Retail Management",
    },
    {
      skill: "Point-of-Sale (POS) Systems Management",
      domain: "Retail Management",
    },
    {
      skill: "Click-and-Collect Fulfillment Strategies",
      domain: "Retail Management",
    },
    {
      skill: "Loss Prevention Techniques",
      domain: "Retail Management",
    },
    {
      skill: "Customer Loyalty Program Management",
      domain: "Retail Management",
    },
    {
      skill: "Environmental Policy Analysis",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Sustainable Development Practices",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Renewable Energy Systems",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Waste Management",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Environmental Impact Assessment",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Conservation Strategies",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Life Cycle Assessment (LCA) Methods",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Environmental Impact Reporting Standards (e.g., GRI, CDP)",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Circular Economy Principles",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Sustainable Supply Chain Certification Programs",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Climate Risk Assessment Tools",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Environmental Social Governance (ESG) Investing Principles",
      domain: "Environmental Sustainability",
    },
    {
      skill: "Solar Energy Systems",
      domain: "Renewable Energy",
    },
    {
      skill: "Wind Energy Systems",
      domain: "Renewable Energy",
    },
    {
      skill: "Biomass Energy Systems",
      domain: "Renewable Energy",
    },
    {
      skill: "Hydroelectric Energy Systems",
      domain: "Renewable Energy",
    },
    {
      skill: "Energy Storage Technologies",
      domain: "Renewable Energy",
    },
    {
      skill: "Energy Policy and Regulation",
      domain: "Renewable Energy",
    },
    {
      skill: "Distributed Energy Resources (DER) Management",
      domain: "Renewable Energy",
    },
    {
      skill: "Microgrid Design and Optimization",
      domain: "Renewable Energy",
    },
    {
      skill: "Renewable Energy Financing Models",
      domain: "Renewable Energy",
    },
    {
      skill: "Energy Storage System Integration",
      domain: "Renewable Energy",
    },
    {
      skill: "Power Purchase Agreement (PPA) Negotiation",
      domain: "Renewable Energy",
    },
    {
      skill: "Renewable Energy Policy Advocacy",
      domain: "Renewable Energy",
    },
    {
      skill: "Aircraft Design",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Aerodynamics",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Structural Analysis",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Propulsion Systems",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Avionics",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Flight Testing",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Computational Fluid Dynamics (CFD) Simulation",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Aeroelasticity Analysis",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Aerospace Materials Testing",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Satellite Systems Engineering",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Avionics Integration Testing",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Aerospace Manufacturing Processes",
      domain: "Aerospace Engineering",
    },
    {
      skill: "Automotive Design",
      domain: "Automotive Engineering",
    },
    {
      skill: "Powertrain Systems",
      domain: "Automotive Engineering",
    },
    {
      skill: "Vehicle Dynamics",
      domain: "Automotive Engineering",
    },
    {
      skill: "Automotive Electronics",
      domain: "Automotive Engineering",
    },
    {
      skill: "Fuel Efficiency Technologies",
      domain: "Automotive Engineering",
    },
    {
      skill: "Vehicle Safety Systems",
      domain: "Automotive Engineering",
    },
    {
      skill: "Vehicle Dynamics Simulation",
      domain: "Automotive Engineering",
    },
    {
      skill: "Advanced Driver Assistance Systems (ADAS) Development",
      domain: "Automotive Engineering",
    },
    {
      skill: "Electric Vehicle (EV) Powertrain Design",
      domain: "Automotive Engineering",
    },
    {
      skill: "Crashworthiness Testing",
      domain: "Automotive Engineering",
    },
    {
      skill: "Automotive Embedded Systems Development",
      domain: "Automotive Engineering",
    },
    {
      skill: "Vehicle-to-Everything (V2X) Communication Protocols",
      domain: "Automotive Engineering",
    },
    {
      skill: "Structural Engineering",
      domain: "Civil Engineering",
    },
    {
      skill: "Transportation Engineering",
      domain: "Civil Engineering",
    },
    {
      skill: "Geotechnical Engineering",
      domain: "Civil Engineering",
    },
    {
      skill: "Environmental Engineering",
      domain: "Civil Engineering",
    },
    {
      skill: "Construction Management",
      domain: "Civil Engineering",
    },
    {
      skill: "Urban Planning",
      domain: "Civil Engineering",
    },
    {
      skill: "Geospatial Analysis Tools (e.g., GIS, LiDAR)",
      domain: "Civil Engineering",
    },
    {
      skill: "Environmental Impact Assessment (EIA) Methods",
      domain: "Civil Engineering",
    },
    {
      skill: "Geotechnical Instrumentation and Monitoring",
      domain: "Civil Engineering",
    },
    {
      skill: "Building Information Modeling (BIM)",
      domain: "Civil Engineering",
    },
    {
      skill: "Infrastructure Asset Management Systems",
      domain: "Civil Engineering",
    },
    {
      skill: "Traffic Engineering and Management",
      domain: "Civil Engineering",
    },
    {
      skill: "Circuit Design",
      domain: "Electrical Engineering",
    },
    {
      skill: "Power Systems Engineering",
      domain: "Electrical Engineering",
    },
    {
      skill: "Electronics",
      domain: "Electrical Engineering",
    },
    {
      skill: "Control Systems",
      domain: "Electrical Engineering",
    },
    {
      skill: "Renewable Energy Systems",
      domain: "Electrical Engineering",
    },
    {
      skill: "Signal Processing",
      domain: "Electrical Engineering",
    },
    {
      skill: "Power Electronics Design",
      domain: "Electrical Engineering",
    },
    {
      skill: "Electric Power Distribution Systems Analysis",
      domain: "Electrical Engineering",
    },
    {
      skill: "Renewable Energy Grid Integration",
      domain: "Electrical Engineering",
    },
    {
      skill: "Electric Vehicle Charging Infrastructure Design",
      domain: "Electrical Engineering",
    },
    {
      skill: "Power System Protection Techniques",
      domain: "Electrical Engineering",
    },
    {
      skill: "Programmable Logic Controllers (PLCs) Programming",
      domain: "Electrical Engineering",
    },
    {
      skill: "Mechanical Design",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Thermodynamics",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Fluid Mechanics",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Manufacturing Processes",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Materials Science",
      domain: "Mechanical Engineering",
    },
    {
      skill: "CAD/CAM Software",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Computational Mechanics Analysis",
      domain: "Mechanical Engineering",
    },
    {
      skill: "HVAC System Design",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Robotics Kinematics and Dynamics",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Advanced Materials Selection",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Additive Manufacturing (3D Printing)",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Failure Mode and Effects Analysis (FMEA)",
      domain: "Mechanical Engineering",
    },
    {
      skill: "Chemical Process Design",
      domain: "Chemical Engineering",
    },
    {
      skill: "Reaction Engineering",
      domain: "Chemical Engineering",
    },
    {
      skill: "Separation Processes",
      domain: "Chemical Engineering",
    },
    {
      skill: "Petrochemical Engineering",
      domain: "Chemical Engineering",
    },
    {
      skill: "Environmental Engineering",
      domain: "Chemical Engineering",
    },
    {
      skill: "Process Safety Management",
      domain: "Chemical Engineering",
    },
    {
      skill: "Process Intensification Techniques",
      domain: "Chemical Engineering",
    },
    {
      skill: "Computational Fluid Dynamics (CFD) Modeling",
      domain: "Chemical Engineering",
    },
    {
      skill: "Hazard Analysis and Risk Assessment (HAZOP)",
      domain: "Chemical Engineering",
    },
    {
      skill: "Chemical Plant Safety Instrumented Systems (SIS)",
      domain: "Chemical Engineering",
    },
    {
      skill: "Process Optimization Methods (e.g., DOE, Taguchi Methods)",
      domain: "Chemical Engineering",
    },
    {
      skill: "Chemical Process Simulation Software (e.g., Aspen Plus, CHEMCAD)",
      domain: "Chemical Engineering",
    },
    {
      skill: "Biomechanics",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Medical Imaging",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Biomaterials",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Bioinformatics",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Medical Device Design",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Rehabilitation Engineering",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Medical Device Regulatory Compliance",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Biomechanical Modeling and Simulation",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Rehabilitation Robotics Programming",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Bioinformatics Data Analysis",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Medical Imaging Processing Techniques",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Assistive Technology Design for Disabilities",
      domain: "Biomedical Engineering",
    },
    {
      skill: "Drug Discovery and Development",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Pharmacology",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Pharmaceutical Formulation",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Regulatory Affairs",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Pharmaceutical Manufacturing",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Clinical Trials Management",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Good Manufacturing Practices (GMP)",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Pharmacokinetic Modeling",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Clinical Data Management",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Pharmaceutical Formulation Optimization",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Drug Delivery Systems Design",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Pharmacovigilance and Drug Safety Monitoring",
      domain: "Pharmaceutical Sciences",
    },
    {
      skill: "Food Chemistry",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Food Microbiology",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Food Processing",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Food Safety and Quality Assurance",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Food Product Development",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Nutrition Science",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Sensory Evaluation Techniques",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Food Packaging Design for Shelf Life Extension",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Food Microstructure Analysis",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Regulatory Compliance in Food Labeling",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Food Processing Equipment Operation and Maintenance",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Food Biotechnology Applications",
      domain: "Food Sciences and Technology",
    },
    {
      skill: "Crop Management",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Soil Science",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Pest Management",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Irrigation Systems",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Agricultural Economics",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Sustainable Farming Practices",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Precision Agriculture Technologies (e.g., GPS, drones)",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Farm Management Software (e.g., FarmLogs, Granular)",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Integrated Pest Management (IPM) Strategies",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Soil Health Assessment Methods",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Agroforestry Practices",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Aquaponics and Hydroponics Systems Management",
      domain: "Agriculture and Farming",
    },
    {
      skill: "Forest Management",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Wildlife Conservation",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Ecosystem Restoration",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Timber Harvesting Techniques",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Forest Policy and Regulation",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Biodiversity Conservation",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Forest Carbon Sequestration Modeling",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Wildlife Corridor Planning",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Ecological Restoration Techniques",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Forest Certification Programs (e.g., FSC, PEFC)",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Forest Inventory and Monitoring Methods",
      domain: "Forestry and Conservation",
    },
    {
      skill: "GIS Applications in Forestry",
      domain: "Forestry and Conservation",
    },
    {
      skill: "Oceanographic Research Methods",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Coral Reef Conservation",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Fisheries Management",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Marine Pollution Control",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Marine Biotechnology",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Marine Ecology",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Oceanographic Data Analysis Software (e.g., MATLAB, R)",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Marine Spatial Planning",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Coral Reef Restoration Techniques",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Fisheries Stock Assessment Methods",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Marine Pollution Remediation Technologies",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Marine Species Identification Skills",
      domain: "Marine Biology and Oceanography",
    },
    {
      skill: "Pollution Control Engineering",
      domain: "Environmental Engineering",
    },
    {
      skill: "Water Resources Management",
      domain: "Environmental Engineering",
    },
    {
      skill: "Air Quality Management",
      domain: "Environmental Engineering",
    },
    {
      skill: "Hazardous Waste Management",
      domain: "Environmental Engineering",
    },
    {
      skill: "Environmental Impact Assessment",
      domain: "Environmental Engineering",
    },
    {
      skill: "Sustainable Infrastructure Design",
      domain: "Environmental Engineering",
    },
    {
      skill: "Environmental Impact Modeling Software (e.g., AERMOD, SWAT)",
      domain: "Environmental Engineering",
    },
    {
      skill: "Groundwater Remediation Technologies",
      domain: "Environmental Engineering",
    },
    {
      skill: "Air Pollution Control System Design",
      domain: "Environmental Engineering",
    },
    {
      skill: "Hazardous Materials Management Plans",
      domain: "Environmental Engineering",
    },
    {
      skill: "Environmental Compliance Auditing",
      domain: "Environmental Engineering",
    },
    {
      skill: "Environmental Management Systems (EMS) Implementation",
      domain: "Environmental Engineering",
    },
    {
      skill: "Land Use Planning",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Transportation Planning",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Zoning Regulations",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Urban Design",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Sustainable Urban Development",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Community Engagement",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Transit-Oriented Development (TOD) Planning",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Urban Green Space Design",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Smart Cities Technologies Integration",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Participatory Urban Planning Methods",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Urban Resilience Planning",
      domain: "Urban Planning and Development",
    },
    {
      skill: "Historic Preservation Planning",
      domain: "Urban Planning and Development",
    },
  ];

  const currentYear = new Date().getFullYear(); // Get the current year
  const SelectYear = [];

  for (let i = 0; i < 50; i++) {
    SelectYear.push({
      id: i + 1,
      name: (currentYear - i).toString(),
    });
  }
  const selectMonth = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const removeRowClickQuestion = (e, crrentindex) => {
    const optionList = [...workExperienceArray];
    const filterdata = optionList.filter(
      (data, index) => index !== crrentindex
    );
    setWorkExperienceArray(filterdata);
  };

  const handleCheckEmplayer = (index, e, selected) => {
    let temp = [...workExperienceArray];
    temp[index][selected] = e.target.value;
    if (selected === "DomainArea") {
      const filterData = SelectSkill.filter(
        (data) => data.domain === e.target.value
      );
      setSkillArray(filterData);
    }

    if (selected === "Country") {
      const filterCity = cities.find((data) => data.name === e.target.value);
      setshowCityByCountry([...filterCity.cities]);
    }
    setWorkExperienceArray(temp);
  };

  const handleNewRowQuestion = () => {
    const lastIndex = workExperienceArray.length - 1;

    setWorkExperienceIndex("");
    setEmployerError("");
    setCountryError("");
    setCityError("");
    setFormmonthError("");
    setFormYearError("");
    setTomonthError("");
    setToYearError("");
    setDomainError("");
    setSkillError("");

    if (workExperienceArray[lastIndex].Employer === "") {
      setWorkExperienceIndex(lastIndex);
      setEmployerError("Required");
      setWorkExperiencePress(true);
    }
    if (workExperienceArray[lastIndex].City === "") {
      setWorkExperienceIndex(lastIndex);
      setCityError("Required");
      setWorkExperiencePress(true);
    }
    if (workExperienceArray[lastIndex].Country === "") {
      setWorkExperienceIndex(lastIndex);
      setCountryError("Required");
      setWorkExperiencePress(true);
    }
    if (workExperienceArray[lastIndex].StartMonth === "") {
      setWorkExperienceIndex(lastIndex);
      setFormmonthError("Required");
      setWorkExperiencePress(true);
    }
    if (workExperienceArray[lastIndex].StartYear === "") {
      setWorkExperienceIndex(lastIndex);
      setFormYearError("Required");
      setWorkExperiencePress(true);
    }
    // if (workExperienceArray[lastIndex].EndMonth === "") {
    //   setWorkExperienceIndex(lastIndex);
    //   setTomonthError("Required");
    //   setWorkExperiencePress(true);
    // }
    // if (workExperienceArray[lastIndex].EndYear === "") {
    //   setWorkExperienceIndex(lastIndex);
    //   setToYearError("Required");
    //   setWorkExperiencePress(true);
    // }
    if (workExperienceArray[lastIndex].DomainArea === "") {
      setWorkExperienceIndex(lastIndex);
      setDomainError("Required");
      setWorkExperiencePress(true);
    }
    if (workExperienceArray[lastIndex].SkilsUsed === "") {
      setWorkExperienceIndex(lastIndex);
      setSkillError("Required");
      setWorkExperiencePress(true);
    }

    if (
      workExperienceArray[lastIndex].Employer === "" ||
      workExperienceArray[lastIndex].City === "" ||
      workExperienceArray[lastIndex].Country === "" ||
      workExperienceArray[lastIndex].StartMonth === "" ||
      workExperienceArray[lastIndex].StartYear === "" ||
      // workExperienceArray[lastIndex].EndMonth === "" ||
      // workExperienceArray[lastIndex].EndYear === "" ||
      workExperienceArray[lastIndex].SkilsUsed === "" ||
      workExperienceArray[lastIndex].DomainArea === ""
    ) {
      setWorkExperienceIndex(lastIndex);
      setSkillError(true);
    } else {
      setWorkExperienceArray([
        ...workExperienceArray,
        {
          Employer: "",
          City: "",
          Country: "",
          StartMonth: "",
          StartYear: "",
          EndMonth: "",
          EndYear: "",
          DomainArea: "",
          SkilsUsed: "",
        },
      ]);
    }
  };

  const studentUpdate = async () => {
    const formData = {
      _id: loginData._id,
      workExperience: workExperienceArray,
      workExperienceStatus: workExperience,
      currentFormStep: 6,
    };

    const student = await dispatch(updateStudent(formData));
    if (student.payload.success) {
      dispatch(setWorkExperience(formData));
      dispatch(dashPageStatuseChange(6));
    }
  };

  const WorkExperienceClick = async () => {
    if (studentDetail.currentFormStep > 5) {
      dispatch(dashPageStatuseChange(6));
    } else {
      setbuttonPress(true);
      if (workExperience === true) {
        const lastIndex = workExperienceArray.length - 1;

        setWorkExperienceIndex("");
        setEmployerError("");
        setCountryError("");
        setCityError("");
        setFormmonthError("");
        setFormYearError("");
        setTomonthError("");
        setToYearError("");
        setDomainError("");
        setSkillError("");

        if (workExperienceArray[lastIndex].Employer === "") {
          setWorkExperienceIndex(lastIndex);
          setEmployerError("Required");
          setWorkExperiencePress(true);
        }
        if (workExperienceArray[lastIndex].City === "") {
          setWorkExperienceIndex(lastIndex);
          setCityError("Required");
          setWorkExperiencePress(true);
        }
        if (workExperienceArray[lastIndex].Country === "") {
          setWorkExperienceIndex(lastIndex);
          setCountryError("Required");
          setWorkExperiencePress(true);
        }
        if (workExperienceArray[lastIndex].StartMonth === "") {
          setWorkExperienceIndex(lastIndex);
          setFormmonthError("Required");
          setWorkExperiencePress(true);
        }
        if (workExperienceArray[lastIndex].StartYear === "") {
          setWorkExperienceIndex(lastIndex);
          setFormYearError("Required");
          setWorkExperiencePress(true);
        }
        // if (workExperienceArray[lastIndex].EndMonth === "") {
        //   setWorkExperienceIndex(lastIndex);
        //   setTomonthError("Required");
        //   setWorkExperiencePress(true);
        // }
        // if (workExperienceArray[lastIndex].EndYear === "") {
        //   setWorkExperienceIndex(lastIndex);
        //   setToYearError("Required");
        //   setWorkExperiencePress(true);
        // }
        if (workExperienceArray[lastIndex].DomainArea === "") {
          setWorkExperienceIndex(lastIndex);
          setDomainError("Required");
          setWorkExperiencePress(true);
        }
        if (workExperienceArray[lastIndex].SkilsUsed === "") {
          setWorkExperienceIndex(lastIndex);
          setSkillError("Required");
          setWorkExperiencePress(true);
        }
        if (
          workExperienceArray[lastIndex].Employer !== "" &&
          workExperienceArray[lastIndex].City !== "" &&
          workExperienceArray[lastIndex].Country !== "" &&
          workExperienceArray[lastIndex].StartMonth !== "" &&
          workExperienceArray[lastIndex].StartYear !== "" &&
          // workExperienceArray[lastIndex].EndMonth !== "" &&
          // workExperienceArray[lastIndex].EndYear !== "" &&
          workExperienceArray[lastIndex].SkilsUsed !== "" &&
          workExperienceArray[lastIndex].DomainArea !== ""
        ) {
          await studentUpdate();
        } else {
          setWorkExperiencePress(true);
          setbuttonPress(true);
        }
      } else {
        await studentUpdate();
      }
    }
  };

  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Work Experience"
            description="While many candidates without work experience are accepted, we highly recommend those with work experience to include this information in their profile. Candidates with experience often receive significant consideration during the selection process"
          />
          <div className="inputs_container">
            <div className="radio_div_container">
              <span className="radio_title">
                Do you have any work experience?{" "}
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={workExperience === true}
                  onClick={() => {
                    setWorkEx(true);
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={workExperience === false}
                  onClick={() => {
                    setWorkEx(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>

            {workExperience === true && (
              <>
                {workExperienceArray.map((workexper, index) => (
                  <>
                    <div className="radio_div_container">
                      <TextField
                        label="Employer"
                        type="text"
                        id="text"
                        className="input_style"
                        error={
                          employerError !== "" && workExperienceIndex === index
                            ? true
                            : false
                        }
                        helperText={
                          workExperienceIndex === index ? employerError : ""
                        }
                        value={workexper?.Employer}
                        onChange={(e) =>
                          handleCheckEmplayer(index, e, "Employer")
                        }
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="Country"
                        select
                        className="input_style"
                        error={
                          countryError !== "" && workExperienceIndex === index
                            ? true
                            : false
                        }
                        helperText={
                          workExperienceIndex === index ? countryError : ""
                        }
                        value={workexper?.Country}
                        onChange={(e) =>
                          handleCheckEmplayer(index, e, "Country")
                        }
                        size="small"
                      >
                        {countries.map((data, index) => (
                          <MenuItem key={index} value={data.name}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="City"
                        select
                        className="input_style"
                        error={
                          cityError !== "" && workExperienceIndex === index
                            ? true
                            : false
                        }
                        helperText={
                          workExperienceIndex === index ? cityError : ""
                        }
                        value={workexper?.City}
                        onChange={(e) => handleCheckEmplayer(index, e, "City")}
                        size="small"
                      >
                        {showCityByCountry.map((data, index) => (
                          <MenuItem key={index} value={data.name}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="dn_input_box">
                      <div className="drop_small_container">
                        <TextField
                          id="select"
                          label="From month"
                          select
                          className="dropd_small_input_style_two"
                          error={
                            frommonthError !== "" &&
                            workExperienceIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            workExperienceIndex === index ? frommonthError : ""
                          }
                          value={workexper?.StartMonth}
                          onChange={(e) =>
                            handleCheckEmplayer(index, e, "StartMonth")
                          }
                          size="small"
                        >
                          {selectMonth.map((year, index) => (
                            <MenuItem key={index} value={year.name}>
                              {year.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="From Year"
                          select
                          className="dropd_small_input_style_two"
                          error={
                            fromYearError !== "" &&
                            workExperienceIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            workExperienceIndex === index ? fromYearError : ""
                          }
                          value={workexper?.StartYear}
                          onChange={(e) =>
                            handleCheckEmplayer(index, e, "StartYear")
                          }
                          size="small"
                        >
                          {SelectYear.map((year, index) => (
                            <MenuItem key={index} value={year.name}>
                              {year.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="dn_input_box">
                      <div className="drop_small_container">
                        <TextField
                          id="select"
                          label="To month"
                          select
                          className="dropd_small_input_style_two"
                          error={
                            TomonthError !== "" && workExperienceIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            workExperienceIndex === index ? TomonthError : ""
                          }
                          value={workexper?.EndMonth}
                          onChange={(e) =>
                            handleCheckEmplayer(index, e, "EndMonth")
                          }
                          size="small"
                        >
                          {selectMonth.map((year, index) => (
                            <MenuItem key={index} value={year.name}>
                              {year.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="To Year"
                          select
                          className="dropd_small_input_style_two"
                          error={
                            ToYearError !== "" && workExperienceIndex === index
                              ? true
                              : false
                          }
                          helperText={
                            workExperienceIndex === index ? ToYearError : ""
                          }
                          value={workexper?.EndYear}
                          onChange={(e) =>
                            handleCheckEmplayer(index, e, "EndYear")
                          }
                          size="small"
                        >
                          {SelectYear.map((year, index) => (
                            <MenuItem key={index} value={year.name}>
                              {year.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="Domain Area"
                        select
                        className="input_style"
                        error={
                          domainError !== "" && workExperienceIndex === index
                            ? true
                            : false
                        }
                        helperText={
                          workExperienceIndex === index ? domainError : ""
                        }
                        value={workexper?.DomainArea}
                        onChange={(e) =>
                          handleCheckEmplayer(index, e, "DomainArea")
                        }
                        size="small"
                      >
                        {SelectDomain.sort((a, b) =>
                          a.CIPDESC > b.CIPDESC ? 1 : -1
                        ).map((data, index) => (
                          <MenuItem key={index} value={data}>
                            {data}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="dn_input_box">
                      <TextField
                        id="select"
                        label="Skills Used"
                        select
                        className="input_style"
                        error={
                          skillError !== "" && workExperienceIndex === index
                            ? true
                            : false
                        }
                        helperText={
                          workExperienceIndex === index ? skillError : ""
                        }
                        value={workexper?.SkilsUsed}
                        onChange={(e) =>
                          handleCheckEmplayer(index, e, "SkilsUsed")
                        }
                        size="small"
                      >
                        {skillArray.map((data, index) => (
                          <MenuItem key={index} value={data.skill}>
                            {data.skill}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    {workExperienceArray.length !== 1 && (
                      <div className="button_container">
                        <Button
                          type="primary"
                          // loading={loading}
                          iconPosition="end"
                          icon={<PiPaperPlaneRight />}
                          size="large"
                          onClick={(e) => removeRowClickQuestion(e, index)}
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                  </>
                ))}

                {workExperienceArray.length !== 2 && (
                  <div className="button_container">
                    <Button
                      type="primary"
                      // loading={loading}
                      iconPosition="end"
                      icon={<PiPaperPlaneRight />}
                      size="large"
                      onClick={handleNewRowQuestion}
                    >
                      Add More
                    </Button>
                  </div>
                )}
              </>
            )}

            <div className="button_container_distance">
              <Button
                type="primary"
                iconPosition="start"
                icon={<HiChevronDoubleLeft />}
                size="large"
                onClick={() => dispatch(dashPageStatuseChange(4))}
                className="back_btn_btn"
              >
                Back
              </Button>
              <Button
                type="primary"
                // loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={() => WorkExperienceClick()}
              >
                Save & Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkExperience;
