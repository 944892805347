import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Button, Flex, Form, Col, Divider, Row, Typography, Radio } from "antd";
import {
  setStudentRegdInfo,
  studentEvailVerify,
} from "../../redux/StudentRegd/StudentRegdSlice";

const FillInfoDetails = ({ degreeName, questionResponse }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [Email_Confirm, setEmail_Confirm] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthDate, setBirthDate] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);
  const [showmodel, setShowmodel] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [birthYearError, setBirthYearError] = useState("");
  const [birthMonthError, setBirthMonthError] = useState("");
  const [birthDateError, setBirthDateError] = useState("");

  useEffect(() => {
    if (btnPress === true) {
      if (Email_Confirm === "") {
        setEmailError("Required");
      } else {
        setEmailError("");
      }
      if (firstname === "") {
        setFirstnameError("Required");
      } else {
        setFirstnameError("");
      }
      if (lastname === "") {
        setLastnameError("Required");
      } else {
        setLastnameError("");
      }
      if (birthYear === "") {
        setBirthYearError("Required");
      } else {
        setBirthYearError("");
      }
      if (birthMonth === "") {
        setBirthMonthError("Required");
      } else {
        setBirthMonthError("");
      }
      if (birthDate === "") {
        setBirthDateError("Required");
      } else {
        setBirthDateError("");
      }
    }
  }, [
    btnPress,
    Email_Confirm,
    firstname,
    lastname,
    birthYear,
    birthMonth,
    birthDate,
  ]);

  const validateEmail = async (e) => {
    const inputValue = e.target.value;
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const email_Regex = /@[a-z]/;
    const emailtotalRegex = /.[a-z]{2,3}/;
    setEmail(inputValue);
    let error = "";
    if (!email_Regex.test(inputValue)) {
      error = "email address @ is missing like examplegmail.com";
      setEmailError("email address @ is missing like examplegmail.com");
      // setEmailError("*Error! you have entered invalid email.");
    } else if (!emailtotalRegex.test(inputValue)) {
      // error = "email address domain is missing like example@";
      setEmailError("email address domain is missing like example@");
    } else if (!emailRegex.test(inputValue)) {
      setEmailError("*Error! you have entered invalid email.");
    } else {
      const verifyemail = await dispatch(studentEvailVerify(inputValue));
      if (verifyemail.payload.success === true) {
        setEmailError("Email already exits");
      } else {
        setEmail_Confirm(inputValue);
        setEmailError("");
      }
    }

    // setEmailError(error);
  };

  const currentYear = new Date().getFullYear(); // Get the current year
  const SelectYear = [];

  for (let i = 0; i < 50; i++) {
    SelectYear.push({
      id: i + 1,
      name: (currentYear - i - 18).toString(),
    });
  }

  const selectMonth = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const selectDate = [];

  for (let i = 1; i <= 31; i++) {
    selectDate.push({
      id: i,
      name: i.toString(),
    });
  }

  const loginClick = async (e) => {
    setbtnPress(true);

    if (Email_Confirm === "") {
      setEmailError("Required");
    } else {
      setEmailError("");
    }
    if (firstname === "") {
      setFirstnameError("Required");
    } else {
      setFirstnameError("");
    }
    if (lastname === "") {
      setLastnameError("Required");
    } else {
      setLastnameError("");
    }
    if (birthYear === "") {
      setBirthYearError("Required");
    } else {
      setBirthYearError("");
    }
    if (birthMonth === "") {
      setBirthMonthError("Required");
    } else {
      setBirthMonthError("");
    }
    if (birthDate === "") {
      setBirthDateError("Required");
    } else {
      setBirthDateError("");
    }

    if (
      Email_Confirm !== "" &&
      firstname !== "" &&
      lastname !== "" &&
      birthYear !== "" &&
      birthMonth !== "" &&
      birthDate !== ""
    ) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      OTP = "111444";

      const formdata = {
        email: Email_Confirm,
        firstname:
          firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase(),
        lastname:
          lastname.charAt(0).toUpperCase() + lastname.slice(1).toLowerCase(),
        birthdate: `${birthYear}-${birthMonth}-${birthDate}`,
        degreeName: degreeName,
        questionResponse: questionResponse,
        otp: OTP,
      };

      dispatch(setStudentRegdInfo(formdata));
      navigate("/pin");
    }
  };

  return (
    <>
      <div className="account-area pt-10 pb-10 backgroundimage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10 pt-10 fillinfopadding">
              <div className="account-wrap">
                <div className="account-main">
                  <div className="sing_image">
                    <Link to="/">
                      <img src="assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  <div
                    style={{
                      marginTop: "5vh",
                    }}
                  ></div>
                  {/* <h3 className="account-title">Sign Up to Your Account 👋</h3> */}
                  <span className="account-form">
                    <div className="account-form-item mb-10">
                      <TextField
                        label="First Name"
                        className="textfield"
                        type="text"
                        id="text"
                        style={{ width: "100%" }}
                        error={firstnameError !== "" ? true : false}
                        helperText={firstnameError}
                        onChange={(e) =>
                          setFirstname(
                            e.target.value
                              .replace(/[^A-Za-z\s]/gi, "")
                              .replace(/\b\w/g, (char) => char.toUpperCase())
                          )
                        }
                        value={firstname}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="account-form-item mb-10">
                      <TextField
                        label="Last Name"
                        className="textfield"
                        type="text"
                        id="text"
                        style={{ width: "100%" }}
                        error={lastnameError !== "" ? true : false}
                        helperText={lastnameError}
                        onChange={(e) =>
                          setLastname(
                            e.target.value
                              .replace(/[^A-Za-z\s]/gi, "")
                              .replace(/\b\w/g, (char) => char.toUpperCase())
                          )
                        }
                        value={lastname}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="account-form-item mb-10">
                      <TextField
                        label="Email address"
                        className="textfield"
                        type="email"
                        id="text"
                        style={{ width: "100%" }}
                        error={emailError !== "" ? true : false}
                        helperText={emailError}
                        onChange={(e) => validateEmail(e)}
                        value={email}
                        autocomplete="off"
                        size="small"
                      />
                    </div>
                    <div className="account-form-item mb-10">
                      Date of birth:
                      <div className="drop_down_conta mt-2">
                        <TextField
                          id="select"
                          label="Date"
                          select
                          className="ant_drop_down_small"
                          error={birthDateError !== "" ? true : false}
                          helperText={birthDateError}
                          value={birthDate}
                          onChange={(e) => setBirthDate(e.target.value)}
                          size="small"
                        >
                          {selectDate.map((date, index) => (
                            <MenuItem value={date.name} key={index}>
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Month"
                          select
                          className="ant_drop_down_small"
                          error={birthMonthError !== "" ? true : false}
                          helperText={birthMonthError}
                          value={birthMonth}
                          onChange={(e) => setBirthMonth(e.target.value)}
                          size="small"
                        >
                          {selectMonth.map((date, index) => (
                            <MenuItem value={date.name} key={index}>
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Year"
                          select
                          className="ant_drop_down_small"
                          error={birthYearError !== "" ? true : false}
                          helperText={birthYearError}
                          value={birthYear}
                          onChange={(e) => setBirthYear(e.target.value)}
                          size="small"
                        >
                          {SelectYear.map((date, index) => (
                            <MenuItem value={date.name} key={index}>
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    <div className="account-form-button d-flex justify-content-center">
                      <button
                        type="submit"
                        className="account-btn"
                        onClick={(e) => loginClick(e)}
                      >
                        Continue
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReactTooltip
          id="my-tooltip-1"
          place="top"
          variant="info"
          content="In the USA, Bachelor's programs require applicants to have completed their secondary education, which means obtaining a high school diploma or an equivalent following 12 years of schooling.  Also, please note that there are a few colleges or universities offering transfer or lateral admission into their 2-year Bachelor's program if the applicant has completed courses equivalent to an Associate Degree (2 years) in the USA. This requires an educational evaluation of your completed post-secondary courses. It's a good opportunity to join a 2-year Bachelor's degree program. "
          className="tooltip_style"
        />
      </div>
    </>
  );
};

export default FillInfoDetails;
