import React from "react";
import Hadear from "./layout/Hadear";
import { Outlet } from "react-router-dom";

const SearchResultLayout = () => {
  return (
    <>
      <Hadear />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default SearchResultLayout;
