import React from "react";
import { Link } from "react-router-dom";
import { GoCreditCard } from "react-icons/go";
import { BsPersonVcard } from "react-icons/bs";
import { GiBookshelf } from "react-icons/gi";
import { FaGraduationCap } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { RxExternalLink } from "react-icons/rx";
import { CiStar } from "react-icons/ci";
import { IoIosShareAlt } from "react-icons/io";
import { PiStudentFill } from "react-icons/pi";
import { Button } from "antd";

const ProgramDetailsModel = ({ selectedProgram }) => {
  return (
    <>
      <div className="col-xl-12 h2_coursecol-lg-12 col-md-12">
        <div className="-item mb-30 containerofchange">
          <div className="h2_course-content" >
            <h5 className="h2_course-content-title">
              <span
                style={{
                  color: "#ffff"
                }}
              >
                {selectedProgram.programName}
              </span>
            </h5>
            {/* <div className="h2_course-content-info">
              <span>
                <i className="fa-thin fa-book-blank" />
                23 Lessons
              </span>
              <span>
                <i className="fa-thin fa-user-group" />
                45 Students
              </span>
            </div> */}
            <div className="program_det_card">
              <div className="flex-start">
                <div style={{
                  marginTop: "4px",
                  height: "35px", width: "35px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #ffff",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", borderRadius: "50%"
                }}>
                  {/* <div className="tomake_circle"> */}
                  <FaGraduationCap className="iconmmmmmm" />
                  {/* </div> */}
                </div>
                <div className="card_data">
                  <div className="heavy-font">Fall,Spring</div>
                  <span className="prog_disc">Admission Session</span>
                </div>

              </div>
              <div className="flex-start">
                <div style={{
                  marginTop: "4px",
                  height: "35px", width: "35px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  border: "1px solid #ffff", borderRadius: "50%"
                }}>
                  <BsPersonVcard
                    className="iconmmmmmm"
                  />
                </div>
                <div className="card_data">
                  <div className="heavy-font">2.75 GPA</div>
                  <span className="prog_disc">Eligibility</span>
                </div>
              </div>
              <div className="flex-start">
                <div style={{
                  marginTop: "4px",
                  height: "35px", width: "35px",
                  display: 'flex',
                  alignItems: "center",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  justifyContent: "center",
                  border: "1px solid #ffff", borderRadius: "50%"
                }}>
                  <GoCreditCard
                    className="iconmmmmmm"
                  />
                </div>
                <div className="card_data">
                  <div className="heavy-font">
                    USD
                    {selectedProgram.tutionFees !== ""
                      ? "30000"
                      : selectedProgram.tutionFees}
                  </div>
                  <span className="prog_disc">
                    Total Cost (Including Tuition)
                  </span>
                </div>
              </div>
              <div className="flex-start">
                <div style={{
                  marginTop: "4px",
                  height: "35px", width: "35px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  border: "1px solid #ffff", borderRadius: "50%"
                }}>
                  <FaGraduationCap
                    className="iconmmmmmm"
                  />
                </div>
                <div className="card_data">
                  <div className="heavy-font">18 Months</div>
                  <span className="prog_disc">Duration</span>
                </div>
              </div>
              <div className="flex-start_two">
                <div style={{
                  marginTop: "4px",
                  height: "35px", width: "35px",
                  display: 'flex',
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #ffff", borderRadius: "50%"
                }}>
                  <FaBook
                    className="iconmmmmmm" />
                </div>
                <div className="card_data">
                  <div className="heavy-font_two">
                    IELTS - 6.5 / TOEFL - 80 / GRE / GMAT Not Required
                  </div>
                  <span className="prog_disc">
                    English proficiency requirement
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="h2_course-content-bottom">
            <div className="h2_course-bottom-price">
              <span><del>$140</del>$96.00</span>
            </div>
            <div className="h2_course-bottom-btn">
              <Link>
                More Details
                <i className="fa-light fa-arrow-right" />
                <i className="fa-light fa-arrow-right" />
              </Link>
            </div>
          </div> */}
        </div>
        {/* <div className="above_clas">

          <div className="btn_side_to_move">
            <Button key="back"
              className="btttttttnnn"
            onClick={handleCancel}
            >
              Return
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="btnnnnnnbtn"
            loading={loading}
            onClick={handleOk}
            >
              Apply Now
            </Button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProgramDetailsModel;
