import React from 'react'
import img1 from './profile.jpeg'
const ProfileImage = () => {
    return (
        <div>

            <div className='profile_imageeee'>
                <div className='inside_profileeee'>
                    <div>
                        <p className='top_img'>Now put on your headset </p>
                    </div>
                    <div className='prodile_cotttt'>
                        <img src={img1} alt='error' className='img_insthat' />
                    </div>
                    <div className='on_click_on'><p>Click on <span className='cotinous'> Continue</span> to go on</p></div>
                </div>

            </div>

        </div>
    )
}

export default ProfileImage
