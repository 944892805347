import React from "react";
import { useSelector } from "react-redux";
import WelCome from "./components/WelCome";
import Personal_Information from "./components/Personal_Information";
import TravelData from "./components/TravelData";
import AdmissionSession from "./components/AdmissionSession";
import Education from "./components/Education";
import WorkExperience from "./components/WorkExperience";
import FinancialSupport from "./components/FinancialSupport";
import OtherInformation from "./components/OtherInformation";
import SubscriptionSucces from "./components/SubscriptionSucces";
import OtherObjectives from "./components/OtherObjectives";
import English_Test from "./components/English_Test";

const RegistationComponents = () => {
  const { pageRenderStatus } = useSelector((store) => store.DashboardPage);

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return (
          <>
            <WelCome />
          </>
        );
      case 1:
        return (
          <>
            <Personal_Information />
          </>
        );
      case 2:
        return (
          <>
            <TravelData />
          </>
        );
      case 3:
        return (
          <>
            <AdmissionSession />
          </>
        );
      case 4:
        return (
          <>
            <Education />
          </>
        );
      case 5:
        return (
          <>
            <WorkExperience />
          </>
        );
      case 6:
        return (
          <>
            <FinancialSupport />
          </>
        );
      case 7:
        return (
          <>
            <English_Test />
          </>
        );

      case 8:
        return (
          <>
            <OtherInformation />
          </>
        );
      case 9:
        return (
          <>
            <OtherObjectives />
          </>
        );
      case 10:
        return (
          <>
            <SubscriptionSucces />
          </>
        );

      default:
        return (
          <>
            <WelCome />
          </>
        );
    }
  };

  return <>{renderSwitch(pageRenderStatus)}</>;
  // return <>{renderSwitch(7)}</>;
};

export default RegistationComponents;
