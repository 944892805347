import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { updateStudent } from "../redux/StudentRegd/StudentRegdSlice";
import { authActions } from "../redux/authentication/AuthenticationSlice";
import { dashPageStatuseChange } from "../redux/dashboard/PageRenderSlice";

const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((store) => store.Athentication);
  const subscriptionClick = async (name, price, month) => {
    const formdata = {
      _id: loginData._id,
      planName: name,
      planPrice: price,
      planMonth: month,
      subscriptionstatus: true,
      currentFormStep: 9,
    };
    const student = await dispatch(updateStudent(formdata));
    if (student.payload.success) {
      dispatch(
        authActions.signin({
          ...student.payload.student,
          isAuth: true,
        })
      );
      navigate("/dashbord/welcome");
      dispatch(dashPageStatuseChange(9));
    }
  };
  return (
    <>
      <section className="innerPage_price-area pt-10 pb-10">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-40">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#F14D5D",
                  }}
                >
                  Pricing
                </span>
                <h2 className="section-title mb-0">Explore Pricing</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="h2_price-item mb-30">
                <div className="h2_price-item-title">
                  <h5>Primary</h5>
                </div>
                <div className="h2_price-amount">
                  <del>$25.00</del>
                  <div className="h2_price-amount-info">
                    <h2>$20</h2>
                    <p>
                      <span>Per</span>
                      <span>3 Month</span>
                    </p>
                  </div>
                </div>
                <div className="h2_price-middle-info">
                  <p className="h2_price-middle-info-1">
                    Discounted For International Students
                  </p>
                </div>
                <div
                  className="h2_price-button"
                  onClick={(e) => subscriptionClick("Primary", 20, 3)}
                >
                  <Link>Choose Plan</Link>
                </div>
                <div className="h3_price-content">
                  <div className="h2_price-content-list">
                    <ul className="list_style">
                      <li>
                        <FaCheckCircle className="check" />
                        Match University
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Application Prep
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Test Prep
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Profile Analysis
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="h2_price-item mb-30">
                <div className="h2_price-popular-tag">
                  <span>Most Popular</span>
                </div>
                <div className="h2_price-item-title">
                  <h5>Popular</h5>
                </div>
                <div className="h2_price-amount">
                  <del>$35.00</del>
                  <div className="h2_price-amount-info">
                    <h2>$30</h2>
                    <p>
                      <span>Per</span>
                      <span>3 Month</span>
                    </p>
                  </div>
                </div>
                <div className="h2_price-middle-info">
                  <p className="h2_price-middle-info-1">
                    Discounted For International Students
                  </p>
                </div>
                <div
                  className="h2_price-button"
                  onClick={(e) => subscriptionClick("Popular", 30, 3)}
                >
                  <Link>Choose Plan</Link>
                </div>
                <div className="h3_price-content">
                  <div className="h2_price-content-list">
                    <ul className="list_style">
                      <li>
                        <FaCheckCircle className="check" />
                        Match University
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Application Prep
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Test Prep
                      </li>
                      <li>
                        <AiFillCloseCircle className="non_check" />
                        Profile Analysis
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="h2_price-item mb-30">
                <div className="h2_price-item-title">
                  <h5>Value</h5>
                </div>
                <div className="h2_price-amount">
                  <del>$60.00</del>
                  <div className="h2_price-amount-info">
                    <h2>$50</h2>
                    <p>
                      <span>Per</span>
                      <span>3 Month</span>
                    </p>
                  </div>
                </div>
                <div className="h2_price-middle-info">
                  <p className="h2_price-middle-info-1">
                    Discounted For International Students
                  </p>
                </div>
                <div
                  className="h2_price-button"
                  onClick={(e) => subscriptionClick("Value", 50, 3)}
                >
                  <Link>Choose Plan</Link>
                </div>
                <div className="h2_price-content">
                  <div className="h2_price-content-list">
                    <ul className="list_style">
                      <li>
                        <FaCheckCircle className="check" />
                        Match University
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Application Prep
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Test Prep
                      </li>
                      <li>
                        <FaCheckCircle className="check" />
                        Profile Analysis
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscription;
