import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { studentEvailVerify } from "../redux/StudentRegd/StudentRegdSlice";
import { SetPassword_reset_email } from "../redux/authentication/AuthenticationSlice";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [Email_Confirm, setEmail_Confirm] = useState("");
  const [emailError, setEmailError] = useState("");
  const [visiable, setVisiable] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  useEffect(() => {
    if (btnPress === true) {
      if (Email_Confirm === "") {
        setEmailError("Required");
      } else {
        setEmailError("");
      }
    }
  }, [btnPress, Email_Confirm]);

  const validateEmail = async (e) => {
    const inputValue = e.target.value;
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const email_Regex = /@[a-z]/;
    const emailtotalRegex = /.[a-z]{2,3}/;
    setEmail(inputValue);
    let error = "";
    if (!email_Regex.test(inputValue)) {
      error = "email address @ is missing like examplegmail.com";
      setEmailError("email address @ is missing like examplegmail.com");
      // setEmailError("*Error! you have entered invalid email.");
    } else if (!emailtotalRegex.test(inputValue)) {
      // error = "email address domain is missing like example@";
      setEmailError("email address domain is missing like example@");
    } else if (!emailRegex.test(inputValue)) {
      setEmailError("*Error! you have entered invalid email.");
    } else {
      setEmail_Confirm(inputValue);
      setEmailError("");
    }
  };

  const verify_email_press = async (e) => {
    e.preventDefault();
    setbtnPress(true);
    if (Email_Confirm !== "") {
      const verifyemail = await dispatch(studentEvailVerify(Email_Confirm));
      if (verifyemail.payload.success === true) {
        dispatch(SetPassword_reset_email(Email_Confirm));
        navigate("/reset-password");
      } else {
        setEmailError("Email not exits");
      }
    }
  };

  return (
    <>
      <div className="account-area pt-10 pb-10 backgroundimage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <div className="account-wrap">
                <div className="account-main">
                  <div className="sing_image">
                    <Link to="/">
                      <img src="assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  <h3 className="account-title">Forgot Password</h3>
                  <span className="account-form">
                    <div className="account-form-item mb-20">
                      <div className="">
                        <TextField
                          label="Enter Email ID"
                          className="textfield"
                          type="email"
                          id="text"
                          style={{ width: "100%" }}
                          error={emailError !== "" ? true : false}
                          helperText={emailError}
                          onChange={(e) => {
                            validateEmail(e);
                          }}
                          value={email}
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <div className="account-form-button">
                      <button
                        type="submit"
                        className="account-btn"
                        onClick={(e) => verify_email_press(e)}
                      >
                        Reset Password
                      </button>
                    </div>
                  </span>

                  <div className="account-bottom">
                    <div className="account-bottom-text">
                      <p>
                        Have an account ? <Link to="/sign-in">Sign In</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
