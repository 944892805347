import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, message } from "antd";
import { studentContactUs } from "../redux/authentication/AuthenticationSlice";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [name, setName] = useState("");
  const [name_errror, setName_error] = useState("");
  const [email, setEmail] = useState("");
  const [email_error, setEmail_error] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumbe_error, setMobileNumber_error] = useState("");
  const [feelFree, setfeelFree] = useState("");
  const [feelFree_error, setfeelFree_error] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (buttonPress === true) {
      if (name === "") {
        setName_error("Required");
      } else {
        setName_error("");
      }

      if (email === "") {
        setEmail_error("Required");
      } else {
        setEmail_error("");
      }

      if (mobileNumber === "") {
        setMobileNumber_error("Required");
      } else {
        setMobileNumber_error("");
      }
      if (feelFree === "") {
        setfeelFree_error("Required");
      } else {
        setfeelFree_error("");
      }
    }
  }, [buttonPress, name, email, mobileNumber, feelFree]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);
    if (name !== "" && email !== "" && mobileNumber !== "" && feelFree !== "") {
      const formData = {
        name: name,
        email: email,
        mobile_number: mobileNumber,
        feel_free: feelFree,
      };
      const data = await dispatch(studentContactUs(formData));
      if (data.payload.success) {
        messageApi.open({
          type: "success",
          content: "Massage send successfully",
        });
        setbuttonPress(false);
        setName("");
        setName_error("");
        setEmail("");
        setEmail_error("");
        setMobileNumber("");
        setMobileNumber_error("");
        setfeelFree("");
        setfeelFree_error("");
      } else {
        setbuttonLoading(false);
      }
    } else {
      setbuttonLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <section className="contact-area pt-10 pb-10">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-40">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#F14D5D",
                  }}
                >
                  Contact Us
                </span>
                <h2 className="section-title mb-0">Contact Us</h2>
              </div>
            </div>
          </div>
          <div className="contact-wrap">
            <div className="row">
              <div className="col-xl-8 col-md-8">
                <div className="contact-content pr-80 mb-20">
                  <h3 className="contact-title mb-25">Send Me Message</h3>
                  <form action="#" className="contact-form">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                        <div className="contact-form-input mb-30">
                          <input
                            type="text"
                            placeholder="Your Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            //  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
                            style={
                              name_errror === ""
                                ? {
                                    borderBottom:
                                      "1px solid rgba(4, 0, 23, 0.14)",
                                  }
                                : {
                                    borderBottom: "1px solid  #ff0000",
                                  }
                            }
                          />
                          <span className="inner-icon">
                            <i className="fa-thin fa-user" />
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                        <div className="contact-form-input mb-30">
                          <input
                            type="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={
                              email_error === ""
                                ? {
                                    borderBottom:
                                      "1px solid rgba(4, 0, 23, 0.14)",
                                  }
                                : {
                                    borderBottom: "1px solid  #ff0000",
                                  }
                            }
                          />
                          <span className="inner-icon">
                            <i className="fa-thin fa-envelope" />
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6">
                        <div className="contact-form-input mb-30">
                          <input
                            type="tel"
                            placeholder="Your Number"
                            // pattern="^[6-9]\d{9}"
                            // minLength="10"
                            // maxLength="10"
                            inputMode="numeric"
                            style={
                              mobileNumbe_error === ""
                                ? {
                                    borderBottom:
                                      "1px solid rgba(4, 0, 23, 0.14)",
                                  }
                                : {
                                    borderBottom: "1px solid  #ff0000",
                                  }
                            }
                            value={mobileNumber}
                            onChange={(e) =>
                              setMobileNumber(
                                e.target.value.replace(/[^0-9]/g, "")
                              )
                            }
                          />
                          <span className="inner-icon">
                            <i className="fa-thin fa-phone-volume" />
                          </span>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="contact-form-input mb-50 contact-form-textarea">
                          <textarea
                            name="message"
                            value={feelFree}
                            cols={30}
                            style={
                              feelFree_error === ""
                                ? {
                                    borderBottom:
                                      "1px solid rgba(4, 0, 23, 0.14)",
                                  }
                                : {
                                    borderBottom: "1px solid  #ff0000",
                                  }
                            }
                            onChange={(e) => setfeelFree(e.target.value)}
                            rows={10}
                            placeholder="Feel free to get in touch!"
                            defaultValue={""}
                          />
                          <span className="inner-icon">
                            <i className="fa-thin fa-pen" />
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="contact-form-submit mb-30">
                          <div
                            className="contact-form-btn"
                            onClick={(e) => handleSubmit(e)}
                          >
                            <Link className="theme-btn contact-btn">
                              Send Message
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-xl-4 col-md-4">
                <div className="contact-info ml-50 mb-20">
                  <h3 className="contact-title mb-40">Get In Touch</h3>
                  <div className="contact-info-item">
                    <span>
                      <i className="fa-thin fa-location-dot" />
                      Address
                    </span>
                    <p>Hilton Conference Centre</p>
                  </div>
                  <div className="contact-info-item">
                    <span>
                      <i className="fa-thin fa-mobile-notch" />
                      Phone
                    </span>
                    <a href="tel:+123548645850">+123 548 6458 50</a>
                  </div>
                  <div className="contact-info-item">
                    <span>
                      <i className="fa-thin fa-envelope" />
                      Email
                    </span>
                    <a href="mailto:example@gmail.com">info@f1visa.com</a>
                  </div>
                  <div className="contact-social">
                    <span>Social Media</span>
                    <ul>
                      <li>
                        <Link>
                          <i className="fa-brands fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <i className="fa-brands fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <i className="fa-brands fa-linkedin-in" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d147120.012062842!2d13.706000467398074!3d51.075159941942076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1senveto!5e0!3m2!1sen!2sbd!4v1680961754336!5m2!1sen!2sbd"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </section>
    </>
  );
};

export default ContactUs;
