import React from 'react'
import { IoMdExit } from "react-icons/io";
import { BiFullscreen } from "react-icons/bi";
import { FaArrowRight, FaSearch } from "react-icons/fa";
import { IoArrowBackOutline } from "react-icons/io5";
import { GrLinkNext } from "react-icons/gr";
import { GrFormNextLink } from "react-icons/gr";
import { WiTime4 } from "react-icons/wi";
import { ImArrowRight } from "react-icons/im";

const QuestionAndAnswerr = () => {
    return (
        <div>

            <div className='to_flex_time_last_content'>
                <div className='side_contwnt_time'>
                    <div className='for_give_border'>
                        <p>00:33:51</p>
                    </div>
                    <div className='inside_content'>
                        <span className='watchhhhh'><WiTime4 /></span>
                        <span className='to_fontttt'>Hide Time</span>
                    </div>

                </div>

            </div>
            <div className='two_Containerr_side'>
                <div className='side_left_con'>
                    <div className='container_paragr'>

                        <span>  <ImArrowRight /></span>
                        <span>  [1]</span>
                        <span>Creating a single paragraph with 300 lines would be impractical for readability, but I can provide a comprehensive paragraph along with some thought-provoking questions. Here’s a detailed paragraph about climate change:
                            Climate change is one of the most pressing
                            challenges facing humanity today, characterized by rising global temperatures, shifting weather patterns, and increasing frequency of extreme weather events. It is driven primarily by the accumulation of greenhouse gases in the atmosphere, largely due to human activities such as burning fossil fuels, deforestation, and industrial processes. The consequences of climate change are profound and far-reaching, affecting not only the environment but also economies, public health, and social stability. As temperatures rise, polar ice melts, leading to rising sea levels that threaten coastal communities and ecosystems. Moreover, changing precipitation patterns can result in droughts in some regions and flooding in others, disrupting food production and water supply. The biodiversity loss is another critical aspect, as many species struggle to adapt to the rapid changes in their habitats. Addressing climate change requires a multi-faceted approach that includes transitioning to renewable energy sources,
                            Creating a single paragraph with 300 lines would be impractical for readability, but I can provide a comprehensive paragraph along with some thought-provoking questions. Here’s a detailed paragraph about climate change:
                            Climate change is one of the most pressing
                            challenges facing humanity today, characterized by rising global temperatures, shifting weather patterns, and increasing frequency of extreme weather events. It is driven primarily by the accumulation of greenhouse gases in the atmosphere, largely due to human activities such as burning fossil fuels, deforestation, and industrial processes. The consequences of climate change are profound and far-reaching, affecting not only the environment but also economies, public health, and social stability. As temperatures rise, polar ice melts, leading to rising sea levels that threaten coastal communities and ecosystems. Moreover, changing precipitation patterns can result in droughts in some regions and flooding in others, disrupting food production and water supply. The biodiversity loss is another critical aspect, as many species struggle to adapt to the rapid changes in their habitats. Addressing climate change requires a multi-faceted approach that includes transitioning to renewable energy sources,
                            improving energy efficiency, protecting forests, and fostering sustainable agricultural practices. International cooperation is essential, as climate change knows no borders; it affects every nation on the planet. The Paris Agreement exemplifies global efforts to limit temperature rise and mitigate climate impacts, emphasizing the importance of collective action. However, achieving meaningful progress necessitates the engagement of individuals, communities, businesses, and governments alike. Public awareness and education about climate issues are vital for driving change and inspiring action. As we face this unprecedented challenge, it is crucial to remain hopeful and proactive, understanding that solutions are within our reach if we prioritize sustainability and resilience in our societies.</span>
                    </div>

                </div>
                <div className='side_right_con'>
                    <div className='indide_side_barrr'>
                        <div className='top_sdie_content'>
                            In the crucial to remain hopeful and proactive,
                            understanding that solutions are within our reach if we prioritize
                            sustainability and resilience in our societies ?</div>

                        <div className='for_all_Questions'>
                            <div className='for_select_question'>
                                <input type='radio'></input>
                                <p className='fofr_queistoon'>What are some of the most significant impacts of climate change that you have observed in your community?</p>
                            </div>
                            <div className='for_select_question'>
                                <input type='radio'></input>
                                <p className='fofr_queistoon'>What are some of the most significant impacts of climate change that you have observed in your community?</p>
                            </div>
                            <div className='for_select_question'>
                                <input type='radio'></input>
                                <p className='fofr_queistoon'>What are some of the most significant impacts of climate change that you have observed in your community?</p>
                            </div>
                            <div className='for_select_question'>
                                <input type='radio'></input>
                                <p className='fofr_queistoon'>What are some of the most significant impacts of climate change that you have observed in your community?</p>
                            </div>
                            <div className='for_select_question'>
                                <input type='radio'></input>
                                <p className='fofr_queistoon'>What are some of the most significant impacts of climate change that you have observed in your community?</p>
                            </div>
                        </div>

                        <div className='down_arrowwwwww'>
                            <span>Paragraph  2 is marked with an</span>
                            <span> <ImArrowRight /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuestionAndAnswerr
