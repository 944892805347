import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { Typography, Radio, Col, Button, Space, Table, Modal } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import { FaHeart } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import moment from "moment/moment";
import Header from "../../student_registation/components/Header";
import {
  deleteWishlist,
  setallUniversityIds,
} from "../../../redux/Test/TestSlice";
import ProgramDetailsModel from "../../program/ProgramDetailsModel";
const ApplayDirect = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const { programSearchObjects, wishListTotal, wishListTotalUniIds } =
    useSelector((store) => store.testManagement);

  const [temploading, setTempLoading] = useState(true);
  const [fetchedProgram, setfetchedProgram] = useState([]);

  const [selectedProgram, setselectedProgram] = useState("");

  const [currentShoingData, setcurrentShoingData] = useState([]);
  const [noOfShowdata, setnoOfShowdata] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPage, setnoOfPage] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishListTotal.length > 0) {
      const uniTotal = wishListTotal.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setfetchedProgram(uniTotal);

      setcurrentShoingData(uniTotal);

      const lengthp = Math.ceil(uniTotal.length / noOfShowdata);
      let pagearray = [];
      for (let index = 0; index < lengthp; index++) {
        pagearray = [...pagearray, index + 1];
      }
      setnoOfPage([...pagearray]);
    }
  }, [wishListTotal]);

  useEffect(() => {
    const indexOfLastProduct = currentPage * noOfShowdata;
    const indexOfFirstProduct = indexOfLastProduct - noOfShowdata;
    const currentProducts = fetchedProgram.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    );
    setcurrentShoingData(currentProducts);
  }, [fetchedProgram, currentPage]);

  const deleteWishListPress = (university) => {
    const findwishId = wishListTotal.find(
      (data) => data.UNITID === university.UNITID
    );
    dispatch(deleteWishlist(findwishId._id));
    dispatch(setallUniversityIds());
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "No",
      render: (text) => <span>{text}</span>,
      width: 70,
    },
    {
      title: "Institution",
      dataIndex: "unversityName",
      key: "Institution",
      render: (text) => <Link>{text}</Link>,
      //   width: 300,
    },
    {
      title: "Program",
      dataIndex: "programName",
      key: "Program",
      //   width: 300,

      render: (text, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setselectedProgram(record);
            setOpen(true);
          }}
        >
          {record.programName}
        </span>
      ),
    },
    {
      title: "Save",
      key: "UNITID",
      dataIndex: "Save",
      width: 100,
      render: (text, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => deleteWishListPress(record)}
          >
            <FaHeart color="red" />
          </span>
        </>
      ),
    },
    {
      title: "Request Info",
      key: "action",
      width: 130,
      render: (_, record) => (
        <Space size="middle">
          <span>
            <IoMdMail />
          </span>
        </Space>
      ),
    },
  ];

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 1000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Recommended Programs"
            description="Recommended Programs"
          />
          <div className="inputs_container">
            <Table
              columns={columns}
              dataSource={currentShoingData}
              virtual
              scroll={{
                y: 400,
              }}
              //   onRow={(record, rowIndex) => {
              //     return {
              //       onClick: (event) => {
              //         console.log(event, "event");
              //       },
              //     };
              //   }}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        //   title="Title"
        onOk={handleOk}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Applay Now
          </Button>,
        ]}
      >
        <ProgramDetailsModel selectedProgram={selectedProgram} />
      </Modal>
    </>
  );
};

export default ApplayDirect;
