import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Typography, Radio, Col, Button, Space } from "antd";

const OvercomingChallenges = ({ data_use }) => {
  const { loginData } = useSelector((store) => store.Athentication);
  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
.main_letter_box {
  width: 100%;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.main_letter_box span {
  color: #333;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: justify;
}

.margin_tops{
  margin-top: 1.5vh;
}
  .tex_capita {
  text-transform: capitalize;
}
  .text_lowercase {
  text-transform: lowercase;
}
         </style>
        </head>
        <body>
                <div class="main_letter_box">
                  <span>${moment(data_use.newdate).format("DD/MM/YYYY")}</span>

                  <span class="margin_tops">${data_use.facultyName}</span>
                  <span>${data_use.facultyTitle}</span>
                  <span>${data_use.facultyDepartment}</span>
                  <span>${data_use.facultyInstitution}</span>
                  <span>
                    ${data_use.facultyCity},${data_use.facultyState},
                    ${data_use.facultyZip}
                  </span>
                  <span>${data_use.facultyEmailcnf}</span>
                  <span>${data_use.mobile}</span>
                  <span>Date</span>

                  <span class="margin_tops">${data_use.universityName}</span>
                  <span>${data_use.universityAddress}</span>
                  <span>
                    ${data_use.universityCity},${data_use.universityState},
                    ${data_use.universityZip}
                  </span>
                  <span class="margin_tops">
                    Dear Members of the Admissions Committee,
                  </span>
         <span class="margin_tops">
          I am honored to recommend <span> ${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> for admission to
          <span> ${data_use.universityName}</span>. I have had the privilege of
          teaching <span> ${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> in
          <span> ${data_use.selected_program}</span> and have witnessed
          <span> ${data_use.gender2} </span>
          remarkable resilience and determination in overcoming significant
          challenges.
        </span>
        <span class="margin_tops">
          <span>${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> faced
          <span> ${data_use.specificChallanges} </span> but did not let this
          obstacle deter
          <span> ${data_use.gender2} </span> academic pursuits. Instead,
          <span class="text_lowercase"> ${data_use.gender3} </span>
          demonstrated exceptional perseverance and a positive attitude,
          resulting in impressive academic achievements.
          <span class="tex_capita"> ${data_use.gender2}</span> ability to
          maintain a high level of performance despite these challenges is a
          testament to <span>${data_use.gender2} </span>
          strong character and determination.
        </span>

        <span class="margin_tops">
          <span class="tex_capita">${data_use.gender2} </span> experience has
          not only shaped <span>${data_use.gender} </span> into a more resilient
          individual but also provided <span>${data_use.gender} </span> with a
          unique perspective that enriches classroom discussions and group
          projects. <span>${loginData.firstname}</span>
          <span> ${
            loginData.lastname
          }</span> is an inspiring individual who will
          undoubtedly bring a wealth of experience and insight to your program.
        </span>

        <span class="margin_tops">
          I strongly believe that <span>${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> will excel at
          <span> ${data_use.universityName}</span> and make the most of the
          opportunities available. <span> ${data_use.gender3} </span> has my
          highest recommendation.
        </span>
      
                  <span class="margin_tops">Sincerely,</span>
                  <span class="margin_tops">${data_use.facultyName}</span>
                </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };
  return (
    <>
      <div className="main_letter_box">
        <span>{moment(data_use.newdate).format("DD/MM/YYYY")}</span>

        <span className="margin_tops">{data_use.facultyName}</span>
        <span>{data_use.facultyTitle}</span>
        <span>{data_use.facultyDepartment}</span>
        <span>{data_use.facultyInstitution}</span>
        <span>
          {data_use.facultyCity},{data_use.facultyState},{data_use.facultyZip}
        </span>
        <span>{data_use.facultyEmailcnf}</span>
        <span>{data_use.mobile}</span>

        <span className="margin_tops">{data_use.Committee}</span>
        <span>{data_use.universityName}</span>
        <span>{data_use.universityAddress}</span>
        <span>
          {data_use.universityCity},{data_use.universityState},
          {data_use.universityZip}
        </span>
        <span className="margin_tops">
          Dear Members of the Admissions Committee,
        </span>
        <span className="margin_tops">
          I am honored to recommend <span> {loginData.firstname}</span>
          <span> {loginData.lastname}</span> for admission to
          <span> {data_use.universityName}</span>. I have had the privilege of
          teaching <span> {loginData.firstname}</span>
          <span> {loginData.lastname}</span> in
          <span> {data_use.selected_program}</span> and have witnessed
          <span> {data_use.gender2} </span>
          remarkable resilience and determination in overcoming significant
          challenges.
        </span>
        <span className="margin_tops">
          <span>{loginData.firstname}</span>
          <span> {loginData.lastname}</span> faced
          <span> {data_use.specificChallanges} </span> but did not let this
          obstacle deter
          <span> {data_use.gender2} </span> academic pursuits. Instead,
          <span className="text_lowercase"> {data_use.gender3} </span>
          demonstrated exceptional perseverance and a positive attitude,
          resulting in impressive academic achievements.
          <span className="tex_capita"> {data_use.gender2}</span> ability to
          maintain a high level of performance despite these challenges is a
          testament to <span>{data_use.gender2} </span>
          strong character and determination.
        </span>

        <span className="margin_tops">
          <span className="tex_capita">{data_use.gender2} </span> experience has
          not only shaped <span>{data_use.gender} </span> into a more resilient
          individual but also provided <span>{data_use.gender} </span> with a
          unique perspective that enriches classroom discussions and group
          projects. <span>{loginData.firstname}</span>
          <span> {loginData.lastname}</span> is an inspiring individual who will
          undoubtedly bring a wealth of experience and insight to your program.
        </span>

        <span className="margin_tops">
          I strongly believe that <span>{loginData.firstname}</span>
          <span> {loginData.lastname}</span> will excel at
          <span> {data_use.universityName}</span> and make the most of the
          opportunities available. <span> {data_use.gender3} </span> has my
          highest recommendation.
        </span>
        <span className="margin_tops">Sincerely,</span>
        <span className="margin_tops">{data_use.facultyName}</span>
        <div className="button_container">
          <Button
            type="primary"
            size="large"
            onClick={() => handlePrint(false)}
          >
            Print
          </Button>
        </div>
      </div>
    </>
  );
};

export default OvercomingChallenges;
