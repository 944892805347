import React, { useState, useEffect } from "react";
import { MdStars } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaRegFlag } from "react-icons/fa";
import { SlBadge } from "react-icons/sl";
import { CiCircleInfo } from "react-icons/ci";
import { BsLightningCharge } from "react-icons/bs";
import { FaAngleRight } from "react-icons/fa";
import { Typography, Radio, Col, Button, Space, Modal } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import ProgramDetailsModel from "../program/ProgramDetailsModel";

const NewSingleCardUniversity = ({ uiversity, index }) => {
  const { isAuth, loginData } = useSelector((state) => state.Athentication);
  const [info_model, setinfo_model] = useState(false);
  const [selectedUniversity, setselectedUniversity] = useState("");

  const [temploading, setTempLoading] = useState(true);
  const [fetchedPrograms, setfetchedPrograms] = useState([]);

  const [selectedProgram, setselectedProgram] = useState("");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedUniversity !== "") {
      let eduLeve = "";
      if (loginData.degreeName === "Undergraduate") {
        eduLeve = "Bachelor's Degree";
      } else if (loginData.degreeName === "Graduate") {
        eduLeve = "Master's Degree";
      } else if (loginData.degreeName === "Doctoral") {
        eduLeve = "Doctoral Degree";
      }

      const fetchPrograms = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/program/programbyuniv/${eduLeve}/${selectedUniversity.UNITID}`;
        const fetchProgra = await axios.get(url);
        if (fetchProgra.data.success === true) {
          const progTotal = fetchProgra.data.programs;
          setTempLoading(false);
          setfetchedPrograms(progTotal);
        }
      };
      fetchPrograms();
    }
  }, [selectedUniversity]);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 1000);
  };
  
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>

      <Modal
        // title={`${selectedUniversity.universityName}`}
        title={
          <span
            style={{
              color: "#D2093C",
            }}
          >
            {selectedUniversity.universityName}
          </span>
        }
        style={{ top: 20 }}
        open={info_model}
        // onOk={() => setinfo_model(false)}
        onCancel={() => setinfo_model(false)}
        footer={null}
        maskClosable={false}
      >
        <div className="program_container flex-between">
          {fetchedPrograms.map((program, index) => (
            <>
              <div
                key={index}
                className="program_box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setselectedProgram(program);
                  setOpen(true);
                }}
              >
                <p>{program.programName}</p>
                <span>View Full Details</span>
              </div>
            </>
          ))}
        </div>
      </Modal>

      <Modal
        open={open}
        //   title="Title"
        onOk={handleOk}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
        width={800}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          // <Button
          //   key="submit"
          //   type="primary"
          //   loading={loading}
          //   onClick={handleOk}
          // >
          //   Applay Now
          // </Button>,
        ]}
      >
        <ProgramDetailsModel selectedProgram={selectedProgram} />
      </Modal>

      <div className="container-fluid p-2">
        <div
          style={{
            height: "280px",
            width: "100%",
            backgroundColor: "#FFFFFF",
            boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          className="card border-0 p-2  "
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="rounded-2 p-1 border"
                  style={{ height: "50px", width: "50px" }}
                >
                  <img
                    src="\assets\img\SingleCardImg\logo_29.jpg"
                    alt=""
                    className="img-fluid w-100 h-100"
                  />
                </div>
                <div
                  className="px-1 mb-1"
                  style={{ height: "60px", width: "75%" }}
                >
                  <div
                    style={{
                      fontSize: "13.5px",
                      fontWeight: "700",
                      color: "#0487F3",
                      position: "relative",
                      top: "6px",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      lineHeight: "1.4em",
                      //  maxHeight: "2.8em",
                    }}
                  >
                    {uiversity.universityName}{" "}
                  </div>
                  <div
                    style={{
                      fontSize: "10.6px",
                      position: "relative",
                      bottom: "px",
                      top: "0.2px",
                    }}
                    className="text-secondary"
                  >
                    {uiversity.city}{" "}
                  </div>
                </div>
              </div>
              <div>
                <span>
                  <MdStars style={{ color: "#F8C57A", fontSize: "30px" }} />
                </span>
              </div>
            </div>
            <div style={{ height: "130px" }}>
              <div
                style={{ width: "100%" }}
                className="mt-3 d-flex justify-content-between align-items-ceter"
              >
                <div>
                  <FaBuilding className="mt-1" style={{ fontSize: "25px" }} />
                </div>
                <div
                  style={{ width: "60%" }}
                  className="d-flex align-items-center"
                >
                  <div className="px-2">
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "10.5px",
                        lineHeight: "12px",
                      }}
                      className=" text-dark"
                    >
                      {uiversity.universityType}
                    </div>
                    <div
                      style={{
                        fontSize: "10.6px",
                        position: "relative",
                        bottom: "6px",
                      }}
                      className="text-secondary"
                    >
                      type{" "}
                    </div>
                  </div>
                </div>

                <span
                  style={{ fontSize: "12px" }}
                  className="text-primary text-end w-50"
                >
                  {/* <FaExternalLinkAlt /> Know More */}
                </span>
              </div>

              <div
                style={{ width: "100%" }}
                className=" d-flex justify-content-between align-items-ceter"
              >
                <div>
                  <FaRegFlag className="mt-1" style={{ fontSize: "25px" }} />
                </div>
                <div
                  style={{ width: "60%" }}
                  className="d-flex align-items-center"
                >
                  <div className="px-2">
                    <div
                      style={{ fontWeight: "500", fontSize: "13px" }}
                      className=" text-dark"
                    >
                      1899
                      {uiversity.establishedYear}{" "}
                    </div>
                    <div
                      style={{
                        fontSize: "10.6px",
                        position: "relative",
                        bottom: "6px",
                      }}
                      className="text-secondary"
                    >
                      Established Since
                    </div>
                  </div>
                </div>

                <span
                  style={{ fontSize: "13px" }}
                  className="text-dark text-end w-50"
                >
                  Apply with
                </span>
              </div>

              <div
                style={{ width: "100%" }}
                className=" d-flex justify-content-between align-items-ceter"
              >
                <div>
                  <SlBadge className="mt-1" style={{ fontSize: "25px" }} />
                </div>
                <div
                  style={{ width: "55%" }}
                  className="d-flex align-items-center "
                >
                  <div className="px-2">
                    <div
                      style={{ fontWeight: "500", fontSize: "13px" }}
                      className=" text-dark"
                    >
                      {index + 1}{" "}
                    </div>
                    <div
                      style={{
                        fontSize: "10.6px",
                        position: "relative",
                        bottom: "6px",
                      }}
                      className="text-secondary"
                    >
                      QS Ranking
                    </div>
                  </div>
                </div>

                <span
                  style={{ fontSize: "13px" }}
                  className="text-dark text-end w-50"
                >
                  <BsLightningCharge style={{ color: "#FFD256" }} /> Fast-track{" "}
                  {/* <CiCircleInfo fontSize={"14px"} /> */}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2 p-1">
            <div
              style={{ border: "1px outset #000", height: "32px" }}
              className="w-100 d-flex p-1 text-dark rounded-5 justify-content-between"
            >
              <p
                className="px-1"
                style={{
                  fontSize: "11px",
                  color: "#000",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setselectedUniversity(uiversity);
                  setinfo_model(true);
                }}
              >
                RECOMMENDED COURSES
              </p>
              <span>
                <FaAngleRight />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSingleCardUniversity;
