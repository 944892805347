import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { Typography, Radio, Col, Button, Space, Modal, message } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import { HiChevronDoubleLeft } from "react-icons/hi2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import satates from "../json_datas/countries+states.json";
import Header from "./Header";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  removeallStudentFormDara,
  setFinancialSupport,
  setOtherObjectives,
} from "../../../redux/dashboard/PageRenderSlice";
import { authActions } from "../../../redux/authentication/AuthenticationSlice";

const OtherObjectives = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginData } = useSelector((store) => store.Athentication);
  const { currentFormStep, studentDetail } = useSelector(
    (store) => store.StudentRegd
  );
  const { countryList, personalInfo } = useSelector(
    (store) => store.DashboardPage
  );
  const { totalUniversity, major_subjects } = useSelector(
    (store) => store.testManagement
  );
  const [internshipInStudy, setInternshipInStudy] = useState(false);
  const [workExp, setWorkExp] = useState("");
  const [limitWorkExp, setLimitWorkExp] = useState(false);
  const [program, setProgram] = useState("");
  const [state, setState] = useState("");
  const [university, setUniversity] = useState("");
  const [city, setCity] = useState("");
  const [bringDependent, setbringDependent] = useState(false);

  const [employe, setemploye] = useState(false);
  const [experienceLess2, setexperienceLess2] = useState(false);
  const [maritalsts, setMaritalsts] = useState(false);
  const [open, setOpen] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (studentDetail.currentFormStep >= 10) {
      setInternshipInStudy(studentDetail.otherObjectives.internshipInStudy);
      setWorkExp(studentDetail.otherObjectives.workExp);
      setProgram(studentDetail.otherObjectives.program);
      setState(studentDetail.otherObjectives.state);
      setUniversity(studentDetail.otherObjectives.university);
      setCity(studentDetail.otherObjectives.city);
      setbringDependent(studentDetail.otherObjectives.bringDependent);
    }
  }, [studentDetail]);

  const handleClose = () => {
    setOpen(true);
  };

  const OtherObjectivesClick = async (e) => {
    e.preventDefault();
    const formData = {
      _id: loginData._id,
      otherObjectives: {
        internshipInStudy: internshipInStudy,
        workExp: workExp,
        program: program,
        state: state,
        university: university,
        city: city,
        bringDependent: bringDependent,
      },
      currentFormStep: 11,
      otherObjectivesStatus: true,
    };

    const student = await dispatch(updateStudent(formData));
    if (student.payload.success) {
      setOpen(true);
      messageApi.open({
        type: "success",
        content: `Student Creation successfully done`,
      });
      const intervalId = setInterval(() => {
        setOpen(false);
      }, 5000);

      dispatch(setOtherObjectives(formData));
      dispatch(
        authActions.signin({
          ...student.payload.student,
          isAuth: true,
        })
      );
      navigate("/dashbord/home");
      dispatch(removeallStudentFormDara());
      return () => clearInterval(intervalId);
      //   if (studentDetail.testScoreStatus) {
      //     navigate("/studentdashboard");
      //   } else {
      //     dispatch(dashPageStatuseChange(12));
      //   }
    }
  };

  const notificationPress = (massage) => {
    Modal.warning({
      title: "This is a warning message",
      content: `${massage}`,
      onOk() {},
      okText: "Got It",
    });
  };

  return (
    <>
      {contextHolder}
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Other Objectives"
            description="Please select additional options to enhance your profile matching and optimize outcomes that align with your expectations."
          />
          <div className="inputs_container">
            <div className="radio_div_container">
              <span className="radio_title">
                Do you wish to participate in any paid/unpaid
                internship/employment during your study?
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={employe === true}
                  onClick={() => {
                    setemploye(true);
                    notificationPress(
                      "Before accepting the answer - Show a detailed message if the student is below 35 years old and unmarried - on how it would impact his or her future"
                    );
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={employe === false}
                  onClick={() => {
                    setemploye(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            {employe === true && (
              <div className="radio_div_container">
                <span className="radio_title">
                  Show a detailed message that it is not advisable to select
                  this option as his/her work experience is limited. Do you
                  still want to go ahead?
                </span>
                <Radio.Group defaultValue={false}>
                  <Radio
                    value={true}
                    checked={experienceLess2 === true}
                    onClick={() => {
                      setexperienceLess2(true);
                      notificationPress(
                        "Show a detailed message that it is advisable the student gets some advanced hot skill training related to his/her experience before leaving to the USA "
                      );
                    }}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={experienceLess2 === false}
                    onClick={() => {
                      setexperienceLess2(false);
                    }}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </div>
            )}
            <div className="radio_div_container">
              <span className="radio_title">
                Do you have the funds available? (self & family)
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={internshipInStudy === true}
                  onClick={() => {
                    setInternshipInStudy(true);
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={internshipInStudy === false}
                  onClick={() => {
                    setInternshipInStudy(false);
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>

            {internshipInStudy === true && (
              <>
                <div className="radio_div_container">
                  <span className="radio_title">
                    Do you have a specific option to include in your matching
                  </span>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Program"
                    select
                    className="input_style"
                    // error={programSessionError !== "" ? true : false}
                    // helperText={programSessionError}
                    // value={programSession}
                    // onChange={(e) => setprogramSession(e.target.value)}
                    size="small"
                  >
                    {major_subjects.map((program, index) => (
                      <MenuItem key={index} value={program.name}>
                        {program.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="State"
                    select
                    className="input_style"
                    // error={programSessionError !== "" ? true : false}
                    // helperText={programSessionError}
                    // value={programSession}
                    // onChange={(e) => setprogramSession(e.target.value)}
                    size="small"
                  >
                    {satates
                      .find((data) => data.name === "United States")
                      .states.map((state, index) => (
                        <MenuItem key={index} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="City"
                    select
                    className="input_style"
                    // error={programSessionError !== "" ? true : false}
                    // helperText={programSessionError}
                    // value={programSession}
                    // onChange={(e) => setprogramSession(e.target.value)}
                    size="small"
                  >
                    {countryList.map((city, index) => (
                      <MenuItem key={index} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="University"
                    select
                    className="input_style"
                    // error={programSessionError !== "" ? true : false}
                    // helperText={programSessionError}
                    // value={programSession}
                    // onChange={(e) => setprogramSession(e.target.value)}
                    size="small"
                  >
                    {totalUniversity.map((university, index) => (
                      <MenuItem key={index} value={university.universityName}>
                        {university.universityName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            )}

            {studentDetail.personalInformation.maritalsts === false && (
              <div className="radio_div_container">
                <span className="radio_title">
                  Do you wish to bring your dependents to the USA during your
                  study?
                </span>
                <Radio.Group defaultValue={false}>
                  <Radio
                    value={true}
                    checked={maritalsts === true}
                    onClick={() => {
                      setMaritalsts(true);
                    }}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={maritalsts === false}
                    onClick={() => {
                      setMaritalsts(false);
                    }}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </div>
            )}
            <div className="button_container_distance">
              <Button
                type="primary"
                iconPosition="start"
                icon={<HiChevronDoubleLeft />}
                size="large"
                onClick={() => dispatch(dashPageStatuseChange(8))}
                 className="back_btn_btn"
              >
                Back
              </Button>
              <Button
                type="primary"
                // loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={(e) => {
                  OtherObjectivesClick(e);
                }}
              >
                Save and Submit
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default OtherObjectives;
