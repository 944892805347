import React from "react";
import { useDispatch, useSelector } from "react-redux";
import imagelog from "../../student_registation/components/1.png";

const Dashbord = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const dispatch = useDispatch();
  return (
    <>
      <div className="reg_welcome_containere">
        <div className="reg_welcome_box">
          <div className="reg_welcome_box_image">
            <img className="" src={imagelog} alt />
          </div>
          <div className="reg_welcome_box_dis">
            <h2 className="h6_cta-title"> Hi {loginData.firstname},</h2>
            <h5 className="wel_p">
              Warm welcome on your first step toward becoming a student in the
              USA. We appreciate your selecting f1visa.online to assist you on
              this exciting journey.
            </h5>
            <h5 className="wel_p">
              We are commited to making it easy and affordable for many of you.
              Hence our no-frills services are simple and straight.No deceptive
              commmitments! No disappointments!!
            </h5>
            <h5 className="wel_p">
              Please read{" "}
              <a href="" target="blank">
                ThisPage
              </a>{" "}
              now to discover how to make best use of our services.
            </h5>
            <h2 className="h6_cta-title"> F1 VISA TEAM</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashbord;
