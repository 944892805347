import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Typography, Radio, Col, Button, Space } from "antd";

const PassionandEnthusiasm = ({ data_use }) => {
  const { loginData } = useSelector((store) => store.Athentication);
  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
.main_letter_box {
  width: 100%;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.main_letter_box span {
  color: #333;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: justify;
}

.margin_tops{
  margin-top: 1.5vh;
}
  .tex_capita {
  text-transform: capitalize;
}
  .text_lowercase {
  text-transform: lowercase;
}
         </style>
        </head>
        <body>
                <div class="main_letter_box">
                  <span>${moment(data_use.newdate).format("DD/MM/YYYY")}</span>

                  <span class="margin_tops">${data_use.facultyName}</span>
                  <span>${data_use.facultyTitle}</span>
                  <span>${data_use.facultyDepartment}</span>
                  <span>${data_use.facultyInstitution}</span>
                  <span>
                    ${data_use.facultyCity},${data_use.facultyState},
                    ${data_use.facultyZip}
                  </span>
                  <span>${data_use.facultyEmailcnf}</span>
                  <span>${data_use.mobile}</span>
                  <span>Date</span>

                  <span class="margin_tops">${data_use.universityName}</span>
                  <span>${data_use.universityAddress}</span>
                  <span>
                    ${data_use.universityCity},${data_use.universityState},
                    ${data_use.universityZip}
                  </span>
                  <span class="margin_tops">
                    Dear Members of the Admissions Committee,
                  </span>
          <span class="margin_tops">
          It is with great pleasure that I write this letter of recommendation
          for <span> ${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> for admission to
          <span> ${data_use.universityName}</span>. I have had the privilege of
          teaching <span> ${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> in
          <span> ${data_use.selected_program}</span> and have been continually
          impressed by <span> ${data_use.gender2} </span> passion and enthusiasm
          for the field of <span> ${data_use.specificField} </span>.
        </span>

        <span class="margin_tops">
          <span> ${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> approaches
          <span> ${
            data_use.gender2
          } </span> studies with a genuine curiosity and
          a desire to delve deeply into the subject matter.
          <span class="tex_capita"> ${data_use.gender2} </span> enthusiasm is
          contagious, often sparking lively discussions and inspiring
          <span> ${data_use.gender2} </span> peers to engage more fully with the
          material.
        </span>

        <span class="margin_tops">
          <span class="tex_capita">${data_use.gender2} </span> work on
          <span> ${data_use.specificProject} </span> demonstrated not only
          <span> ${data_use.gender2} </span> deep interest in the subject but
          also <span> ${data_use.gender2} </span> ability to conduct thorough
          research and present <span> ${data_use.gender2} </span> findings
          compellingly.
          <span class="tex_capita"> ${data_use.gender2} </span> passion for
          <span> ${data_use.specificField} </span> is evident in all
          <span> ${data_use.gender2} </span>
          academic endeavors and is complemented by
          <span> ${data_use.gender2} </span> strong analytical and critical
          thinking skills.
        </span>

        <span class="margin_tops">
          I am confident that <span> ${loginData.firstname}</span>
          <span> ${loginData.lastname}</span> will thrive in an academic
          environment that nurtures intellectual curiosity and innovation.
          <span> ${data_use.gender3} </span> will be an enthusiastic and
          dedicated member of <span>${data_use.universityName}</span> and will
          contribute significantly to your academic community.
        </span>
      
                  <span class="margin_tops">Sincerely,</span>
                  <span class="margin_tops">${data_use.facultyName}</span>
                </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };
  return (
    <>
      <div className="main_letter_box">
        <span>{moment(data_use.newdate).format("DD/MM/YYYY")}</span>

        <span className="margin_tops">{data_use.facultyName}</span>
        <span>{data_use.facultyTitle}</span>
        <span>{data_use.facultyDepartment}</span>
        <span>{data_use.facultyInstitution}</span>
        <span>
          {data_use.facultyCity},{data_use.facultyState},{data_use.facultyZip}
        </span>
        <span>{data_use.facultyEmailcnf}</span>
        <span>{data_use.mobile}</span>

        <span className="margin_tops">{data_use.Committee}</span>
        <span>{data_use.universityName}</span>
        <span>{data_use.universityAddress}</span>
        <span>
          {data_use.universityCity},{data_use.universityState},
          {data_use.universityZip}
        </span>
        <span className="margin_tops">
          Dear Members of the Admissions Committee,
        </span>

        <span className="margin_tops">
          It is with great pleasure that I write this letter of recommendation
          for <span> {loginData.firstname}</span>
          <span> {loginData.lastname}</span> for admission to
          <span> {data_use.universityName}</span>. I have had the privilege of
          teaching <span> {loginData.firstname}</span>
          <span> {loginData.lastname}</span> in
          <span> {data_use.selected_program}</span> and have been continually
          impressed by <span> {data_use.gender2} </span> passion and enthusiasm
          for the field of <span> {data_use.specificField} </span>.
        </span>

        <span className="margin_tops">
          <span> {loginData.firstname}</span>
          <span> {loginData.lastname}</span> approaches
          <span> {data_use.gender2} </span> studies with a genuine curiosity and
          a desire to delve deeply into the subject matter.
          <span className="tex_capita"> {data_use.gender2} </span> enthusiasm is
          contagious, often sparking lively discussions and inspiring
          <span> {data_use.gender2} </span> peers to engage more fully with the
          material.
        </span>

        <span className="margin_tops">
          <span className="tex_capita">{data_use.gender2} </span> work on
          <span> {data_use.specificProject} </span> demonstrated not only
          <span> {data_use.gender2} </span> deep interest in the subject but
          also <span> {data_use.gender2} </span> ability to conduct thorough
          research and present <span> {data_use.gender2} </span> findings
          compellingly.
          <span className="tex_capita"> {data_use.gender2} </span> passion for
          <span> {data_use.specificField} </span> is evident in all
          <span> {data_use.gender2} </span>
          academic endeavors and is complemented by
          <span> {data_use.gender2} </span> strong analytical and critical
          thinking skills.
        </span>

        <span className="margin_tops">
          I am confident that <span> {loginData.firstname}</span>
          <span> {loginData.lastname}</span> will thrive in an academic
          environment that nurtures intellectual curiosity and innovation.
          <span> {data_use.gender3} </span> will be an enthusiastic and
          dedicated member of <span>{data_use.universityName}</span> and will
          contribute significantly to your academic community.
        </span>
        <span className="margin_tops">Sincerely,</span>
        <span className="margin_tops">{data_use.facultyName}</span>
        <div className="button_container">
          <Button
            type="primary"
            size="large"
            onClick={() => handlePrint(false)}
          >
            Print
          </Button>
        </div>
      </div>
    </>
  );
};

export default PassionandEnthusiasm;
