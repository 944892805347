import React from "react";
import { Link } from "react-router-dom";

const Universities = () => {
  return (
    <>
      <section className="category-area pt-40 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-40">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#F14D5D",
                  }}
                >
                  Top Universities
                </span>
                <h2 className="section-title mb-0"> Top Universities</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-12 col-lg-12"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img
                      src="/assets/img/category/1/HarvardUniversity.jpg"
                      alt=""
                    />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni1.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni2.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img
                      src="/assets/img/category/1/HarvardUniversity.jpg"
                      alt=""
                    />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni2.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni1.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img
                      src="/assets/img/category/1/HarvardUniversity.jpg"
                      alt=""
                    />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni2.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-40">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-40">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#F14D5D",
                  }}
                >
                  Popular Universities
                </span>
                <h2 className="section-title mb-0"> Popular Universities</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-12 col-lg-12"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img
                      src="/assets/img/category/1/HarvardUniversity.jpg"
                      alt=""
                    />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni1.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni2.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img
                      src="/assets/img/category/1/HarvardUniversity.jpg"
                      alt=""
                    />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni2.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni1.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img
                      src="/assets/img/category/1/HarvardUniversity.jpg"
                      alt=""
                    />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="category-wrap col-xl-3 col-lg-3 p-1">
                <div className="category-item">
                  <div className="category-img">
                    <img src="/assets/img/category/1/uni2.jpg" alt="" />
                  </div>
                  <div className="category-content">
                    <h5>
                      <Link>Harvard University</Link>
                    </h5>
                    <p>
                      <Link>
                        26 Programs
                        <i className="fa-light fa-arrow-right" />
                        <i className="fa-light fa-arrow-right" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Universities;
