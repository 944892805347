import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const initialState={
    dashboardPageStatus:1
}

const StudentDashboardSlice=createSlice({
    name:"StudentDashboardPage",
    initialState,
    reducers:{
        studentDashboardPageChange(state,action){
            state.dashboardPageStatus=action.payload
        }
    }
})

export const {studentDashboardPageChange}=StudentDashboardSlice.actions;
export default StudentDashboardSlice.reducer;