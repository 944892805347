import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

import {
  Typography,
  Radio,
  Col,
  Button,
  Space,
  Table,
  Modal,
  Input,
} from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import moment from "moment/moment";
import Header from "../../student_registation/components/Header";
import axios from "axios";
import { MessageBox, ChatItem, MessageList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";

const ServiesRequiest = () => {
  const { loginData } = useSelector((store) => store.Athentication);

  const [buttonPress, setbuttonPress] = useState(false);

  const [massagebuttonPress, setmassagebuttonPress] = useState(false);

  const [serviceError, setserviceError] = useState("");
  const [services, setservices] = useState("");
  const [massageTexterror, setmassageTexterror] = useState("");
  const [massageText, setmassageText] = useState("");
  const [selectedservices, setselectedservices] = useState("");

  const [allservices, setallservices] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const modelclose = () => {
    setModal(false);
    setselectedservices("");
    setmassageText("");
    setmassageTexterror("");
  };

  const messageListReferance = React.createRef();

  useEffect(() => {
    if (buttonPress === true) {
      if (services === "") {
        setserviceError("Required");
      } else {
        setserviceError("");
      }
    }
  }, [buttonPress, services]);

  useEffect(() => {
    const getRequiest = async () => {
      const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/notification/notifications-by-stydentid/${loginData._id}`;
      const reqestRespo = await axios.get(reqUrl);
      if (reqestRespo.data.success) {
        const respoRequest = reqestRespo.data.notifications;
        setallservices(respoRequest);
      }
    };
    getRequiest();
    // const timeout = setTimeout(() => {
    //   getRequiest();
    // }, 1000);

    // return () => clearTimeout(timeout);
  }, []);

  const service = [
    { name: "Service Request" },
    { name: "Service Request" },
    { name: "Service Request" },
    { name: "Service Request" },
    { name: "Service Request" },
  ];
  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "index",
    //   key: "No",
    //   render: (text) => <span>{text}</span>,
    //   width: 70,
    // },
    {
      title: "Title",
      dataIndex: "massage",
      key: "Title",
      render: (text) => <Link>{text}</Link>,
      width: 450,
    },
    {
      title: "Status",
      dataIndex: "NotificationStatus",
      key: "Status",
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "programName",
      key: "Action",
      width: 150,

      render: (text, record) => (
        <span style={{ cursor: "pointer" }}>
          <Button
            type="primary"
            onClick={() => {
              toggle();
              setselectedservices(record);
            }}
          >
            Reply
          </Button>
        </span>
      ),
    },
  ];
  const postServicesClick = async () => {
    setbuttonPress(true);
    if (services === "") {
      setserviceError("Required");
    } else {
      setserviceError("");
    }
    if (services !== "") {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notification/new`;
      const formdata = {
        StudentId: loginData._id,
        StudentName: `${loginData.firstname} ${loginData.lastname} `,
        Title: services,
        massage: services,
        Role: "Student",
        massageChat: [{ text: services, Role: "Student", position: "left" }],
      };

      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const posrReq = await axios.post(url, formdata, config);
      if (posrReq.data.success === true) {
        const reqRespo = posrReq.data.notifications;
        setallservices([...allservices, reqRespo]);
        setbuttonPress(false);
        setserviceError("");
        setservices("");
      }
    }
  };

  const sendmassageClick = async (e) => {
    e.preventDefault();

    if (massageText === "") {
      setmassageTexterror("please enter Massage");
    } else {
      setmassageTexterror("");
    }
    if (massageText !== "") {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/notification/update-single-massage`;
      const formdata = {
        Id: selectedservices._id,
        massageChat: {
          text: massageText.trim(),
          Role: "Student",
          position: "left",
        },
      };
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const posrReq = await axios.put(url, formdata, config);
      if (posrReq.data.success === true) {
        const reqRespo = posrReq.data.notification;
        setselectedservices(reqRespo);
        let updaterequest = allservices;
        updaterequest = updaterequest.map((data) => {
          if (data._id === reqRespo._id) {
            data = reqRespo;
          }
          return data;
        });
        setallservices(updaterequest);
        setmassageText("");
      }
    }
  };

  return (
    <>
      <Modal
        open={modal}
        title=" "
        onOk={(e) => sendmassageClick(e)}
        onCancel={() => modelclose()}
        okText="Send"
        closable={false}
      >
        <div className="Model_container">
          <p>{selectedservices.massage}</p>

          <div className="dn_input_box mt-3">
            {selectedservices !== "" && (
              <MessageList
                referance={messageListReferance}
                className="message-list"
                lockable={true}
                toBottomHeight={"100%"}
                dataSource={selectedservices.massageChat}
              />
            )}
          </div>
          <div className="dn_input_box mt-3">
            <TextField
              label="Massage"
              className="input_style"
              error={massageTexterror !== "" ? true : false}
              helperText={massageTexterror}
              value={massageText}
              onChange={(e) => setmassageText(e.target.value)}
              autocomplete="off"
              id="outlined-multiline-static"
              multiline
              rows={3}
            />
          </div>
        </div>
      </Modal>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Service Request"
            description="To provide you with seamless service, we can process up to three service requests for our experienced educational consultants to personally address your questions. Please use these opportunities judiciously to achieve your objectives."
          />

          {/* You have used all your service requests. To access more opportunities, please renew now. */}
          <div className="inputs_container">
            {allservices.filter((data) => data.Role === "Student").length <
              3 && (
              <>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="Request"
                    select
                    className="input_style"
                    error={serviceError !== "" ? true : false}
                    helperText={serviceError}
                    value={services}
                    onChange={(e) => setservices(e.target.value)}
                    size="small"
                  >
                    {service.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>

                <div className="button_container">
                  <Button
                    type="primary"
                    // loading={loading}
                    iconPosition="end"
                    icon={<PiPaperPlaneRight />}
                    size="large"
                    onClick={() => postServicesClick()}
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="inputs_container">
            <Table
              columns={columns}
              dataSource={allservices}
              virtual
              scroll={{
                x: false,
              }}
              // style={{width:"100%"}}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiesRequiest;
