import React from "react";

const HowTodoIt = () => {
  return (
    <>
      <div className="process-container">
        <h2>How it works?</h2>
        <p>
          F1VISA.online is a user-friendly web platform designed to help
          international students in their quest for admission to US
          universities...
        </p>

        <div className="process-content">
          {/* Traditional Educational Path Section */}
          <div className="process traditional-path">
            <h3>
              Traditional "Educational Counseling" Path for International
              Students
            </h3>
            <div className="step">
              1. Student approaches an Educational Consultant...
            </div>
            <div className="step">
              2. After paying a huge sum as consultation fee...
            </div>
            <div className="step">
              3. Consultant provides limited program recommendations...
            </div>
            <div className="step">
              4. Student has to run from pillar to post in securing documents...
            </div>
            {/* Add more steps as needed */}
          </div>

          {/* F1VISA.online Path Section */}
          <div className="process f1visa-path">
            <h3>F1VISA.online Process</h3>
            <div className="step">
              1. Student creates profile and enters preferences...
            </div>
            <div className="step">
              2. Student completes all processes at own pace...
            </div>
            <div className="step">3. Post-admission support in the USA...</div>
            {/* Add more steps as needed */}
          </div>

          {/* Curved arrows using SVG or CSS paths */}
          <div className="arrow traditional-arrow"></div>
          <div className="arrow f1visa-arrow"></div>
        </div>
      </div>
    </>
  );
};

export default HowTodoIt;
