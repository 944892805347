import React from "react";
import { useDispatch, useSelector } from "react-redux";
import imagelog from "./1.png";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setPersonalInformation,
} from "../../../redux/dashboard/PageRenderSlice";

const WelCome = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const dispatch = useDispatch();

  const handleFormFillUp = async () => {
    const formData = {
      _id: loginData._id,
      currentFormStep: 1,
    };
    const student = await dispatch(updateStudent(formData));
    if (student.payload.success) {
      dispatch(setPersonalInformation(formData));
      dispatch(dashPageStatuseChange(1));
    }
  };
  return (
    <>
      <div className="reg_welcome_containere">
        <div className="reg_welcome_box">
          <div className="reg_welcome_box_image">
            <img className="" src={imagelog} alt />
          </div>
          <div className="reg_welcome_box_dis">
            <h2 className="h6_cta-title">Welcome to f1visa.online</h2>
            <h5 className="wel_p">
              Our goal is to make the process as easy as possible for you. Our
              tools can work better for you if the data you provide is correct.
              If you need any assistance in completing your profile, please see
              the 'How to' section in the FAQs.
            </h5>
            <div
              className="h6_cta-button1"
              onClick={(e) => handleFormFillUp(e)}
            >
              <span className="h6_cta-btn1">
                Start Your Profile
                <i className="fa-light fa-arrow-up-right" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelCome;
