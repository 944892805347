import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { Avatar, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../../redux/authentication/AuthenticationSlice";
import { removeallStudentFormDara } from "../../redux/dashboard/PageRenderSlice";
import { removeStudentDetail } from "../../redux/StudentRegd/StudentRegdSlice";
const Hadear = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const [toggle, setToggle] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [notAuth, setnotAuth] = useState(null);
  const open = Boolean(anchorEl);
  const notauthOpen = Boolean(notAuth);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const notAuthhandleClick = (event) => {
    setnotAuth(event.currentTarget);
  };
  const notAuthhandleClose = () => {
    setnotAuth(null);
  };

  const logoutClick = () => {
    dispatch(signout());
    dispatch(removeallStudentFormDara());
    dispatch(removeStudentDetail());
    navigate("/");
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        {/* sidebar-information-area-start */}
        <div
          className={
            toggle === true
              ? "sidebar-info side-info info-open"
              : "sidebar-info side-info "
          }
        >
          <div className="sidebar-logo-wrapper mb-25">
            <div className="row align-items-center">
              <div className="col-xl-6 col-8">
                <div className="sidebar-logo">
                  <Link to="/">
                    <img src="/assets/img/logo/f1visalogo8.svg" alt />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-4">
                <div className="sidebar-close-wrapper text-end">
                  <button
                    className="sidebar-close side-info-close"
                    onClick={() => setToggle(false)}
                  >
                    <i className="fal fa-times" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar-menu-wrapper fix">
            <div className="mobile-menu mean-container">
              <div className="mean-bar">
                <Link
                  to="/"
                  className="meanmenu-reveal"
                  style={{ right: 0, left: "auto", display: "inline" }}
                >
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </Link>
                <nav className="mean-nav">
                  <div className="mean-bar">
                    <Link
                      to="/"
                      className="meanmenu-reveal"
                      style={{ right: 0, left: "auto", display: "inline" }}
                      onClick={() => setToggle(false)}
                    >
                      <span>
                        <span>
                          <span />
                        </span>
                      </span>
                    </Link>
                    <nav className="mean-nav" />
                  </div>
                  <ul style={{ display: "none" }}>
                    <li className="menu-has-child">
                      <Link to="/how-it-works" onClick={() => setToggle(false)}>
                        How It Works
                      </Link>
                    </li>
                    <li className="menu-has-child">
                      <Link to="/" onClick={() => setToggle(false)}>
                        Match University
                      </Link>
                    </li>
                    <li className="menu-has-child">
                      <Link to="/universities" onClick={() => setToggle(false)}>
                        University
                      </Link>
                    </li>
                    <li className="menu-has-child">
                      <Link to="/pricing" onClick={() => setToggle(false)}>
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" onClick={() => setToggle(false)}>
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to="/blogs" onClick={() => setToggle(false)}>
                        Blog
                      </Link>
                    </li>

                    <li>
                      <Link to="/sign-in" onClick={() => setToggle(false)}>
                        Sign In
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            toggle === true
              ? "offcanvas-overlay overlay-open"
              : "offcanvas-overlay"
          }
        />
        {/* sidebar-information-area-end */}
      </div>

      <header>
        <div className="header-area header-sticky">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-10 col-lg-10 col-md-10 col-10">
                <div className="header-left">
                  <div className="header-logo">
                    <Link to="/">
                      <img src="/assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  <nav
                    className="main-menu mobile-menu d-none d-xl-block"
                    id="mobile-menu"
                  >
                    <ul>
                      <li className="menu-has-child">
                        <Link to="/how-it-works">How It Works</Link>
                      </li>
                      <li className="menu-has-child">
                        <Link to="/">Match University</Link>
                      </li>
                      {/* <li className="menu-has-child">
                        <Link to="/universities">University</Link>
                      </li> */}
                      <li className="menu-has-child">
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blog</Link>
                      </li>
                      {/* <li>
                        <Link to="/about-us">About us</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/dashbord/welcome">welcome</Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-2">
                <div className="header-right">
                  {!isAuth ? (
                    <>
                      <div className="header-btn d-none d-sm-block">
                        <React.Fragment>
                          {/* <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            <Link to="/sign-in">
                            Register/Log In</Link>
                          
                          </Box> */}
                          <div className="h3_header-right">
                            <div className="h3_header-btn d-none d-sm-block">
                              <Link to="/sign-in" className="header-btn theme-btn theme-btn-medium">Register/Log In</Link>
                            </div>

                          </div>

                          <Menu
                            anchorEl={notAuth}
                            id="account-menu"
                            open={notauthOpen}
                            onClose={notAuthhandleClose}
                            onClick={notAuthhandleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                notAuthhandleClose();
                                navigate("/sign-in");
                              }}
                            >
                              <ListItemIcon>
                                <LoginIcon fontSize="small" />
                              </ListItemIcon>
                              Sign In
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                notAuthhandleClose();
                                navigate("/sign-up");
                              }}
                            >
                              <ListItemIcon>
                                <PersonAdd fontSize="small" />
                              </ListItemIcon>
                              Sign Up
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      </div>
                    </>
                  ) : (
                    <>
                      {loginData.subscriptionstatus &&
                        loginData.noofTimeLogin > 0 ? (
                        <>
                          <div className="header-btn d-none d-sm-block">
                            <React.Fragment>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <Tooltip title="Account settings">
                                  <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={
                                      open ? "account-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                  >
                                    <Avatar
                                      size="large"
                                      src="https://res.cloudinary.com/dh1fsseho/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1669977353/Avatar/avatar2_z6yynb.jpg"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&::before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleClose();
                                    navigate("/dashbord/home");
                                  }}
                                  style={{ gap: "5px" }}
                                >
                                  <Avatar
                                    size="medium"
                                    className="mr-2"
                                    src="https://res.cloudinary.com/dh1fsseho/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1669977353/Avatar/avatar2_z6yynb.jpg"
                                  />
                                  Profile
                                </MenuItem>

                                <Divider />
                                <MenuItem
                                  onClick={() => {
                                    handleClose();
                                    navigate("/change-password");
                                  }}
                                >
                                  <ListItemIcon>
                                    <PersonAdd fontSize="small" />
                                  </ListItemIcon>
                                  Change Password
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    logoutClick();
                                  }}
                                >
                                  <ListItemIcon>
                                    <Logout fontSize="small" />
                                  </ListItemIcon>
                                  Sign Out
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="header-btn d-none d-sm-block">
                            <React.Fragment>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <Tooltip title="Account settings">
                                  <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={
                                      open ? "account-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                  >
                                    <Avatar
                                      size="large"
                                      src="https://res.cloudinary.com/dh1fsseho/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1669977353/Avatar/avatar2_z6yynb.jpg"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&::before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleClose();
                                    navigate("/dashbord/welcome");
                                  }}
                                >
                                  <Avatar
                                    size="medium"
                                    className="mr-2"
                                    src="https://res.cloudinary.com/dh1fsseho/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1669977353/Avatar/avatar2_z6yynb.jpg"
                                  />
                                  Profile
                                </MenuItem>

                                <Divider />
                                <MenuItem onClick={handleClose}>
                                  <ListItemIcon>
                                    <PersonAdd fontSize="small" />
                                  </ListItemIcon>
                                  Change Password
                                </MenuItem>
                                <MenuItem onClick={() => logoutClick()}>
                                  <ListItemIcon>
                                    <Logout fontSize="small" />
                                  </ListItemIcon>
                                  Sign Out
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className="header-menu-bar d-xl-none ml-10">
                    <span
                      className="header-menu-bar-icon side-toggle"
                      onClick={() => setToggle(true)}
                    >
                      <i className="fa-light fa-bars" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Hadear;
