import React from "react";

const TestimonialArea = () => {
  return (
    <>
      <section className=" pt-40 pb-20">
        <div className="container container-custom-2">
          <div className="section-area-8 text-center mb-60">
            <span className="section-subtitle">EDUCATION FOR EVERYONE</span>
            <h2 className="section-title mb-0">Our Student's Feedback</h2>
          </div>
          <div className="h8_testimonial-content">
            <div className="h8_testimonial-item">
              <img
                src="assets/img/testimonial/8/quote.png"
                alt
                className="h8_testimonial-quote"
              />
              <p>
                “After the launch, vulputate at sapien sit amet, <br />
                auctor iaculis lorem. In vel hend rerit nisi. <br />
                Vestibulum eget risus velit”
              </p>
              <div className="h8_testimonial-item-admin">
                <div className="h8_testimonial-item-img">
                  <img src="assets/img/testimonial/8/1.jpg" alt />
                </div>
                <div className="h8_testimonial-item-info">
                  <h4>
                    <a href="#">Martha Maldonado</a>
                  </h4>
                  <span>UI/UX Developer</span>
                </div>
              </div>
              <div className="h8_testimonial-item-rating">
                <ul>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="h8_testimonial-item">
              <img
                src="assets/img/testimonial/8/quote.png"
                alt
                className="h8_testimonial-quote"
              />
              <p>
                “After the launch, vulputate at sapien sit amet, <br />
                auctor iaculis lorem. In vel hend rerit nisi. <br />
                Vestibulum eget risus velit”
              </p>
              <div className="h8_testimonial-item-admin">
                <div className="h8_testimonial-item-img">
                  <img src="assets/img/testimonial/8/2.jpg" alt />
                </div>
                <div className="h8_testimonial-item-info">
                  <h4>
                    <a href="#">Michael D. Lovelady</a>
                  </h4>
                  <span>UI/UX Developer</span>
                </div>
              </div>
              <div className="h8_testimonial-item-rating">
                <ul>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="h8_testimonial-item">
              <img
                src="assets/img/testimonial/8/quote.png"
                alt
                className="h8_testimonial-quote"
              />
              <p>
                “After the launch, vulputate at sapien sit amet, <br />
                auctor iaculis lorem. In vel hend rerit nisi. <br />
                Vestibulum eget risus velit”
              </p>
              <div className="h8_testimonial-item-admin">
                <div className="h8_testimonial-item-img">
                  <img src="assets/img/testimonial/8/3.jpg" alt />
                </div>
                <div className="h8_testimonial-item-info">
                  <h4>
                    <a href="#">Valerie J. Creasman</a>
                  </h4>
                  <span>UI/UX Developer</span>
                </div>
              </div>
              <div className="h8_testimonial-item-rating">
                <ul>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="h8_testimonial-item">
              <img
                src="assets/img/testimonial/8/quote.png"
                alt
                className="h8_testimonial-quote"
              />
              <p>
                “After the launch, vulputate at sapien sit amet, <br />
                auctor iaculis lorem. In vel hend rerit nisi. <br />
                Vestibulum eget risus velit”
              </p>
              <div className="h8_testimonial-item-admin">
                <div className="h8_testimonial-item-img">
                  <img src="assets/img/testimonial/8/4.jpg" alt />
                </div>
                <div className="h8_testimonial-item-info">
                  <h4>
                    <a href="#">Martha Maldonado</a>
                  </h4>
                  <span>UI/UX Developer</span>
                </div>
              </div>
              <div className="h8_testimonial-item-rating">
                <ul>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="h8_testimonial-item">
              <img
                src="assets/img/testimonial/8/quote.png"
                alt
                className="h8_testimonial-quote"
              />
              <p>
                “After the launch, vulputate at sapien sit amet, <br />
                auctor iaculis lorem. In vel hend rerit nisi. <br />
                Vestibulum eget risus velit”
              </p>
              <div className="h8_testimonial-item-admin">
                <div className="h8_testimonial-item-img">
                  <img src="assets/img/testimonial/8/5.jpg" alt />
                </div>
                <div className="h8_testimonial-item-info">
                  <h4>
                    <a href="#">Pelican Stevens</a>
                  </h4>
                  <span>UI/UX Developer</span>
                </div>
              </div>
              <div className="h8_testimonial-item-rating">
                <ul>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="h8_testimonial-item">
              <img
                src="assets/img/testimonial/8/quote.png"
                alt
                className="h8_testimonial-quote"
              />
              <p>
                “After the launch, vulputate at sapien sit amet, <br />
                auctor iaculis lorem. In vel hend rerit nisi. <br />
                Vestibulum eget risus velit”
              </p>
              <div className="h8_testimonial-item-admin">
                <div className="h8_testimonial-item-img">
                  <img src="assets/img/testimonial/8/6.jpg" alt />
                </div>
                <div className="h8_testimonial-item-info">
                  <h4>
                    <a href="#">David Millan Root</a>
                  </h4>
                  <span>UI/UX Developer</span>
                </div>
              </div>
              <div className="h8_testimonial-item-rating">
                <ul>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="h8_testimonial-item">
              <img
                src="assets/img/testimonial/8/quote.png"
                alt
                className="h8_testimonial-quote"
              />
              <p>
                “After the launch, vulputate at sapien sit amet, <br />
                auctor iaculis lorem. In vel hend rerit nisi. <br />
                Vestibulum eget risus velit”
              </p>
              <div className="h8_testimonial-item-admin">
                <div className="h8_testimonial-item-img">
                  <img src="assets/img/testimonial/8/7.jpg" alt />
                </div>
                <div className="h8_testimonial-item-info">
                  <h4>
                    <a href="#">Janine Do Verner</a>
                  </h4>
                  <span>UI/UX Developer</span>
                </div>
              </div>
              <div className="h8_testimonial-item-rating">
                <ul>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="h8_testimonial-item">
              <img
                src="assets/img/testimonial/8/quote.png"
                alt
                className="h8_testimonial-quote"
              />
              <p>
                “After the launch, vulputate at sapien sit amet, <br />
                auctor iaculis lorem. In vel hend rerit nisi. <br />
                Vestibulum eget risus velit”
              </p>
              <div className="h8_testimonial-item-admin">
                <div className="h8_testimonial-item-img">
                  <img src="assets/img/testimonial/8/7.jpg" alt />
                </div>
                <div className="h8_testimonial-item-info">
                  <h4>
                    <a href="#">Janine Do Verner</a>
                  </h4>
                  <span>UI/UX Developer</span>
                </div>
              </div>
              <div className="h8_testimonial-item-rating">
                <ul>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                  <li>
                    <i className="fa-solid fa-star" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialArea;
