import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { Autoplay, FreeMode, Mousewheel, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

const PopularUniversity = () => {
  return (
    <>
      <section className="category-area pt-40">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <div className="category-left pb-60">
                <div className="section-area-8">
                  <span className="section-subtitle">
                    RECOMMENDED INSTITUTIONS
                  </span>
                  <h2 className="section-title mb-20">Featured this week</h2>
                </div>
                <div className="category-navigation">
                  <div className="category-prev">
                    <i className="fa-thin fa-arrow-left" />
                  </div>
                  <div className="category-next">
                    <i className="fa-light fa-arrow-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9">
              <div className="category-wrap">
                <div className="category-shape">
                  <img
                    src="/assets/img/category/1/shape-1.png"
                    alt=""
                    className="category-shape-1"
                  />
                  <img
                    src="/assets/img/category/1/shape-2.png"
                    alt=""
                    className="category-shape-2"
                  />
                  <img
                    src="/assets/img/category/1/shape-3.png"
                    alt=""
                    className="category-shape-3"
                  />
                  <img
                    src="/assets/img/category/1/shape-4.png"
                    alt=""
                    className="category-shape-4"
                  />
                </div>
                <div className="category-active swiper pb-60 backGCol">
                  <div className="swiper-wrapper ">
                    {/* <Swiper
                      grabCursor={true}
                      navigation={false}
                      pagination={true}
                      mousewheel={false}
                      keyboard={true}
                      modules={[
                        Navigation,
                        // Pagination,
                        Mousewheel,
                        Keyboard,
                        Autoplay,
                      ]}
                      loop={true}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                      }}
                      breakpoints={{
                        "@0.00": {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        "@0.75": {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        "@1.00": {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                        "@1.50": {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                      }}
                      className="mySwiper "
                      style={{ paddingBottom: "10px", backgroundColor: "#fff" }}
                    >
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="category-item">
                            <div className="category-img">
                              <img
                                src="/assets/img/category/1/HarvardUniversity.jpg"
                                alt=""
                              />
                            </div>
                            <div className="category-content">
                              <h5>
                                <Link>Harvard University</Link>
                              </h5>
                              <p>
                                <Link>
                                  26 Programs
                                  <i className="fa-light fa-arrow-right" />
                                  <i className="fa-light fa-arrow-right" />
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="category-item">
                            <div className="category-img">
                              <img
                                src="/assets/img/category/1/uni1.jpg"
                                alt=""
                              />
                            </div>
                            <div className="category-content">
                              <h5>
                                <Link>Stanford University</Link>
                              </h5>
                              <p>
                                <Link>
                                  78 Programs
                                  <i className="fa-light fa-arrow-right" />
                                  <i className="fa-light fa-arrow-right" />
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="category-item">
                            <div className="category-img">
                              <img
                                src="/assets/img/category/1/uni2.jpg"
                                alt=""
                              />
                            </div>
                            <div className="category-content">
                              <h5>
                                <Link>Harvard University</Link>
                              </h5>
                              <p>
                                <Link>
                                  26 Programs
                                  <i className="fa-light fa-arrow-right" />
                                  <i className="fa-light fa-arrow-right" />
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper> */}
                  </div>
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={10}
                    freeMode={true}
                    // pagination={{
                    //   clickable: false,
                    // }}
                    modules={[Pagination, Mousewheel, Autoplay]}
                    loop={true}
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                      360: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      460: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      720: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      1080: {
                        slidesPerView: 3,
                        // spaceBetween: 5,
                      },
                    }}
                    className="mySwiper p-2"
                  >
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="category-item">
                          <div className="category-img">
                            <img src="/assets/img/category/1/uni1.jpg" alt="" />
                          </div>
                          <div className="category-content">
                            <h5>
                              <Link>Stanford University</Link>
                            </h5>
                            <p>
                              <Link>
                                78 Programs
                                <i className="fa-light fa-arrow-right" />
                                <i className="fa-light fa-arrow-right" />
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="category-item">
                          <div className="category-img">
                            <img src="/assets/img/category/1/uni2.jpg" alt="" />
                          </div>
                          <div className="category-content">
                            <h5>
                              <Link>Harvard University</Link>
                            </h5>
                            <p>
                              <Link>
                                26 Programs
                                <i className="fa-light fa-arrow-right" />
                                <i className="fa-light fa-arrow-right" />
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="category-item">
                          <div className="category-img">
                            <img src="/assets/img/category/1/uni1.jpg" alt="" />
                          </div>
                          <div className="category-content">
                            <h5>
                              <Link>Stanford University</Link>
                            </h5>
                            <p>
                              <Link>
                                78 Programs
                                <i className="fa-light fa-arrow-right" />
                                <i className="fa-light fa-arrow-right" />
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="category-item">
                          <div className="category-img">
                            <img src="/assets/img/category/1/uni2.jpg" alt="" />
                          </div>
                          <div className="category-content">
                            <h5>
                              <Link>Harvard University</Link>
                            </h5>
                            <p>
                              <Link>
                                26 Programs
                                <i className="fa-light fa-arrow-right" />
                                <i className="fa-light fa-arrow-right" />
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularUniversity;
