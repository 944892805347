import React from "react";
import graph from "./graph.jpg";
const Analysis = ({
  singleQuestion,
  index,
  oIndex,
  handleAnswer,
  handleSubmit,
}) => {
  console.log(singleQuestion,"singleQuestion");
  
  return (
    <div style={{ marginTop: "4rem" }}>
      <div className="top_headerrr_con">
        <div>
          <p className="side_contenttt"></p>
        </div>
        <div>
          <button className="sub_colorrr" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>

      <div className="for_multipleeee_question">
        <div className="contentt_about_grpag">
          <p>{singleQuestion.QuestionTitle}</p>
        </div>
      </div>
      {singleQuestion?.image !== "" && (
        <div className="containerrr_grpahhhh_Centerr">
          <img src={singleQuestion?.image} alt="graph" />
        </div>
      )}
      {singleQuestion?.image !== "" && (
        <div className="above_quuuu">
          <div className="down_questionnnn">
            <p>{singleQuestion?.singleQuestion_discription}</p>
          </div>
        </div>
      )}

      <div className="above_container_innnn">
        <div className="all_input_questionnnnn">
          <div className="input_containerrrr">
            <div className="input_sub_container">
              <input
                id={`question-${index}-option-${oIndex}`}
                type="radio"
                name={`question-${index}-${oIndex}`}
                onChange={() =>
                  handleAnswer(index, singleQuestion, singleQuestion.option1)
                }
              />
            </div>
            <div className="for_answerrrr_content">
              <span>[A]</span>
              <span htmlFor={`question-${index}-option-${oIndex}`}>
                {singleQuestion.option1}
              </span>
            </div>
          </div>
          <div className="input_containerrrr">
            <div className="input_sub_container">
              <input
                id={`question-${index}-option-${oIndex}`}
                type="radio"
                name={`question-${index}-${oIndex}`}
                onChange={() =>
                  handleAnswer(index, singleQuestion, singleQuestion.option2)
                }
              />
            </div>
            <div className="for_answerrrr_content">
              <span>[B]</span>
              <span htmlFor={`question-${index}-option-${oIndex}`}>
                {singleQuestion.option2}
              </span>
            </div>
          </div>
          <div className="input_containerrrr">
            <div className="input_sub_container">
              <input
                id={`question-${index}-option-${oIndex}`}
                type="radio"
                name={`question-${index}-${oIndex}`}
                onChange={() =>
                  handleAnswer(index, singleQuestion, singleQuestion.option3)
                }
              />
            </div>
            <div className="for_answerrrr_content">
              <span>[C]</span>
              <span htmlFor={`question-${index}-option-${oIndex}`}>
                {singleQuestion.option3}
              </span>
            </div>
          </div>
          <div className="input_containerrrr" style={{ marginBottom: "5vh" }}>
            <div className="input_sub_container">
              <input
                id={`question-${index}-option-${oIndex}`}
                type="radio"
                name={`question-${index}-${oIndex}`}
                onChange={() =>
                  handleAnswer(index, singleQuestion, singleQuestion.option4)
                }
              />
            </div>
            <div className="for_answerrrr_content">
              <span>[D]</span>
              <span htmlFor={`question-${index}-option-${oIndex}`}>
                {singleQuestion.option4}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analysis;
