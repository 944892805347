import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Pin = () => {
  const navigate = useNavigate();

  const { studentRegdInfo } = useSelector((store) => store.StudentRegd);

  const [otpvalue, setOtpvalue] = useState("");
  const [btnPress, setbtnPress] = useState(false);
  const [birthYear, setBirthYear] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [otpvalueError, setOtpvalueError] = useState("");

  useEffect(() => {
    if (studentRegdInfo.birthdate !== "") {
      const text = studentRegdInfo.birthdate;
      const firstSplit = text.split("-");
      if (firstSplit.length === 3) {
        const textone = firstSplit[0];
        const texttwo = firstSplit[1];
        const textThree = firstSplit[2];
        setBirthYear(textone);
        setBirthMonth(texttwo);
        setBirthDate(textThree);
      }
    }
  }, [studentRegdInfo]);

  const currentYear = new Date().getFullYear(); // Get the current year
  const SelectYear = [];

  for (let i = 0; i < 50; i++) {
    SelectYear.push({
      id: i + 1,
      name: (currentYear - i - 18).toString(),
    });
  }

  const selectMonth = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const selectDate = [];

  for (let i = 1; i <= 31; i++) {
    selectDate.push({
      id: i,
      name: i.toString(),
    });
  }

  const otpvalueChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setOtpvalue(value);
    }
  };
  useEffect(() => {
    if (btnPress === true) {
      if (otpvalue.length === 6) {
        setOtpvalueError("Please enter 6 digit pin");
      } else {
        setOtpvalueError("");
      }
    }
  }, [btnPress, otpvalue]);

  const continueClick = (e) => {
    e.preventDefault();
    setOtpvalueError("");
    if (otpvalue.length === 6) {
      setOtpvalueError("Pin sholud be atleast 6 character");
    } else {
      setOtpvalueError("");
    }

    if (otpvalue.length === 6) {
      if (otpvalue === studentRegdInfo.otp) {
        navigate("/password");
      } else {
        setOtpvalueError("Pin doesn't match");
      }
    } else {
      setOtpvalueError("Pin sholud be atleast 6 character");
    }
  };

  return (
    <>
      <div className="account-area pb-10 backgroundimage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10 fillinfopadding">
              <div className="account-wrap">
                <div className="account-main">
                  <div className="sing_image">
                    <Link to="/">
                      <img src="assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  <h5 className="account-title2">
                    A temporary PIN has been sent to your email address.
                  </h5>
                  <span className="account-form">
                    <div className="account-form-item mb-10">
                      <TextField
                        label="First Name"
                        className="textfield"
                        type="text"
                        id="text"
                        style={{ width: "100%" }}
                        value={studentRegdInfo.firstname}
                        disabled={true}
                      />
                    </div>
                    <div className="account-form-item mb-10">
                      <TextField
                        label="Last Name"
                        className="textfield"
                        type="text"
                        id="text"
                        style={{ width: "100%" }}
                        value={studentRegdInfo.lastname}
                        disabled={true}
                      />
                    </div>
                    <div className="account-form-item mb-10">
                      <TextField
                        label="Email address"
                        className="textfield"
                        type="email"
                        id="text"
                        style={{ width: "100%" }}
                        value={studentRegdInfo.email}
                        disabled={true}
                      />
                    </div>
                    <div className="account-form-item mb-10">
                      <div className="drop_down_conta">
                        <TextField
                          id="select"
                          label="Date"
                          select
                          className="ant_drop_down_small"
                          value={birthDate}
                          disabled={true}
                          size="small"
                        >
                          {selectDate.map((date, index) => (
                            <MenuItem
                              selected={birthDate === date.name}
                              value={date.name}
                              key={index}
                            >
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Month"
                          select
                          className="ant_drop_down_small"
                          value={birthMonth}
                          disabled={true}
                          size="small"
                        >
                          {selectMonth.map((date, index) => (
                            <MenuItem
                              selected={birthMonth === date.name}
                              value={date.name}
                              key={index}
                            >
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="select"
                          label="Year"
                          select
                          className="ant_drop_down_small"
                          value={birthYear}
                          disabled={true}
                          size="small"
                        >
                          {SelectYear.map((date, index) => (
                            <MenuItem
                              selected={birthYear === date.name}
                              value={date.name}
                              key={index}
                            >
                              {date.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="account-form-item mb-2">
                      <TextField
                        label="Enter your PIN"
                        className="textfield"
                        type="text"
                        id="text"
                        style={{ width: "100%" }}
                        error={otpvalueError !== "" ? true : false}
                        helperText={otpvalueError}
                        onChange={(e) => otpvalueChange(e)}
                        value={otpvalue}
                        autocomplete="off"
                        inputProps={{
                          minLength: 6,
                          maxLength: 6,
                        }}
                      />
                    </div>
                    <div className="account-form-button">
                      <button
                        type="submit"
                        className="account-btn"
                        onClick={(e) => continueClick(e)}
                      >
                        Continue
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReactTooltip
          id="my-tooltip-1"
          place="top"
          variant="info"
          content="In the USA, Bachelor's programs require applicants to have completed their secondary education, which means obtaining a high school diploma or an equivalent following 12 years of schooling.  Also, please note that there are a few colleges or universities offering transfer or lateral admission into their 2-year Bachelor's program if the applicant has completed courses equivalent to an Associate Degree (2 years) in the USA. This requires an educational evaluation of your completed post-secondary courses. It's a good opportunity to join a 2-year Bachelor's degree program. "
          className="tooltip_style"
        />
      </div>
    </>
  );
};

export default Pin;
