import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { studentDashboardPageChange } from "../redux/studentDashboardPage/StudentDasboardSlice";
import {
  authActions,
  studentLoginBypassword,
} from "../redux/authentication/AuthenticationSlice";

const SignIn = () => {
  const { isAuth } = useSelector((store) => store.Athentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visiable, setVisiable] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleClickShowPassword = () => setVisiable((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  useEffect(() => {
    if (btnPress === true) {
      if (email === "") {
        setEmailError("Please enter email");
      } else {
        setEmailError("");
      }
      if (password === "") {
        setPasswordError("Please enter password");
      } else {
        setPasswordError("");
      }
    }
  }, [btnPress, email, password]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setbtnPress(true);
    if (email === "") {
      setEmailError("Please enter email");
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPasswordError("Please enter password");
    } else {
      setPasswordError("");
    }

    if (email !== "" && password !== "") {
      const formData = {
        email: email,
        password: password,
      };
      try {
        const loginResponce = await dispatch(studentLoginBypassword(formData));
        if (loginResponce.payload.success) {
          dispatch(
            authActions.signin({
              ...loginResponce.payload.student,
              isAuth: true,
            })
          );
          if (loginResponce.payload.student.subscriptionstatus) {
            navigate("/dashbord/home");
            if (loginResponce.payload.student.noofTimeLogin > 0) {
              dispatch(studentDashboardPageChange(1));
            }
          } else {
            navigate("/dashbord/welcome");
          }

        } else {
          setPasswordError("Password does't match");
          setEmailError("Email does't match");
        }
      } catch (error) {}
    } else {
    }
  };

  return (
    <>
      <div className="account-area pt-10 pb-10 backgroundimage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <div className="account-wrap">
                <div className="account-main">
                  <div className="sing_image">
                    <Link to="/">
                      <img src="assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  {/* <h3 className="account-title">Sign in to Your Account </h3> */}
                  <div
                    style={{
                      margin: "15vh 0",
                    }}
                  ></div>
                  <span className="account-form">
                    <div className="account-form-item mb-20">
                      <div className="">
                        <TextField
                          label="Enter Email ID"
                          className="textfield"
                          type="email"
                          id="text"
                          style={{ width: "100%" }}
                          error={emailError !== "" ? true : false}
                          helperText={emailError}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          value={email}
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div className="account-form-item mb-15">
                      <TextField
                        style={{ width: "100%" }}
                        type={visiable ? "text" : "password"}
                        label="Password"
                        id="outlined-start-adornment"
                        error={passwordError !== "" ? true : false}
                        helperText={passwordError}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        value={password}
                        autocomplete="off"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={handleClickShowPassword}
                              className="currsor_po"
                            >
                              {visiable ? <VisibilityOff /> : <Visibility />}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="account-form-condition set_remember_inputts">
                      <label className="condition_label">
                        Remember Me
                        <input type="checkbox" />
                        <span className="check_mark" />
                      </label>
                      <Link to="/forget-password">Forgot Password ?</Link>
                    </div>
                    <div className="account-form-button">
                      <button
                        type="submit"
                        className="account-btn"
                        onClick={onSubmitHandler}
                      >
                        Sign In
                      </button>
                    </div>
                  </span>

                  <div className="account-bottom">
                    <div className="account-bottom-text">
                      <p>
                        Have an account ? <Link to="/sign-up">Sign Up</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
