import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { studentDashboardPageChange } from "../../redux/studentDashboardPage/StudentDasboardSlice";

const PracticeTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const navigationClick = () => {
    if (isAuth === true) {
      if (loginData.subscriptionstatus && loginData.noofTimeLogin > 0) {
        navigate("/dashbord/home");
        dispatch(studentDashboardPageChange(6));
      } else {
        navigate("/dashbord/welcome");
      }
    } else {
      navigate("/sign-in");
    }
  };
  return (
    <>
      <section
        className="h3_about-area pt-40"
        style={{ background: "#f4f7ff" }}
      >
        <img
          src="/assets/img/about/3/shape-5.png"
          alt=""
          className="h3_about-top-shape"
        />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="h3_about-img mb-50">
                <div className="h3_about-inner-img w_img mr-50">
                  <img src="/assets/img/about/3/Study.png" alt="" />
                </div>
                <div className="h3_about-img-shape d-none d-sm-block">
                  <img
                    className="h3_about-img-shape-1"
                    src="/assets/img/about/3/shape-1.png"
                    alt=""
                  />
                  <img
                    className="h3_about-img-shape-2"
                    src="/assets/img/about/3/shape-2.png"
                    alt=""
                  />
                  <img
                    className="h3_about-img-shape-3"
                    src="/assets/img/about/3/shape-3.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="h3_about-wrap mr-65 mb-50">
                <img
                  src="/assets/img/about/3/shape-4.png"
                  alt=""
                  className="h3_about-wrap-shape"
                />
                <div className="section-area-3 mb-35 small-section-area-3">
                  <h2
                    className="section-title mb-20"
                    style={{ textTransform: "capitalize" }}
                  >
                    Transform Learning with Validated Practice Tests
                  </h2>
                  <p
                    className="section-text"
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Our test preparation solutions are crafted to bolster your
                    studies, instilling the skills and confidence essential for
                    excelling in these exams. Drawing from empirical evidence,
                    we have meticulously curated practice tests that perfectly
                    complement your study resources. With our subscription, you
                    have the flexibility to select one English language test and
                    one college admission test to tailor your preparation to
                    your specific needs.
                  </p>
                </div>
                <div className="h3_about-content mb-35">
                  <div className="row">
                    <div className="col-sm-6">
                      <span>
                        <i className="fa-regular fa-check" />
                        TOEFL
                      </span>
                    </div>
                    <div className="col-sm-6">
                      <span>
                        <i className="fa-regular fa-check" />
                        SAT
                      </span>
                    </div>
                    <div className="col-sm-6">
                      <span>
                        <i className="fa-regular fa-check" />
                        GRE
                      </span>
                    </div>
                    <div className="col-sm-6">
                      <span>
                        <i className="fa-regular fa-check" />
                        IELTS
                      </span>
                    </div>
                    <div className="col-sm-6">
                      <span>
                        <i className="fa-regular fa-check" />
                        ACT
                      </span>
                    </div>
                    <div className="col-sm-6">
                      <span>
                        <i className="fa-regular fa-check" />
                        GMAT
                      </span>
                    </div>
                  </div>
                </div>
                <div className="h3_about-button">
                  <span
                    className="theme-btn theme-btn-medium theme-btn-3"
                    style={{
                      background: "#395BDF",
                    }}
                    onClick={() => navigationClick()}
                  >
                    Learn More
                    <i className="fa-light fa-arrow-up-right" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PracticeTest;
