import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  HomeTwoTone,
} from "@ant-design/icons";
import {
  FaRegUser,
  FaPlaneDeparture,
  FaUniversity,
  FaRegAddressCard,
} from "react-icons/fa";
import { MdMonetizationOn, MdJoinFull } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { GrScorecard } from "react-icons/gr";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaObjectUngroup } from "react-icons/fa6";
import { Menu } from "antd";
import { dashPageStatuseChange } from "../../redux/dashboard/PageRenderSlice";
const { SubMenu } = Menu;

const MenuBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginData } = useSelector((store) => store.Athentication);
  const { pageRenderStatus } = useSelector((store) => store.DashboardPage);
  const { currentFormStep, studentDetail } = useSelector(
    (store) => store.StudentRegd
  );

  const [stateOpenKeys, setStateOpenKeys] = useState(["2", "23"]);

  useEffect(() => {
    if (currentFormStep > 0) {
      dispatch(dashPageStatuseChange(1));
    }
  }, [currentFormStep]);

  const items = [
    // {currentFormStep >= 1 ? (<></>): (<></>)},
    {
      key: "1",
      icon: <HomeTwoTone />,
      label: "Welcome",
      form_step: 0,
    },
    {
      key: "2",
      icon: <FaRegUser />,
      label: "Personal Information",
      disabled: currentFormStep >= 1 ? false : true,
      form_step: 1,
    },
    {
      key: "3",
      icon: <FaPlaneDeparture />,
      label: " Travel Data",
      disabled: currentFormStep >= 2 ? false : true,
      form_step: 2,
    },
    {
      key: "4",
      icon: <FaUniversity />,
      label: "Admission Session",
      disabled: currentFormStep >= 3 ? false : true,
      form_step: 3,
    },
    {
      key: "5",
      icon: <PiStudentBold />,
      label: "Education",
      disabled: currentFormStep >= 4 ? false : true,
      form_step: 4,
    },
    {
      key: "6",
      icon: <MdMonetizationOn />,
      label: "Work Experience",
      disabled: currentFormStep >= 5 ? false : true,
      form_step: 5,
    },
    {
      key: "7",
      icon: <MdMonetizationOn />,
      label: "Financial Support",
      disabled: currentFormStep >= 6 ? false : true,
      form_step: 6,
    },
    {
      key: "8",
      icon: <FaRegAddressCard />,
      label: "Test Score (s)",
      disabled: currentFormStep >= 7 ? false : true,
      form_step: 7,
    },
    {
      key: "9",
      icon: <IoMdInformationCircleOutline />,
      label: "Other Information",
      disabled: currentFormStep >= 8 ? false : true,
      form_step: 8,
    },
    {
      key: "10",
      icon: <FaObjectUngroup />,
      label: "Other Objectives",
      disabled: currentFormStep >= 11 ? false : true,
      form_step: 11,
    },
    // {
    //   key: "11",
    //   icon: <MdJoinFull />,
    //   label: "Match Preferences",
    //   disabled: true,
    //   form_step: 9,
    // },
  ];

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(items);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const navigationclick = (value) => {
    const nav_link = value.item.props.form_step;
    dispatch(dashPageStatuseChange(nav_link));
  };

  const handleClick = (e) => {
    console.log("click ", e);
  };

  return (
    <>
      {/* <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        onClick={(e) => navigationclick(e)}
        style={{}}
        items={items}
      /> */}

      <Menu mode="inline" style={{ width: "100%" }}>
        {currentFormStep === 0 && (
          <Menu.Item
            icon={<HomeTwoTone />}
            key="1"
            onClick={() => dispatch(dashPageStatuseChange(0))}
          >
            Welcome
          </Menu.Item>
        )}

        <Menu.Item
          icon={<FaRegUser />}
          key="2"
          disabled={currentFormStep >= 1 ? false : true}
          onClick={() => dispatch(dashPageStatuseChange(1))}
        >
          Personal Information
        </Menu.Item>
        <Menu.Item
          icon={<FaPlaneDeparture />}
          key="2"
          disabled={currentFormStep >= 2 ? false : true}
          onClick={() => dispatch(dashPageStatuseChange(2))}
        >
          Travel Data
        </Menu.Item>
        <Menu.Item
          icon={<FaUniversity />}
          key="3"
          disabled={currentFormStep >= 3 ? false : true}
          onClick={() => dispatch(dashPageStatuseChange(3))}
        >
          Admission Session
        </Menu.Item>
        <Menu.Item
          icon={<PiStudentBold />}
          key="4"
          disabled={currentFormStep >= 4 ? false : true}
          onClick={() => dispatch(dashPageStatuseChange(4))}
        >
          Education
        </Menu.Item>
        <Menu.Item
          icon={<MdMonetizationOn />}
          key="5"
          disabled={currentFormStep >= 5 ? false : true}
          onClick={() => dispatch(dashPageStatuseChange(5))}
        >
          Work Experience
        </Menu.Item>
        <Menu.Item
          icon={<MdMonetizationOn />}
          key="6"
          disabled={currentFormStep >= 6 ? false : true}
          onClick={() => dispatch(dashPageStatuseChange(6))}
        >
          Financial Support
        </Menu.Item>
        <Menu.Item
          icon={<FaRegAddressCard />}
          key="7"
          disabled={currentFormStep >= 7 ? false : true}
          onClick={() => dispatch(dashPageStatuseChange(7))}
        >
          Test Score (s)
        </Menu.Item>
        <Menu.Item
          icon={<IoMdInformationCircleOutline />}
          key="8"
          disabled={currentFormStep >= 8 ? false : true}
          onClick={() => dispatch(dashPageStatuseChange(8))}
        >
          Other Information
        </Menu.Item>

        {currentFormStep === 9 && (
          <Menu.Item
            icon={<FaObjectUngroup />}
            key="9"
            disabled={currentFormStep >= 9 ? false : true}
            onClick={() => dispatch(dashPageStatuseChange(9))}
          >
            Other Objectives
          </Menu.Item>
        )}
        {currentFormStep !== 0 && <Menu.Item key="11"></Menu.Item>}
      </Menu>
    </>
  );
};

export default MenuBar;
