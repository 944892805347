import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "./F1VisaProcess.css";
import "./HowItWorks.css";
// import "./LeverageAboutus.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import Faq from "./pages/Faq";
import Pricing from "./pages/Pricing";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Pin from "./pages/Pin";
import Password from "./pages/Password";
import StudentRegistation from "./pages/StudentRegistation";
import Dashbord_layout from "./component/Dashbord_layout";
import StudentDashBord from "./pages/StudentDashBord";
import Subscription from "./pages/Subscription";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountry } from "./redux/dashboard/PageRenderSlice";
import {
  getAllMajor_subjects,
  getAllPrograms,
  getAllUniversity,
  getAllWishlist,
  getTestByDegreeName,
  getTestScheduleByStudentId,
  setallUniversityIds,
} from "./redux/Test/TestSlice";
import { getBlogList } from "./redux/blog/BlogSlice";
import { getStudentDetail } from "./redux/StudentRegd/StudentRegdSlice";
import MatchedPrograms from "./pages/MatchedPrograms";
import SearchResultLayout from "./component/SearchResultLayout";
import UniversityByObject from "./pages/UniversityByObject";
import UniversityByProfile from "./pages/UniversityByProfile";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Universities from "./pages/Universities";
import Careers from "./pages/Careers";
import ForgetPassword from "./pages/ForgetPassword";
import Reset_Password from "./pages/Reset_Password";
import ChangePassword from "./pages/ChangePassword";
import ProgramDetails from "./pages/ProgramDetails";
import { getProgramsstdList, getUniversity_by_object, getUniversity_by_profile } from "./redux/authentication/AuthenticationSlice";
import UniversityInfoPage from "./pages/universityInfo/UniversityInfoPage";
import BlogsBycategory from "./pages/BlogsBycategory";
import HowTodoIt from "./pages/HowTodoIt";
import HowItwork from "./pages/HowItwork";
import HowItWorks from "./pages/HowItWorks";

function ScrollToTop() {
  const {
    isAuth,
    loginData,
    programsbystudTotal,
    isUniversityAvailableLoading,
    isUniversityLoading,
    isProgramstdAvailableLoading,
    isProgramstdLoading
  } = useSelector((state) => state.Athentication);
  const {
    totalUniversity,
    isUniversityAvailable,
    universityLoading,
    totalPrograms,
    isProgramAvailable,
    programLoading,
    major_subjects,
    isMajor_subjectsAvailable,
    major_subjectsLoading
  } = useSelector((state) => state.testManagement);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isUniversityAvailable || totalUniversity.length === 0) {
      dispatch(getAllUniversity());
    }
    if (!isProgramAvailable || totalPrograms.length === 0) {
      dispatch(getAllPrograms());
    }
    if (!isMajor_subjectsAvailable || major_subjects.length === 0) {
      dispatch(getAllMajor_subjects());
    }
  }, [])
 


  useEffect(() => {
    const initialSetup = async () => {
      try {
        dispatch(getAllCountry());
        dispatch(getBlogList());

        if (universityLoading) {
          dispatch(getAllUniversity());
        }
        if (programLoading) {
          dispatch(getAllPrograms());
        }
        if (major_subjectsLoading) {
          dispatch(getAllMajor_subjects());
        }

        if (isAuth) {
          dispatch(getStudentDetail(loginData));
          dispatch(getTestByDegreeName(loginData));
          dispatch(getTestScheduleByStudentId(loginData));
          dispatch(getAllWishlist(loginData._id));
          const formdata = {
            educationlevel: loginData.degreeName,
            examType: "IELTS",
            // examType: loginData.testScore.englishexamType,
            // examscrore: loginData.testScore.englishTotalScore,
            examscrore: "6.8",
            financeStatus: 20000,
            education: loginData.education.GraduationOption,
          };
          dispatch(getUniversity_by_profile(formdata))
        }
      } catch (error) { }
    };
    initialSetup();
  }, [isAuth, loginData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/pin" element={<Pin />} />
          <Route path="/password" element={<Password />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<Reset_Password />} />
          <Route path="/change-password" element={<ChangePassword />} />

          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog-info/:slugUrl" element={<BlogDetails />} />
            <Route path="/blog-category/:blogcat" element={<BlogsBycategory />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/how-to-do-it" element={<HowTodoIt />} />
            <Route path="/how-it-works" element={<HowItWorks />} />

            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/universities" element={<Universities />} />
            <Route path="/careers" element={<Careers />} />
          </Route>

          <Route path="/results" exact element={<SearchResultLayout />}>
            <Route
              path="/results/match-programs"
              index
              element={<MatchedPrograms />}
            />
            <Route
              path="/results/university-by-objective"
              index
              element={<UniversityByObject />}
            />
            <Route
              path="/results/university-by-profile"
              index
              element={<UniversityByProfile />}
            />
          </Route>

          <Route path="/dashbord" exact element={<Dashbord_layout />}>
            <Route
              path="/dashbord/welcome"
              index
              element={<StudentRegistation />}
            />
            <Route path="/dashbord/home" element={<StudentDashBord />} />
            <Route path="/dashbord/program_info" element={<ProgramDetails />} />
            <Route path="/dashbord/university_info" element={<UniversityInfoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
