import React from "react";
import { Link } from "react-router-dom";

const SingleUniversityCard = ({ uiversity, index }) => {
  return (
    <>
      <div className="col-xxl-12 col-lg-12 col-md-12 p-2">
        <div
          style={{ height: "300px" }}
          className="course-item card border-0 shadow-sm mb-30"
        >
          <div style={{ height: "120px" }} className="course-content">
            <div className="course-content-top">
              <div className="course-top-icon">
                <img src="/assets/img/harvard.jpg" alt />
              </div>
              <div className="course-top-title">
                <h6>{uiversity.universityName}</h6>
              </div>
            </div>
            <ul className="course-hover-content-list">
              <li>
                <i className="fa fa-map-marker" aria-hidden="true" />
                City: {uiversity.city}
              </li>
              <li>
                <i className="fa fa-graduation-cap" aria-hidden="true" />
                Type : {uiversity.universityType}
              </li>
              <li>
                <i className="fa fa-bookmark-o" aria-hidden="true" />
                Establised Since :{uiversity.establishedYear}
              </li>
              <li>
                <i className="fa fa-level-up" aria-hidden="true" />
                QS Ranking: {index + 1}
              </li>
            </ul>
            <div className="course-content-bottom">
              <div className="course-bottom-info">
                <span>
                  <i className="fa-thin fa-book-blank" />
                  {uiversity.subArrayLength}
                </span>
                <span>
                  <i className="fa-thin fa-user-group" />
                  5000
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleUniversityCard;
