import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Space, Alert, Button, notification } from "antd";
import {
  student_change_password,
  student_reset_password,
} from "../redux/authentication/AuthenticationSlice";

const ChangePassword = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const { loginData } = useSelector((store) => store.Athentication);

  const [oldpassword, setoldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [currectpassword, setCurrectPassword] = useState("");
  const [cnfPassword, setcnfPassword] = useState("");
  const [mathedPassword, setmathedPassword] = useState("");
  const [btnPress, setbtnPress] = useState(false);

  const [oldshowPassword, setoldShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);

  const [oldpasswordError, setoldPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cnfPasswordError, setcnfPasswordError] = useState("");
  const [errorArray, seterrorArray] = useState([]);
  const [passwordmatchError, setcnfPasswordmatchError] = useState("");

  const [open, setOpen] = useState(false);
  const [buttonloading, setbuttonloading] = useState(false);

  const handleClickoldShowPassword = () => setoldShowPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCnfPassword = () => setShowCnfPassword((show) => !show);

  useEffect(() => {
    if (btnPress === true) {
      if (oldpassword === "") {
        setoldPasswordError("Please enter password");
      } else {
        setoldPasswordError("");
      }
      if (currectpassword === "") {
        setPasswordError("Please enter password");
      } else {
        setPasswordError("");
      }
      if (mathedPassword !== currectpassword || currectpassword === "") {
        setcnfPasswordError("Both password are not matched");
      } else {
        setcnfPasswordError("");
      }
    }
  }, [btnPress, currectpassword, mathedPassword, oldpassword]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [open]);

  const changePassword = (e) => {
    setOpen(true);
    const currpassword = e.target.value;
    setPassword(currpassword);
    const lengthRegex = /^.{8,}$/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharacterRegex = /[!@#$%^&*()_+]/;
    const numberRegex = /[0-9]/;
    let errorarray = [];
    if (uppercaseRegex.test(currpassword)) {
      errorarray = [...errorarray, "uppercase"];
    }
    if (lowercaseRegex.test(currpassword)) {
      errorarray = [...errorarray, "lowercase"];
    }
    if (specialCharacterRegex.test(currpassword)) {
      errorarray = [...errorarray, "special"];
    }
    if (numberRegex.test(currpassword)) {
      errorarray = [...errorarray, "number"];
    }
    if (lengthRegex.test(currpassword)) {
      errorarray = [...errorarray, "8characters"];
    }

    if (
      uppercaseRegex.test(currpassword) &&
      lowercaseRegex.test(currpassword) &&
      numberRegex.test(currpassword) &&
      lengthRegex.test(currpassword) &&
      specialCharacterRegex.test(currpassword)
    ) {
      setCurrectPassword(currpassword);
      setOpen(false);
    } else {
      setOpen(true);
    }

    seterrorArray([...errorarray]);
  };

  const changeConfermPassword = (e) => {
    const currpassword = e.target.value;
    setcnfPassword(currpassword);
    if (currectpassword === currpassword) {
      setmathedPassword(currpassword);
      setcnfPasswordError("");
    } else {
      setmathedPassword("");
      setcnfPasswordError("Please Confirm your password");
    }
  };

  const items = [
    {
      label: errorArray.includes("uppercase") ? (
        <span
          style={{
            color: "green",
          }}
        >
          Must contain at least one uppercase letter
        </span>
      ) : (
        <>
          <span>Must contain at least one uppercase letter</span>
        </>
      ),
      key: "1",
    },
    {
      label: errorArray.includes("lowercase") ? (
        <span
          style={{
            color: "green",
          }}
        >
          Must contain at least one lowercase letter
        </span>
      ) : (
        <>
          <span>Must contain at least one lowercase letter</span>
        </>
      ),
      key: "2",
    },
    {
      label: errorArray.includes("special") ? (
        <span
          style={{
            color: "green",
          }}
        >
          Must contain at least one special character
        </span>
      ) : (
        <>
          <span>Must contain at least one special character</span>
        </>
      ),
      key: "3",
    },
    {
      label: errorArray.includes("number") ? (
        <span
          style={{
            color: "green",
          }}
        >
          Must contain at least one number
        </span>
      ) : (
        <>
          <span>Must contain at least one number</span>
        </>
      ),
      key: "4",
    },
    {
      label: errorArray.includes("8characters") ? (
        <span
          style={{
            color: "green",
          }}
        >
          Must contain at least 8 characters
        </span>
      ) : (
        <>
          <span>Must contain at least 8 characters</span>
        </>
      ),
      key: "5",
    },
  ];

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Password Changed successfully",
      description: "",
    });
  };

  const passwordClick = async (e) => {
    e.preventDefault();
    setbtnPress(true);
    setbuttonloading(true);

    if (currectpassword === mathedPassword && oldpassword !== "") {
      const formdata = {
        password: mathedPassword,
        oldpassword: oldpassword,
        email: loginData.email,
      };
      const change_pass = await dispatch(student_change_password(formdata));
      if (change_pass.payload.success) {
        openNotificationWithIcon("success");
        setbtnPress(false);
        setPassword("");
        setoldPassword("");
        setCurrectPassword("");
        setcnfPassword("");
        setmathedPassword("");

        const timer = setTimeout(() => {
          navigate("/");
        }, 3000);

        return () => clearTimeout(timer);
      } else {
        setoldPasswordError(change_pass.payload.message);
      }
    } else {
      setbtnPress(true);
      setbuttonloading(false);
      setcnfPasswordmatchError("Password does't match");
    }
    setbuttonloading(false);
  };

  return (
    <>
      {contextHolder}
      <div className="account-area pt-10 pb-10 backgroundimage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <div className="account-wrap">
                <div className="account-main">
                  <div className="sing_image">
                    <Link to="/">
                      <img src="assets/img/logo/f1visalogo8.svg" alt />
                    </Link>
                  </div>
                  <h3 className="account-title">Change Password</h3>
                  <span className="account-form">
                    <div className="account-form-item mb-15">
                      <TextField
                        style={{ width: "100%" }}
                        type={oldshowPassword ? "text" : "password"}
                        label="Old Password"
                        id="outlined-start-adornment"
                        error={oldpasswordError !== "" ? true : false}
                        helperText={oldpasswordError}
                        onChange={(e) => {
                          setoldPassword(e.target.value);
                        }}
                        value={oldpassword}
                        autocomplete="off"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={handleClickoldShowPassword}
                              className="currsor_po"
                            >
                              {oldshowPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="account-form-item mb-15" ref={ref}>
                      <Dropdown
                        menu={{
                          items,
                        }}
                        open={open}
                      >
                        <TextField
                          style={{ width: "100%" }}
                          type={showPassword ? "text" : "password"}
                          label="Enter New Password"
                          id="outlined-start-adornment"
                          error={passwordError !== "" ? true : false}
                          helperText={passwordError}
                          onChange={(e) => {
                            changePassword(e);
                          }}
                          value={password}
                          autocomplete="off"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                onClick={handleClickShowPassword}
                                className="currsor_po"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Dropdown>
                    </div>
                    <div className="account-form-item mb-15">
                      <TextField
                        style={{ width: "100%" }}
                        type={showCnfPassword ? "text" : "password"}
                        label="Confirm New Password"
                        id="outlined-start-adornment"
                        error={cnfPasswordError !== "" ? true : false}
                        helperText={cnfPasswordError}
                        onChange={(e) => {
                          changeConfermPassword(e);
                        }}
                        value={cnfPassword}
                        autocomplete="off"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={handleClickShowCnfPassword}
                              className="currsor_po"
                            >
                              {showCnfPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <div className="account-form-button">
                      {buttonloading === true ? (
                        <>
                          <button
                            type="submit"
                            disabled={true}
                            className="account-btn"
                          >
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="account-btn"
                            onClick={(e) => passwordClick(e)}
                          >
                            Save Password
                          </button>
                        </>
                      )}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
