import React, { useState } from 'react'


import img from './workpeople_enhanced.png'
const VideoContent = () => {
    const [soundLevel, setSoundLevel] = useState(50);

    const handleSliderChange = (e) => {
        setSoundLevel(e.target.value);
    };
    return (
        <div>
            <div style={{ marginTop: "4.5rem" }}>
                <div className='total_container'>
                    <div className='to_flex_endddddd'>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={soundLevel}
                            className="slider_new"
                            onChange={handleSliderChange}
                        />
                    </div>
                </div>

            </div>
            <div >
                <div className='inside_videosss'>
                    <img src={img} alt='error' className='inside_videosss_sub' />
                </div>
            </div>

        </div>
    )
}

export default VideoContent
