import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
      <div>
        <section className="about-area pt-10">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-area-9 text-center mb-40">
                  <span
                    className="section-subtitle"
                    style={{
                      color: "#F14D5D",
                    }}
                  >
                    About Us
                  </span>
                  <h2 className="section-title mb-0">About Us</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6">
                <div className="about-img mb-50">
                  <img src="assets/img/about/1/1.png" alt />
                  <div className="about-img-meta">
                    <span>
                      <i className="fa-solid fa-star" />
                      4.5 (3.4k Reviews)
                    </span>
                    <h5>Congratulations</h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-10">
                <div className="about-content mb-50">
                  <div className="section-area mb-20">
                    <span className="section-subtitle">
                      Start learning Free
                    </span>
                    <h2 className="section-title mb-15">
                      Online Course can be Tailored to Need of learners
                    </h2>
                    <p className="section-text">
                      Through a combination of lectures, readings, discussions,
                      students will gain a solid foundation in educational
                      psychology.
                    </p>
                  </div>
                  <div className="about-content-list">
                    <ul>
                      <li>International course collection in 14 languages</li>
                      <li>Top certifications in tech and business</li>
                      <li>
                        Access to 35,000+ top Eduan courses, anytime, anywhere
                      </li>
                    </ul>
                  </div>
                  <div className="about-content-button">
                    <Link className="theme-btn about-btn theme-btn-medium" to="/about-us">
                      More Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* teacher area start */}
        <section className="h2_teacher-area pb-80">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-area-9 text-center mb-40">
                  <span
                    className="section-subtitle"
                    style={{
                      color: "#F14D5D",
                    }}
                  >
                    Our Team Members
                  </span>
                  <h2 className="section-title mb-0">Our Team Members</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="h2_teacher-item mb-30">
                  <div className="h2_teacher-img">
                    <img src="assets/img/teacher/2/1.jpg" alt />
                  </div>
                  <div className="h2_teacher-content">
                    <h5 className="h2_teacher-content-title">
                      <a href="#">Parsley Montana</a>
                    </h5>
                    <span>Lead Teacher</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="h2_teacher-item mb-30">
                  <div className="h2_teacher-img">
                    <img src="assets/img/teacher/2/2.jpg" alt />
                  </div>
                  <div className="h2_teacher-content">
                    <h5 className="h2_teacher-content-title">
                      <a href="#">Parsley Montana</a>
                    </h5>
                    <span>Lead Teacher</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="h2_teacher-item mb-30">
                  <div className="h2_teacher-img">
                    <img src="assets/img/teacher/2/3.jpg" alt />
                  </div>
                  <div className="h2_teacher-content">
                    <h5 className="h2_teacher-content-title">
                      <a href="#">Parsley Montana</a>
                    </h5>
                    <span>Lead Teacher</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="h2_teacher-item mb-30">
                  <div className="h2_teacher-img">
                    <img src="assets/img/teacher/2/4.jpg" alt />
                  </div>
                  <div className="h2_teacher-content">
                    <h5 className="h2_teacher-content-title">
                      <a href="#">Parsley Montana</a>
                    </h5>
                    <span>Lead Teacher</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="h2_teacher-item mb-30">
                  <div className="h2_teacher-img">
                    <img src="assets/img/teacher/2/5.jpg" alt />
                  </div>
                  <div className="h2_teacher-content">
                    <h5 className="h2_teacher-content-title">
                      <a href="#">Parsley Montana</a>
                    </h5>
                    <span>Lead Teacher</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="h2_teacher-item mb-30">
                  <div className="h2_teacher-img">
                    <img src="assets/img/teacher/2/6.jpg" alt />
                  </div>
                  <div className="h2_teacher-content">
                    <h5 className="h2_teacher-content-title">
                      <a href="#">Parsley Montana</a>
                    </h5>
                    <span>Lead Teacher</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="h2_teacher-item mb-30">
                  <div className="h2_teacher-img">
                    <img src="assets/img/teacher/2/1.jpg" alt />
                  </div>
                  <div className="h2_teacher-content">
                    <h5 className="h2_teacher-content-title">
                      <a href="#">Parsley Montana</a>
                    </h5>
                    <span>Lead Teacher</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="h2_teacher-item mb-30">
                  <div className="h2_teacher-img">
                    <img src="assets/img/teacher/2/2.jpg" alt />
                  </div>
                  <div className="h2_teacher-content">
                    <h5 className="h2_teacher-content-title">
                      <a href="#">Parsley Montana</a>
                    </h5>
                    <span>Lead Teacher</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* teacher area end */}
      </div>
    </>
  );
};

export default AboutUs;
