import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/system";
import Header from "./Header";
import { Typography, Radio, Col, Button, Space, Input } from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import Autocomplete from "@mui/material/Autocomplete";
import { FixedSizeList as List } from "react-window";

import countries from "../json_datas/countries.json";
import cities from "../json_datas/states+cities.json";
import states from "../json_datas/countries+states.json";
import { updateStudent } from "../../../redux/StudentRegd/StudentRegdSlice";
import {
  dashPageStatuseChange,
  setPersonalInformation,
} from "../../../redux/dashboard/PageRenderSlice";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    color: "blue", // Text color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "green", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "red", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "purple", // Border color when focused
    },
  },
  "& .MuiInputLabel-root": {
    color: "orange", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "purple", // Label color when focused
  },
}));

const VirtualizedMenu = ({ items, onSelect, selectedValue }) => {
  return (
    <List
      height={200} // Height of the dropdown
      itemCount={items.length} // Number of items
      itemSize={40} // Height of each item
      width="100%" // Dropdown width
    >
      {({ index, style }) => (
        <MenuItem
          key={index}
          style={style} // Ensure proper styling for virtualization
          value={items[index].name}
          onClick={() => onSelect(items[index].name)} // Trigger selection
          selected={items[index].name === selectedValue} // Highlight selected
        >
          {items[index].name}
        </MenuItem>
      )}
    </List>
  );
};

const Personal_Information = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginData } = useSelector((store) => store.Athentication);
  const { studentDetail } = useSelector((store) => store.StudentRegd);

  const [countryofbirth, setCountryofbirth] = useState("");
  const [primarynational, setPrimarynational] = useState("");
  const [seconderynational, setseconderynational] = useState("");
  const [englishScore, setEnglishScore] = useState("");
  const [countryofresidence, setCountryofresidence] = useState("");
  const [permanentResidence, setPermanentResidence] = useState(false);
  const [gender, setGender] = useState("");
  const [maritalsts, setMaritalsts] = useState(false);
  const [familyto, setFamilyto] = useState(false);
  const [noofChildren, setNoofChildren] = useState(0);
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [volunterwork, setVolunterwork] = useState(false);
  const [volunterworkSts, setVolunterworSts] = useState("");
  const [extracurricular, setExtracurricular] = useState(false);
  const [extracurricularSts, setExtracurricularSts] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);
  const [btnPress, setbtnPress] = useState(false);

  // Error message
  const [countryofbirthError, setCountryofbirthError] = useState("");
  const [primarynationalError, setPrimarynationalError] = useState("");
  const [englishScoreError, setEnglishScoreError] = useState("");
  const [countryofresidenceError, setCountryofresidenceError] = useState("");
  const [permanentResidenceError, setPermanentResidenceError] = useState(null);
  const [genderError, setGenderError] = useState("");
  const [maritalstsError, setMaritalstsError] = useState(false);
  const [familytoError, setFamilytoError] = useState("");
  const [noofChildrenError, setNoofChildrenError] = useState(0);
  const [addressOneError, setAddressOneError] = useState("");
  const [addressTwoError, setAddressTwoError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipcodeError, setZipcodeError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [howmonthlongError, sethowmonthlongError] = useState("");
  const [whatlevelError, setwhatlevelError] = useState("");
  const [volunterworkError, setVolunterworkError] = useState(false);
  const [extracurricularError, setExtracurricularError] = useState(null);

  const [buttonPress, setbuttonPress] = useState(false);

  const [showStatesByCountry, setshowStatesByCountry] = useState([]);
  const [showCityByStates, setshowCityByStates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [CountryCoude, setCountryCoude] = useState("");
  const [CountryPhoneCode, setCountryPhoneCode] = useState("");

  const countryCoudeChange = (value) => {
    const find_code = countries.find((data) => data.name === value);
    const code = find_code.iso2.toLowerCase();
    const phone = find_code.phone_code;
    setCountryCoude(code);
    setCountryPhoneCode(phone);
  };

  useEffect(() => {
    if (studentDetail.currentFormStep > 1) {
      setGender(studentDetail.personalInformation.gender);
      setMaritalsts(studentDetail.personalInformation.maritalsts);
      setFamilyto(studentDetail.personalInformation.familyto);
      setNoofChildren(studentDetail.personalInformation.noofChildren);
      setPrimarynational(studentDetail.personalInformation.primarynational);
      setEnglishScore(studentDetail.personalInformation.englishScore);
      setCountryofresidence(
        studentDetail.personalInformation.countryofresidence
      );
      setPermanentResidence(
        studentDetail.personalInformation.permanentResidence
      );
      setCountryofbirth(studentDetail.personalInformation.countryofbirth);
      setAddressOne(studentDetail.personalInformation.addressOne);
      setAddressTwo(studentDetail.personalInformation.addressTwo);
      setCity(studentDetail.personalInformation.city);
      setState(studentDetail.personalInformation.state);
      setZipcode(studentDetail.personalInformation.zipcode);
      setPhoneNo(studentDetail.personalInformation.phoneNo);
      setVolunterwork(studentDetail.personalInformation.volunterwork);
      setVolunterworSts(studentDetail.personalInformation.volunterworkSts);
      setExtracurricular(studentDetail.personalInformation.extracurricular);
      setExtracurricularSts(
        studentDetail.personalInformation.extracurricularSts
      );
    }
  }, [studentDetail]);

  // useEffect(() => {
  //   if (countryofbirth !== "") {
  //     const filterStae = states.find((data) => data.name === countryofbirth);
  //     setshowStatesByCountry([...filterStae.states]);
  //   }
  // }, [countryofbirth]);

  const change_state = (value) => {
    const filterStae = states.find((data) => data.name === value);
    setshowStatesByCountry([...filterStae.states]);
  };

  useEffect(() => {
    if (state !== "") {
      const filterStae = cities.find((data) => data.name === state);
      if (filterStae !== "undefined" && filterStae !== undefined) {
        setshowCityByStates([...filterStae?.cities]);
      }
    }
  }, [state]);

  useEffect(() => {
    if (buttonPress === true) {
      if (gender === "") {
        setGenderError("Required");
      } else {
        setGenderError("");
      }

      if (countryofbirth === "") {
        setCountryofbirthError("Required");
      } else {
        setCountryofbirthError("");
      }
      if (countryofresidence === null) {
        setCountryofresidenceError("Required");
      } else {
        setCountryofresidenceError("");
      }
      if (addressOne === "") {
        setAddressOneError("Required");
      } else {
        setAddressOneError("");
      }
      if (state === "") {
        setStateError("Required");
      } else {
        setStateError("");
      }
      if (city === "") {
        setCityError("Required");
      } else {
        setCityError("");
      }
      if (zipcode === "") {
        setZipcodeError("Required");
      } else {
        setZipcodeError("");
      }
      if (phoneNo === "") {
        setPhoneNoError("Required");
      } else {
        setPhoneNoError("");
      }
      if (extracurricular === null) {
        setExtracurricularError("Required");
      } else {
        setExtracurricularError("");
      }

      if (volunterwork === true) {
        if (volunterworkSts === "") {
          sethowmonthlongError("Required");
        } else {
          sethowmonthlongError("");
        }
      }
      if (extracurricular === true) {
        if (extracurricularSts === "") {
          setwhatlevelError("Required");
        } else {
          setwhatlevelError("");
        }
      }
    }
  }, [
    buttonPress,
    gender,
    countryofbirth,
    countryofresidence,
    addressOne,
    state,
    city,
    zipcode,
    phoneNo,
    extracurricular,
    volunterwork,
    extracurricular,
    volunterworkSts,
    extracurricularSts,
  ]);

  const SelectGender = [
    {
      id: 1,
      name: "Male",
    },
    {
      id: 2,
      name: "Female",
    },
    {
      id: 3,
      name: "Prefer Not to Disclose",
    },
  ];
  const SelectEthinicity = [
    {
      id: 1,
      name: "White",
    },
    {
      id: 2,
      name: "Black or African American ",
    },
    {
      id: 3,
      name: "Asian",
    },
    {
      id: 4,
      name: "Hispanic or Latino",
    },
    {
      id: 5,
      name: "Other",
    },
  ];

  const SelectZIPcode = [
    {
      id: 1,
      name: "09878",
    },
    {
      id: 2,
      name: "65467",
    },
    {
      id: 3,
      name: "54323",
    },
  ];
  const SelectExtracurricular = [
    {
      id: 1,
      name: "National",
    },
    {
      id: 2,
      name: "Sub-National",
    },
    {
      id: 3,
      name: "District",
    },
    {
      id: 5,
      name: "Counties/Other",
    },
    {
      id: 4,
      name: "Inter-School/College",
    },
  ];

  const countries_phone = [
    { code: "AD", label: "Andorra", phone: "376" },
    {
      code: "AE",
      label: "United Arab Emirates",
      phone: "971",
    },
    { code: "AF", label: "Afghanistan", phone: "93" },
    {
      code: "AG",
      label: "Antigua and Barbuda",
      phone: "1-268",
    },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
    {
      code: "AU",
      label: "Australia",
      phone: "61",
      suggested: true,
    },
    { code: "AW", label: "Aruba", phone: "297" },
    { code: "AX", label: "Alland Islands", phone: "358" },
    { code: "AZ", label: "Azerbaijan", phone: "994" },
    {
      code: "BA",
      label: "Bosnia and Herzegovina",
      phone: "387",
    },
    { code: "BB", label: "Barbados", phone: "1-246" },
    { code: "BD", label: "Bangladesh", phone: "880" },
    { code: "BE", label: "Belgium", phone: "32" },
    { code: "BF", label: "Burkina Faso", phone: "226" },
    { code: "BG", label: "Bulgaria", phone: "359" },
    { code: "BH", label: "Bahrain", phone: "973" },
    { code: "BI", label: "Burundi", phone: "257" },
    { code: "BJ", label: "Benin", phone: "229" },
    { code: "BL", label: "Saint Barthelemy", phone: "590" },
    { code: "BM", label: "Bermuda", phone: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phone: "673" },
    { code: "BO", label: "Bolivia", phone: "591" },
    { code: "BR", label: "Brazil", phone: "55" },
    { code: "BS", label: "Bahamas", phone: "1-242" },
    { code: "BT", label: "Bhutan", phone: "975" },
    { code: "BV", label: "Bouvet Island", phone: "47" },
    { code: "BW", label: "Botswana", phone: "267" },
    { code: "BY", label: "Belarus", phone: "375" },
    { code: "BZ", label: "Belize", phone: "501" },
    {
      code: "CA",
      label: "Canada",
      phone: "1",
      suggested: true,
    },
    {
      code: "CC",
      label: "Cocos (Keeling) Islands",
      phone: "61",
    },
    {
      code: "CD",
      label: "Congo, Democratic Republic of the",
      phone: "243",
    },
    {
      code: "CF",
      label: "Central African Republic",
      phone: "236",
    },
    {
      code: "CG",
      label: "Congo, Republic of the",
      phone: "242",
    },
    { code: "CH", label: "Switzerland", phone: "41" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225" },
    { code: "CK", label: "Cook Islands", phone: "682" },
    { code: "CL", label: "Chile", phone: "56" },
    { code: "CM", label: "Cameroon", phone: "237" },
    { code: "CN", label: "China", phone: "86" },
    { code: "CO", label: "Colombia", phone: "57" },
    { code: "CR", label: "Costa Rica", phone: "506" },
    { code: "CU", label: "Cuba", phone: "53" },
    { code: "CV", label: "Cape Verde", phone: "238" },
    { code: "CW", label: "Curacao", phone: "599" },
    { code: "CX", label: "Christmas Island", phone: "61" },
    { code: "CY", label: "Cyprus", phone: "357" },
    { code: "CZ", label: "Czech Republic", phone: "420" },
    {
      code: "DE",
      label: "Germany",
      phone: "49",
      suggested: true,
    },
    { code: "DJ", label: "Djibouti", phone: "253" },
    { code: "DK", label: "Denmark", phone: "45" },
    { code: "DM", label: "Dominica", phone: "1-767" },
    {
      code: "DO",
      label: "Dominican Republic",
      phone: "1-809",
    },
    { code: "DZ", label: "Algeria", phone: "213" },
    { code: "EC", label: "Ecuador", phone: "593" },
    { code: "EE", label: "Estonia", phone: "372" },
    { code: "EG", label: "Egypt", phone: "20" },
    { code: "EH", label: "Western Sahara", phone: "212" },
    { code: "ER", label: "Eritrea", phone: "291" },
    { code: "ES", label: "Spain", phone: "34" },
    { code: "ET", label: "Ethiopia", phone: "251" },
    { code: "FI", label: "Finland", phone: "358" },
    { code: "FJ", label: "Fiji", phone: "679" },
    {
      code: "FK",
      label: "Falkland Islands (Malvinas)",
      phone: "500",
    },
    {
      code: "FM",
      label: "Micronesia, Federated States of",
      phone: "691",
    },
    { code: "FO", label: "Faroe Islands", phone: "298" },
    {
      code: "FR",
      label: "France",
      phone: "33",
      suggested: true,
    },
    { code: "GA", label: "Gabon", phone: "241" },
    { code: "GB", label: "United Kingdom", phone: "44" },
    { code: "GD", label: "Grenada", phone: "1-473" },
    { code: "GE", label: "Georgia", phone: "995" },
    { code: "GF", label: "French Guiana", phone: "594" },
    { code: "GG", label: "Guernsey", phone: "44" },
    { code: "GH", label: "Ghana", phone: "233" },
    { code: "GI", label: "Gibraltar", phone: "350" },
    { code: "GL", label: "Greenland", phone: "299" },
    { code: "GM", label: "Gambia", phone: "220" },
    { code: "GN", label: "Guinea", phone: "224" },
    { code: "GP", label: "Guadeloupe", phone: "590" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240" },
    { code: "GR", label: "Greece", phone: "30" },
    {
      code: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502" },
    { code: "GU", label: "Guam", phone: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phone: "245" },
    { code: "GY", label: "Guyana", phone: "592" },
    { code: "HK", label: "Hong Kong", phone: "852" },
    {
      code: "HM",
      label: "Heard Island and McDonald Islands",
      phone: "672",
    },
    { code: "HN", label: "Honduras", phone: "504" },
    { code: "HR", label: "Croatia", phone: "385" },
    { code: "HT", label: "Haiti", phone: "509" },
    { code: "HU", label: "Hungary", phone: "36" },
    { code: "ID", label: "Indonesia", phone: "62" },
    { code: "IE", label: "Ireland", phone: "353" },
    { code: "IL", label: "Israel", phone: "972" },
    { code: "IM", label: "Isle of Man", phone: "44" },
    { code: "IN", label: "India", phone: "91" },
    {
      code: "IO",
      label: "British Indian Ocean Territory",
      phone: "246",
    },
    { code: "IQ", label: "Iraq", phone: "964" },
    {
      code: "IR",
      label: "Iran, Islamic Republic of",
      phone: "98",
    },
    { code: "IS", label: "Iceland", phone: "354" },
    { code: "IT", label: "Italy", phone: "39" },
    { code: "JE", label: "Jersey", phone: "44" },
    { code: "JM", label: "Jamaica", phone: "1-876" },
    { code: "JO", label: "Jordan", phone: "962" },
    {
      code: "JP",
      label: "Japan",
      phone: "81",
      suggested: true,
    },
    { code: "KE", label: "Kenya", phone: "254" },
    { code: "KG", label: "Kyrgyzstan", phone: "996" },
    { code: "KH", label: "Cambodia", phone: "855" },
    { code: "KI", label: "Kiribati", phone: "686" },
    { code: "KM", label: "Comoros", phone: "269" },
    {
      code: "KN",
      label: "Saint Kitts and Nevis",
      phone: "1-869",
    },
    {
      code: "KP",
      label: "Korea, Democratic People's Republic of",
      phone: "850",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82" },
    { code: "KW", label: "Kuwait", phone: "965" },
    { code: "KY", label: "Cayman Islands", phone: "1-345" },
    { code: "KZ", label: "Kazakhstan", phone: "7" },
    {
      code: "LA",
      label: "Lao People's Democratic Republic",
      phone: "856",
    },
    { code: "LB", label: "Lebanon", phone: "961" },
    { code: "LC", label: "Saint Lucia", phone: "1-758" },
    { code: "LI", label: "Liechtenstein", phone: "423" },
    { code: "LK", label: "Sri Lanka", phone: "94" },
    { code: "LR", label: "Liberia", phone: "231" },
    { code: "LS", label: "Lesotho", phone: "266" },
    { code: "LT", label: "Lithuania", phone: "370" },
    { code: "LU", label: "Luxembourg", phone: "352" },
    { code: "LV", label: "Latvia", phone: "371" },
    { code: "LY", label: "Libya", phone: "218" },
    { code: "MA", label: "Morocco", phone: "212" },
    { code: "MC", label: "Monaco", phone: "377" },
    {
      code: "MD",
      label: "Moldova, Republic of",
      phone: "373",
    },
    { code: "ME", label: "Montenegro", phone: "382" },
    {
      code: "MF",
      label: "Saint Martin (French part)",
      phone: "590",
    },
    { code: "MG", label: "Madagascar", phone: "261" },
    { code: "MH", label: "Marshall Islands", phone: "692" },
    {
      code: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phone: "389",
    },
    { code: "ML", label: "Mali", phone: "223" },
    { code: "MM", label: "Myanmar", phone: "95" },
    { code: "MN", label: "Mongolia", phone: "976" },
    { code: "MO", label: "Macao", phone: "853" },
    {
      code: "MP",
      label: "Northern Mariana Islands",
      phone: "1-670",
    },
    { code: "MQ", label: "Martinique", phone: "596" },
    { code: "MR", label: "Mauritania", phone: "222" },
    { code: "MS", label: "Montserrat", phone: "1-664" },
    { code: "MT", label: "Malta", phone: "356" },
    { code: "MU", label: "Mauritius", phone: "230" },
    { code: "MV", label: "Maldives", phone: "960" },
    { code: "MW", label: "Malawi", phone: "265" },
    { code: "MX", label: "Mexico", phone: "52" },
    { code: "MY", label: "Malaysia", phone: "60" },
    { code: "MZ", label: "Mozambique", phone: "258" },
    { code: "NA", label: "Namibia", phone: "264" },
    { code: "NC", label: "New Caledonia", phone: "687" },
    { code: "NE", label: "Niger", phone: "227" },
    { code: "NF", label: "Norfolk Island", phone: "672" },
    { code: "NG", label: "Nigeria", phone: "234" },
    { code: "NI", label: "Nicaragua", phone: "505" },
    { code: "NL", label: "Netherlands", phone: "31" },
    { code: "NO", label: "Norway", phone: "47" },
    { code: "NP", label: "Nepal", phone: "977" },
    { code: "NR", label: "Nauru", phone: "674" },
    { code: "NU", label: "Niue", phone: "683" },
    { code: "NZ", label: "New Zealand", phone: "64" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "PA", label: "Panama", phone: "507" },
    { code: "PE", label: "Peru", phone: "51" },
    { code: "PF", label: "French Polynesia", phone: "689" },
    { code: "PG", label: "Papua New Guinea", phone: "675" },
    { code: "PH", label: "Philippines", phone: "63" },
    { code: "PK", label: "Pakistan", phone: "92" },
    { code: "PL", label: "Poland", phone: "48" },
    {
      code: "PM",
      label: "Saint Pierre and Miquelon",
      phone: "508",
    },
    { code: "PN", label: "Pitcairn", phone: "870" },
    { code: "PR", label: "Puerto Rico", phone: "1" },
    {
      code: "PS",
      label: "Palestine, State of",
      phone: "970",
    },
    { code: "PT", label: "Portugal", phone: "351" },
    { code: "PW", label: "Palau", phone: "680" },
    { code: "PY", label: "Paraguay", phone: "595" },
    { code: "QA", label: "Qatar", phone: "974" },
    { code: "RE", label: "Reunion", phone: "262" },
    { code: "RO", label: "Romania", phone: "40" },
    { code: "RS", label: "Serbia", phone: "381" },
    { code: "RU", label: "Russian Federation", phone: "7" },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966" },
    { code: "SB", label: "Solomon Islands", phone: "677" },
    { code: "SC", label: "Seychelles", phone: "248" },
    { code: "SD", label: "Sudan", phone: "249" },
    { code: "SE", label: "Sweden", phone: "46" },
    { code: "SG", label: "Singapore", phone: "65" },
    { code: "SH", label: "Saint Helena", phone: "290" },
    { code: "SI", label: "Slovenia", phone: "386" },
    {
      code: "SJ",
      label: "Svalbard and Jan Mayen",
      phone: "47",
    },
    { code: "SK", label: "Slovakia", phone: "421" },
    { code: "SL", label: "Sierra Leone", phone: "232" },
    { code: "SM", label: "San Marino", phone: "378" },
    { code: "SN", label: "Senegal", phone: "221" },
    { code: "SO", label: "Somalia", phone: "252" },
    { code: "SR", label: "Suriname", phone: "597" },
    { code: "SS", label: "South Sudan", phone: "211" },
    {
      code: "ST",
      label: "Sao Tome and Principe",
      phone: "239",
    },
    { code: "SV", label: "El Salvador", phone: "503" },
    {
      code: "SX",
      label: "Sint Maarten (Dutch part)",
      phone: "1-721",
    },
    {
      code: "SY",
      label: "Syrian Arab Republic",
      phone: "963",
    },
    { code: "SZ", label: "Swaziland", phone: "268" },
    {
      code: "TC",
      label: "Turks and Caicos Islands",
      phone: "1-649",
    },
    { code: "TD", label: "Chad", phone: "235" },
    {
      code: "TF",
      label: "French Southern Territories",
      phone: "262",
    },
    { code: "TG", label: "Togo", phone: "228" },
    { code: "TH", label: "Thailand", phone: "66" },
    { code: "TJ", label: "Tajikistan", phone: "992" },
    { code: "TK", label: "Tokelau", phone: "690" },
    { code: "TL", label: "Timor-Leste", phone: "670" },
    { code: "TM", label: "Turkmenistan", phone: "993" },
    { code: "TN", label: "Tunisia", phone: "216" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90" },
    {
      code: "TT",
      label: "Trinidad and Tobago",
      phone: "1-868",
    },
    { code: "TV", label: "Tuvalu", phone: "688" },
    {
      code: "TW",
      label: "Taiwan",
      phone: "886",
    },
    {
      code: "TZ",
      label: "United Republic of Tanzania",
      phone: "255",
    },
    { code: "UA", label: "Ukraine", phone: "380" },
    { code: "UG", label: "Uganda", phone: "256" },
    {
      code: "US",
      label: "United States",
      phone: "1",
      suggested: true,
    },
    { code: "UY", label: "Uruguay", phone: "598" },
    { code: "UZ", label: "Uzbekistan", phone: "998" },
    {
      code: "VA",
      label: "Holy See (Vatican City State)",
      phone: "379",
    },
    {
      code: "VC",
      label: "Saint Vincent and the Grenadines",
      phone: "1-784",
    },
    { code: "VE", label: "Venezuela", phone: "58" },
    {
      code: "VG",
      label: "British Virgin Islands",
      phone: "1-284",
    },
    {
      code: "VI",
      label: "US Virgin Islands",
      phone: "1-340",
    },
    { code: "VN", label: "Vietnam", phone: "84" },
    { code: "VU", label: "Vanuatu", phone: "678" },
    { code: "WF", label: "Wallis and Futuna", phone: "681" },
    { code: "WS", label: "Samoa", phone: "685" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967" },
    { code: "YT", label: "Mayotte", phone: "262" },
    { code: "ZA", label: "South Africa", phone: "27" },
    { code: "ZM", label: "Zambia", phone: "260" },
    { code: "ZW", label: "Zimbabwe", phone: "263" },
  ];

  const updateNextStep = async () => {
    if (studentDetail.currentFormStep > 1) {
      dispatch(dashPageStatuseChange(2));
    } else {
      const formData = {
        _id: loginData._id,
        personalInformation: {
          gender: gender,
          maritalsts: maritalsts,
          familyto: familyto,
          noofChildren: noofChildren,
          primarynational: primarynational ? primarynational : countryofbirth,
          englishScore: englishScore,
          countryofresidence: countryofresidence
            ? countryofresidence
            : countryofbirth,
          permanentResidence: permanentResidence,
          countryofbirth: countryofbirth,
          addressOne: addressOne,
          addressTwo: addressTwo,
          city: city,
          state: state,
          zipcode: zipcode,
          phoneNo: phoneNo,
          volunterwork: volunterwork,
          volunterworkSts: volunterworkSts,
          extracurricular: extracurricular,
          extracurricularSts: extracurricularSts,
        },
        personalInformationStatus: true,
        currentFormStep: 2,
      };
      const student = await dispatch(updateStudent(formData));
      if (student.payload.success) {
        dispatch(setPersonalInformation(formData));
        dispatch(dashPageStatuseChange(2));
        setbuttonPress(false);
      }
    }
  };

  const personalInfoClick = async (e) => {
    e.preventDefault();

    setbuttonPress(true);
    setLoading(true);
    if (gender === "") {
      setGenderError("Required");
    } else {
      setGenderError("");
    }

    if (countryofbirth === "") {
      setCountryofbirthError("Required");
    } else {
      setCountryofbirthError("");
    }
    if (countryofresidence === null) {
      setCountryofresidenceError("Required");
    } else {
      setCountryofresidenceError("");
    }
    if (addressOne === "") {
      setAddressOneError("Required");
    } else {
      setAddressOneError("");
    }
    if (state === "") {
      setStateError("Required");
    } else {
      setStateError("");
    }
    if (city === "") {
      setCityError("Required");
    } else {
      setCityError("");
    }
    if (zipcode === "") {
      setZipcodeError("Required");
    } else {
      setZipcodeError("");
    }
    if (phoneNo === "") {
      setPhoneNoError("Required");
    } else {
      setPhoneNoError("");
    }
    if (extracurricular === null) {
      setExtracurricularError("Required");
    } else {
      setExtracurricularError("");
    }

    if (volunterwork === true) {
      if (volunterworkSts === "") {
        sethowmonthlongError("Required");
      } else {
        sethowmonthlongError("");
      }
    }
    if (extracurricular === true) {
      if (extracurricularSts === "") {
        setwhatlevelError("Required");
      } else {
        setwhatlevelError("");
      }
      setPhoneNo("");
    }

    try {
      if (
        gender !== "" &&
        maritalsts !== "" &&
        countryofresidence !== null &&
        permanentResidence !== "" &&
        addressOne !== "" &&
        state !== "" &&
        city !== "" &&
        zipcode !== "" &&
        phoneNo !== ""
      ) {
        if (volunterwork === true && extracurricular === false) {
          if (volunterworkSts !== "") {
            await updateNextStep();
          } else {
            setbuttonPress(true);
          }
        }
        if (volunterwork === false && extracurricular === true) {
          if (extracurricularSts !== "") {
            await updateNextStep();
          } else {
            setbuttonPress(true);
          }
        } else if (volunterwork === true && extracurricular === true) {
          if (extracurricularSts !== "" && volunterworkSts !== "") {
            await updateNextStep();
          } else {
            setbuttonPress(true);
          }
        } else {
          await updateNextStep();
        }
      } else {
        setbuttonPress(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Personal Information"
            description="Please enter the data to create your profile"
          />
          <div className="inputs_container">
            <div className="dn_input_box">
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.name}
                value={countries.find((c) => c.name === countryofbirth) || null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCountryofbirth(newValue.name);
                    if (countryofresidence === "") {
                      countryCoudeChange(newValue.name);
                      change_state(newValue.name);
                      setZipcode("");
                      setCity("");
                      setState("");
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country of birth"
                    error={countryofbirthError !== "" ? true : false}
                    helperText={countryofbirthError}
                    size="small"
                  />
                )}
                className="input_style"
              />
            </div>
            <div className="dn_input_box">
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.name}
                value={
                  primarynational === ""
                    ? countries.find((c) => c.name === countryofbirth) || null
                    : countries.find((c) => c.name === primarynational) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setPrimarynational(newValue.name);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Primary Nationality "
                    error={countryofbirthError !== "" ? true : false}
                    helperText={countryofbirthError}
                    size="small"
                  />
                )}
                className="input_style"
              />
            </div>
            <div className="dn_input_box">
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.name}
                value={
                  countryofresidence === ""
                    ? countries.find((c) => c.name === countryofbirth) || null
                    : countries.find((c) => c.name === countryofresidence) ||
                      null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCountryofresidence(newValue.name);
                    countryCoudeChange(newValue.name);
                    change_state(newValue.name);
                    setZipcode("");
                    setCity("");
                    setState("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country of residence"
                    error={countryofbirthError !== "" ? true : false}
                    helperText={countryofbirthError}
                    size="small"
                  />
                )}
                className="input_style"
              />
            </div>
            <div className="dn_input_box">
              <TextField
                id="select"
                label="Secondary residence"
                select
                className="input_style"
                value={seconderynational}
                onChange={(e) => setseconderynational(e.target.value)}
                size="small"
              >
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="dn_input_box">
              <TextField
                id="select"
                label="Gender"
                select
                className="input_style"
                error={genderError !== "" ? true : false}
                helperText={genderError}
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                size="small"
              >
                {SelectGender.map((data, index) => (
                  <MenuItem key={index} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="dn_input_box">
              <TextField
                id="select"
                label="Ethnicity/Race"
                select
                className="input_style"
                // error={genderError !== "" ? true : false}
                // helperText={genderError}
                // value={gender}
                // onChange={(e) => setGender(e.target.value)}
                size="small"
              >
                {SelectEthinicity.map((data, index) => (
                  <MenuItem key={index} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">Marital Status</span>

              <Radio.Group defaultValue={false}>
                <Radio
                  value={false}
                  checked={maritalsts === false}
                  onClick={() => {
                    setMaritalsts(false);
                    setFamilyto(false);
                    setNoofChildren(0);
                  }}
                >
                  Single
                </Radio>
                <Radio
                  value={true}
                  checked={maritalsts === true}
                  onClick={() => {
                    setMaritalsts(true);
                  }}
                >
                  Married
                </Radio>
              </Radio.Group>
            </div>
            {maritalsts === true && (
              <div className="radio_div_container">
                <span className="radio_title">
                  Do you plan to bring your family to the US?
                </span>

                <Radio.Group defaultValue={false}>
                  <Radio
                    value={true}
                    checked={familyto === true}
                    onClick={() => setFamilyto(true)}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={familyto === false}
                    onClick={() => {
                      setFamilyto(false);
                      setNoofChildren(0);
                    }}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </div>
            )}
            {maritalsts === true && familyto === true && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="No. of children"
                    type="email"
                    id="text"
                    className="input_style"
                    value={noofChildren}
                    onChange={(e) =>
                      setNoofChildren(e.target.value.replace(/\D/g, ""))
                    }
                    autocomplete="off"
                    size="small"
                  />
                </div>
                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
              </>
            )}

            <div className="dn_input_box">
              <TextField
                label="Address Line 1 "
                className="input_style"
                error={addressOneError !== "" ? true : false}
                helperText={addressOneError}
                value={addressOne}
                onChange={(e) => setAddressOne(e.target.value)}
                autocomplete="off"
                id="outlined-multiline-static"
                multiline
                rows={4}
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Address Line 2 "
                className="input_style"
                value={addressTwo}
                onChange={(e) => setAddressTwo(e.target.value)}
                autocomplete="off"
                id="outlined-multiline-static"
                multiline
                rows={4}
              />
            </div>
            <div className="dn_input_box">
              <TextField
                label="Country"
                type="email"
                id="text"
                className="input_style"
                error={countryofbirthError !== "" ? true : false}
                helperText={countryofbirthError}
                value={
                  countryofresidence === ""
                    ? countryofbirth
                    : countryofresidence
                }
                autocomplete="off"
                size="small"
              />
            </div>

            <div className="dn_input_box">
              {showStatesByCountry.length > 0 ? (
                <Autocomplete
                  options={showStatesByCountry}
                  getOptionLabel={(option) => option.name}
                  value={showStatesByCountry.find((c) => c.name === state)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setState(newValue.name);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State/Province"
                      error={stateError !== "" ? true : false}
                      helperText={stateError}
                      size="small"
                    />
                  )}
                  className="input_style"
                />
              ) : (
                <TextField
                  type="text"
                  id="text"
                  className="input_style"
                  label="State/Province"
                  error={stateError !== "" ? true : false}
                  helperText={stateError}
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                  autoComplete="off"
                  size="small"
                />
              )}
            </div>
            {/* <div className="dn_input_box">
              <TextField
                id="select"
                label="State/Province"
                select
                className="input_style"
                error={stateError !== "" ? true : false}
                helperText={stateError}
                value={state}
                onChange={(e) => setState(e.target.value)}
                size="small"
              >
                {showStatesByCountry.map((data, index) => (
                  <MenuItem key={index} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
              </TextField>
            </div> */}
            <div className="dn_input_box">
              {showCityByStates.length > 0 ? (
                <>
                  <TextField
                    id="select"
                    label="City"
                    select
                    className="input_style"
                    error={cityError !== "" ? true : false}
                    helperText={cityError}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    size="small"
                  >
                    {showCityByStates.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              ) : (
                <>
                  <TextField
                    type="text"
                    id="text"
                    className="input_style"
                    label="City"
                    error={cityError !== "" ? true : false}
                    helperText={cityError}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    autoComplete="off"
                    size="small"
                  />
                </>
              )}
            </div>
            <div className="dn_input_box">
              {/* <TextField
                id="select"
                label=" ZIP/Postal Code "
                select
                className="input_style"
                error={zipcodeError !== "" ? true : false}
                helperText={zipcodeError}
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
                size="small"
              >
                {SelectZIPcode.map((data, index) => (
                  <MenuItem key={index} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
              </TextField> */}
              <TextField
                label=" ZIP/Postal Code "
                type="text"
                id="text"
                className="input_style"
                error={zipcodeError !== "" ? true : false}
                helperText={zipcodeError}
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value.replace(/\D/g, ""))}
                autocomplete="off"
                size="small"
              />
            </div>
            <div className="dn_input_box">
              <div
                style={{
                  width: "35%",
                  height: "7vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  gap: "1vh",
                }}
              >
                <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => option.phone_code || ""}
                  value={
                    countries.find((c) => c.phone_code === CountryPhoneCode) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      countryCoudeChange(newValue.name);
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.code}>
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                        alt=""
                        style={{
                          marginRight: 8,
                          width: "5vh",
                          height: "5vh",
                        }}
                      />
                      <span style={{ marginLeft: "auto" }}>
                        {option.phone_code}
                      </span>
                    </li>
                  )}
                  renderInput={(params) => {
                    const selectedCountry = countries.find(
                      (c) => c.phone_code === CountryPhoneCode
                    );
                    return (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: selectedCountry && (
                            <>
                              <img
                                loading="lazy"
                                srcSet={`https://flagcdn.com/w40/${selectedCountry.iso2.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${selectedCountry.iso2.toLowerCase()}.png`}
                                alt=""
                                style={{
                                  width: "5vh",
                                  height: "5vh",
                                }}
                              />
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </div>

              <TextField
                label="Phone Number"
                type="email"
                id="text"
                style={{
                  width: "65%",
                }}
                error={phoneNoError !== "" ? true : false}
                helperText={phoneNoError}
                onChange={(e) =>
                  setPhoneNo(e.target.value.replace(/[^0-9]/g, ""))
                }
                value={phoneNo}
                autocomplete="off"
                size="small"
                // inputProps={{
                //   minLength: 10,
                //   maxLength: 10,
                // }}
              />
            </div>
            <div className="dn_input_box">
              <div className="input_style"></div>
            </div>
            <div className="radio_div_container">
              <span className="radio_title">
                Have you ever engaged in volunteer work with an NGO or a
                Charitable organization?
              </span>
              <span className="radio_description">
                Working with an NGO may enhance your undergrad application.
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={volunterwork === true}
                  onClick={() => {
                    setVolunterwork(true);
                    setVolunterworkError("");
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={volunterwork === false}
                  onClick={() => {
                    setVolunterwork(false);
                    setVolunterworkError("");
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>

            {volunterwork === true && (
              <>
                <div className="dn_input_box">
                  <TextField
                    label="How Long (Months)"
                    type="email"
                    id="text"
                    className="input_style"
                    error={howmonthlongError !== "" ? true : false}
                    helperText={howmonthlongError}
                    onChange={(e) =>
                      setVolunterworSts(e.target.value.replace(/\D/g, ""))
                    }
                    value={volunterworkSts}
                    autocomplete="off"
                    size="small"
                  />
                </div>

                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
              </>
            )}

            <div className="radio_div_container">
              <span className="radio_title">
                Have you recently engaged prominently in any extracurricular
                activities?
              </span>
              <span className="radio_description">
                Participating in sports may enhance your undergrad application.
              </span>
              <Radio.Group defaultValue={false}>
                <Radio
                  value={true}
                  checked={extracurricular === true}
                  onClick={() => {
                    setExtracurricular(true);
                    setExtracurricularError("");
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value={false}
                  checked={extracurricular === false}
                  onClick={() => {
                    setExtracurricular(false);
                    setExtracurricularError("");
                  }}
                >
                  No
                </Radio>
              </Radio.Group>
            </div>
            {extracurricular === true && (
              <>
                <div className="dn_input_box">
                  <TextField
                    id="select"
                    label="At What level ?"
                    select
                    className="input_style"
                    error={whatlevelError !== "" ? true : false}
                    helperText={whatlevelError}
                    value={extracurricularSts}
                    onChange={(e) => setExtracurricularSts(e.target.value)}
                    size="small"
                  >
                    {SelectExtracurricular.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="dn_input_box">
                  <div className="input_style"></div>
                </div>
              </>
            )}

            <div className="button_container">
              <Button
                type="primary"
                loading={loading}
                iconPosition="end"
                icon={<PiPaperPlaneRight />}
                size="large"
                onClick={(e) => personalInfoClick(e)}
              >
                Save & Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Personal_Information;
