import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import {
  Typography,
  Radio,
  Col,
  Button,
  Space,
  Table,
  Modal,
  message,
} from "antd";
import { PiPaperPlaneRight } from "react-icons/pi";
import moment from "moment/moment";
import Header from "../../student_registation/components/Header";
import ToeflandIels from "./qustions/ToeflandIels";
import RemainingQustion from "./qustions/RemainingQustion";
import { IoMdExit } from "react-icons/io";
import { BiFullscreen } from "react-icons/bi";
import { FaArrowRight, FaSearch } from "react-icons/fa";
import { IoArrowBackOutline } from "react-icons/io5";
import { GrLinkNext } from "react-icons/gr";
import { GrFormNextLink } from "react-icons/gr";
import { WiTime4 } from "react-icons/wi";
import { ImArrowRight } from "react-icons/im";
import "./qustions/questions.css";

const Questions = () => {
  const { loginData, currect_questions } = useSelector(
    (store) => store.Athentication
  );
  const [timeLeft, setTimeLeft] = useState(120); // 15 minutes
  const [timeDuration, setTimeDuration] = useState(0);
  const [test, setTest] = useState([]);
  const [attemptedQtn, setAttemptedQtn] = useState([]);
  const [testTitle, setTestTitle] = useState("");
  const [testCode, setTestCode] = useState("");
  const [testType, settestType] = useState("");
  const [currentTest, setcurrentTest] = useState("");
  const [currentIndex, setcurrentIndex] = useState(0);
  const [answeredQtn, setAnsweredQtn] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        clearInterval(timer);
        // handleSubmit();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAnswer = (index, singleQuestion, answer) => {
    if (
      !attemptedQtn.includes(index) &&
      !answeredQtn.includes(singleQuestion)
    ) {
      const updatedQuestion = {
        qtnNo: index,
        ...singleQuestion,
        givenAnswer: answer,
      };
      setAttemptedQtn([...attemptedQtn, index]);
      setAnsweredQtn([...answeredQtn, updatedQuestion]);
    } else {
      let updateansweredQtn = answeredQtn;
      updateansweredQtn = updateansweredQtn.map((data, qtIndex) => {
        if (qtIndex === data.qtnNo) {
          data.givenAnswer = answer;
        }
        return data;
      });
      setAnsweredQtn([...updateansweredQtn]);
    }
  };

  const handleSubmit = async () => {
    const formData = {
      StudentName: loginData.firstname + loginData.lastname,
      StudentId: loginData._id,
      TestTitle: testTitle,
      slugUrl: testTitle,
      TestCode: testCode,
      TestType: "OMR",
      SubjectArea: testTitle,
      TestDateandTime: new Date(),
      Duration: timeDuration,
      NumberofQuestions: test.length,
      Questions: answeredQtn,
    };
    // const testgiv = await dispatch(resultPost(formData));
    // if (testgiv.payload.success === true) {
    //   const data = testgiv.payload.testResult;
    //   dispatch(setrecentAttemptTest(data));
    //   navigate("/review");
    // }
  };

  const renderSwitch = (param) => {
    switch (param) {
      case "IELTS":
        return (
          <>
            <ToeflandIels
              test={currect_questions}
              currentIndex={currentIndex}
              handleAnswer={handleAnswer}
              handleSubmit={handleSubmit}
            />
          </>
        );
      case "TOEFL" || "GMAT" || "SAT" || "ACT" || "GRE":
        return (
          <>
            <RemainingQustion
              test={currect_questions}
              currentIndex={currentIndex}
              handleAnswer={handleAnswer}
              handleSubmit={handleSubmit}
            />
          </>
        );
      default:
        return null;
        break;
    }
  };
  return (
    <>
      <div className="main_container">
        <div className="container_box">
          <Header
            title="Access for your Tests"
            description="We have developed a variety of preparatory tests to ensure your success."
          />
          <div className="headerr_contentrrr">
            <div className="header_questions">
              <div className="content_to_last">
                <div className="inside_containerrr">
                  {/* <div className="container_box">
                    <span className="content_headerrr">Exit</span>
                    <span className="for_iconssss">
                      <IoMdExit />
                    </span>
                  </div> */}
                  {/* <div className="container_box">
                    <span className="content_headerrr">Full</span>
                    <span className="for_iconssss">
                      <BiFullscreen />
                    </span>
                  </div>
                  <div className="container_box">
                    <span className="content_headerrr">Review</span>
                    <span className="for_iconssss">
                      <FaSearch />
                    </span>
                  </div> */}
                  {/* {currentIndex > 0 && (
                    <div
                      className="container_box"
                      onClick={() => setcurrentIndex(currentIndex - 1)}
                    >
                      <span className="content_headerrr">Back</span>
                      <span className="for_iconssss">
                        <IoArrowBackOutline />
                      </span>
                    </div>
                  )}

                  {Number(test.length) - 1 !== Number(currentIndex) && (
                    <div
                      className="container_box"
                      onClick={() => setcurrentIndex(currentIndex + 1)}
                    >
                      <span className="content_headerrr">Next</span>
                      <span
                        className="for_iconssss"
                        id="link_next"
                        style={{ color: "#fff" }}
                      >
                        <FaArrowRight />
                      </span>
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            {renderSwitch(testType)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Questions;
