import React from "react";

const Faq = () => {
  return (
    <>
      <section className="h9_faq-area pt-10 pb-10">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-area-9 text-center mb-40">
                <span
                  className="section-subtitle"
                  style={{
                    color: "#F14D5D",
                  }}
                >
                  Top Popular Questions
                </span>
                <h2 className="section-title mb-0">
                  Explore Popular Questions
                </h2>
              </div>
            </div>
          </div>
          <div className="h9_faq-content">
            <div className="accordion" id="Expp">
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <img src="assets/img/faq/9/1.png" alt />
                        What is F1 Visa?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          F1 Visa is a free tool that allows prospective college
                          students and their families to discover college
                          options based on individual fit and net price of
                          attendance. Our tool collects basic information about
                          academic performance, desired criteria, and financial
                          capacity to determine which colleges fit your
                          abilities, meet your criteria, and fall within your
                          budget.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <img src="assets/img/faq/9/2.png" alt />
                        How does F1 Visa work?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          F1 Visa begins with data provided by colleges annually
                          to the U.S. Department of Education combined and with
                          financial aid reports our users provide about their
                          experience at various colleges. Then F1 Visa evaluates
                          millions of proprietary models about the financial aid
                          practices of U.S. colleges. Based on a user's specific
                          academic and financial profile, F1 Visa estimates the
                          net price for the student to attend specific colleges.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <img src="assets/img/faq/9/3.png" alt />
                        How does F1 Visa find my matches
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          F1 Visa's goal is to find the colleges that meet each
                          student's unique circumstances. College matches are
                          determined by a student's academic profile (test
                          scores and GPA), academic interest (intended major),
                          campus culture (distance from home, total enrollment,
                          etc.), and financial situation.
                        </p>
                    
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <img src="assets/img/faq/9/4.png" alt />
                        Is this service free?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          F1 Visa provides data to users free of charge. We are
                          able to do this by offering advertising and affiliate
                          offers through partner companies and colleges. As
                          described in our Privacy Policy, these partners do not
                          have access to the data that you provide directly to
                          F1 Visa.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <img src="assets/img/faq/9/5.png" alt />
                        How do you use my data?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          We value your privacy. Your data will be kept safe and
                          secure, and we will only provide
                          personally-identifiable information to colleges or
                          other parties if you request it. Please see our
                          Privacy Policy for more information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        <img src="assets/img/faq/9/6.png" alt />
                        Can colleges improve their rankings or how many times
                        they appear as a match for students?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          Our matching and ranking systems are based only on
                          data that we have available about colleges. We take
                          this integrity very seriously and do not allow
                          colleges or universities to ever pay to receive better
                          placement or treatment in our system. The matches that
                          you get are based on the information you provide and
                          the information that we have about each college.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        <img src="assets/img/faq/9/7.png" alt />
                        Can I save my estimates and data and come back to it
                        later?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          Yes. You have the option of creating a user account.
                          This will enable you to access upgraded features and
                          save your settings on our servers via a secure
                          connection to our servers. For more information on the
                          benefits of creating an account, see our Why Register?
                          page.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        <img src="assets/img/faq/9/8.png" alt />
                        Where does the data for F1 Visa come from?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          We provide U.S. government-compliant net price
                          calculators for many colleges. For our campus
                          partners, the price data we present comes directly
                          from those institutions and we display the same
                          estimated cost and scholarship information as would
                          the school's net price calculator (presuming identical
                          inputs). Cost estimates for other colleges are based
                          on our proprietary models of financial aid.
                        </p>
                    
                      
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        <img src="assets/img/faq/9/9.png" alt />
                        Is the net price provided by F1 Visa accurate?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          Our net prices are provided as estimates and may not
                          reflect the actual net price. Generally, our models
                          are able to provide costs that are accurate to within
                          10-20% of the costs provided by actual financial aid
                          reports or as estimated by colleges' net price
                          calculators. Our Results display icons next to each
                          estimate to indicate how accurate we expect each
                          estimate is.
                        </p>
                   
                    
                      </div>
                    </div>
                  </div>
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        <img src="assets/img/faq/9/10.png" alt />
                        What if my net price on Raptor is very different from
                        the one provided by the college's net price calculator?
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          Help us get better! Provide us with data from the
                          college's net price calculator or financial aid award
                          letter and we can use that information to provide
                          better estimates in the future.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEleven"
                        aria-expanded="false"
                        aria-controls="collapseEleven"
                      >
                        <img src="assets/img/faq/9/11.png" alt />
                        Does Raptor work for all types of students?
                      </button>
                    </h2>
                    <div
                      id="collapseEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEleven"
                      data-bs-parent="#Expp"
                    >
                      <div className="accordion-body">
                        <p>
                          Currently, F1 Visa is only able to accurately estimate
                          costs for traditional college-aged students (17-24)
                          who are still considered dependents and who do not
                          have dependents of their own. However, College Raptor
                          can still provide valuable insight for other types of
                          students by finding colleges that fit their academic
                          profile and specific educational goals, as well as
                          price estimates, although they are likely to be less
                          accurate.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
