import React from "react";
import AcademicExcellence from "./AcademicExcellence";
import ResearchPotential from "./ResearchPotential";
import ExtracurricularActivities from "./ExtracurricularActivities";
import OvercomingChallenges from "./OvercomingChallenges";
import SkillsandTeamwork from "./SkillsandTeamwork";
import PassionandEnthusiasm from "./PassionandEnthusiasm";

const Main_draft_letter = ({ data_use }) => {
  const renderSwitch = (param) => {
    switch (param) {
      case "General Academic Excellence":
        return (
          <>
            <AcademicExcellence data_use={data_use} />
          </>
        );
      case "Research Potential":
        return (
          <>
            <ResearchPotential data_use={data_use} />
          </>
        );
      case "Leadership and Extracurricular Activities":
        return (
          <>
            <ExtracurricularActivities data_use={data_use} />
          </>
        );
      case "Overcoming Challenges":
        return (
          <>
            <OvercomingChallenges data_use={data_use} />
          </>
        );
      case "Interpersonal Skills and Teamwork":
        return (
          <>
            <SkillsandTeamwork data_use={data_use} />
          </>
        );
      case "Passion and Enthusiasm for the Field":
        return (
          <>
            <PassionandEnthusiasm data_use={data_use} />
          </>
        );
    }
  };

  return <>{renderSwitch(data_use.drafttype)}</>;
};

export default Main_draft_letter;
