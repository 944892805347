import React from "react";
import Analysis from "./Analysis";

const RemainingQustion = ({
  test,
  currentIndex,
  oIndex,
  handleAnswer,
  handleSubmit,
}) => {
  
  return (
    <>
      {test.map((qustion, index) => (
        <>
          <Analysis
            singleQuestion={qustion}
            index={index}
            key={index}
            oIndex={oIndex}
            handleAnswer={handleAnswer}
            handleSubmit={handleSubmit}
          />
        </>
      ))}
    </>
  );
};

export default RemainingQustion;
