import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
const Baseurl = process.env.REACT_APP_BASE_URL
const initialState = {
  studentDetail: {},
  isStudentloading: true,
  pageStatus: 0,
  studentRegdInfo: localStorage.getItem("studentRegdInfo")
    ? JSON.parse(localStorage.getItem("studentRegdInfo"))
    : "",
  currentFormStep: 0,
};

export const studentEvailVerify = createAsyncThunk(
  "student/studentEvailVerify",
  async (email, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/student/email/${email}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Student not found");
    }
  }
);
export const getStudentDetail = createAsyncThunk(
  "student/getStudentDetail",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/student/studentdetails/${formData._id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Student not found");
    }
  }
);

export const updateStudent = createAsyncThunk(
  "student/updateStudent",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/student/updateby/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Student not able to update");
    }
  }
);

const StudentRegdSlice = createSlice({
  name: "StudentRegd",
  initialState,
  reducers: {
    pageStatuseChange(state, action) {
      state.pageStatus = action.payload;
    },
    removeStudentDetail(state, action) {
      state.studentDetail = {};
    },
    setStudentRegdInfo(state, action) {
      state.studentRegdInfo = action.payload;
      localStorage.setItem("studentRegdInfo", JSON.stringify(state.studentRegdInfo));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentDetail.pending, (state) => {
        state.isStudentloading = true;
      })
      .addCase(getStudentDetail.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.studentDetail = action.payload.student;
          state.currentFormStep = action.payload.student.currentFormStep;
        }
        state.isStudentloading = false;
      })
      .addCase(getStudentDetail.rejected, (state) => {
        state.isStudentloading = true;
      })
      .addCase(updateStudent.pending, (state) => {
        state.isStudentloading = true;
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.studentDetail = action.payload.student;
          state.currentFormStep = action.payload.student.currentFormStep;
        }
        state.isStudentloading = false;
      })
      .addCase(updateStudent.rejected, (state) => {
        state.isStudentloading = true;
      });
  },
});

export const { pageStatuseChange, setStudentRegdInfo, removeStudentDetail } =
  StudentRegdSlice.actions;
export default StudentRegdSlice.reducer;
