import React from 'react'

const Microphone = () => {
    return (
        <div>
            <div>
                <div className='general_instruction'>
                  <div>
                      <div className='contentt_togive_under'>
                        <p className='test_test_gen'>Microphone value</p>
                    </div>

                    <div className='sub_contentttttttt'>
                        <div>
                            <p className='text_contttt'>In order to adjust your microphone volume, please answer the practice question below, using your normal speaking tone and volume. Wait until you hear a beep to respond.
                            </p>
                        </div>
                        <div>

                            <p className='text_contttt'>You will have 15 seconds to speak. If you finish responding before 15 seconds are finished, you can click on <span className='bold_contenttt'>Stop Recording</span>. After you are done recording, click on

                                <span className='bold_contenttt'>Playback Response</span>
                                and listen to your recording. If you cannot hear your response clearly, make necessary adjustments and repeat the microphone check.
                            </p>
                        </div>
                        <div>
                            <p className='text_contttt'>

                                We do not recommend the use of a microphone with a voice activation feature. If you use a microphone with a voice activation feature, the first few seconds of your responses may not be recorded.
                            </p>
                        </div>
                        <div className='with_totallll'>
                            <p>"Describe the city you live in"</p>
                        </div>
                    </div>
                  </div>
                </div>


            </div>
        </div>
    )
}

export default Microphone
