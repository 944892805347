import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Typography, Radio, Col, Button, Space } from "antd";


const AcademicExcellence = ({ data_use }) => {
  const { loginData } = useSelector((store) => store.Athentication);
  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("_blank", "width=800,height=600");

    // Write content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
.main_letter_box {
  width: 100%;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.main_letter_box span {
  color: #333;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: justify;
}

.margin_tops{
  margin-top: 1.5vh;
}
  .tex_capita {
  text-transform: capitalize;
}
         </style>
        </head>
        <body>
                <div class="main_letter_box">
                  <span>${moment(data_use.newdate).format("DD/MM/YYYY")}</span>

                  <span class="margin_tops">${data_use.facultyName}</span>
                  <span>${data_use.facultyTitle}</span>
                  <span>${data_use.facultyDepartment}</span>
                  <span>${data_use.facultyInstitution}</span>
                  <span>
                    ${data_use.facultyCity},${data_use.facultyState},
                    ${data_use.facultyZip}
                  </span>
                  <span>${data_use.facultyEmailcnf}</span>
                  <span>${data_use.mobile}</span>
                  <span>Date</span>

                  <span class="margin_tops">${data_use.universityName}</span>
                  <span>${data_use.universityAddress}</span>
                  <span>
                    ${data_use.universityCity},${data_use.universityState},
                    ${data_use.universityZip}
                  </span>
                  <span class="margin_tops">
                    Dear Members of the Admissions Committee,
                  </span>
                  <span class="margin_tops">
                   I am writing to highly recommend <span>${
                     loginData.firstname
                   } </span>
                   ${loginData.lastname} for admission to ${
      data_use.universityName
    }. I
                   have had the pleasure of teaching <span>${
                     loginData.firstname
                   } </span>
                   ${loginData.lastname} in ${
      data_use.selected_program
    } and have found
                   <span> ${
                     data_use.gender
                   }</span> to be an outstanding student with exceptional
                   academic abilities and a strong commitment to <span>${
                     data_use.gender2
                   } </span>
          studies.
        </span>
        <span class="margin_tops">
          <span>${loginData.firstname} </span> ${
      loginData.lastname
    } consistently
          demonstrated a deep understanding of the course material and a keen
          ability to apply theoretical concepts to practical situations. <span> </span>
          <span class="tex_capita">${data_use.gender2} </span>
          analytical skills, combined with <span>${data_use.gender2} </span>
          enthusiasm for learning, make ${
            data_use.gender
          } an ideal candidate for
          your esteemed program.
        </span>
        <span class="margin_tops">
          In addition to ${data_use.gender2} academic prowess,
          <span> ${loginData.firstname}</span> <span>${
      loginData.lastname
    } </span>
          has shown remarkable interpersonal skills and a collaborative spirit.
          ${data_use.gender3} actively participated in group discussions,
          contributed valuable insights, and supported ${data_use.gender2} peers
          in understanding complex concepts.
        </span>
        <span class="margin_tops">
          I am confident that ${loginData.firstname} ${loginData.lastname} will
          continue to excel and contribute significantly to
          ${data_use.universityName}.
          <span class="tex_capita">${data_use.gender2} </span>
          dedication, intellect, and passion for ${data_use.gender2} field make
          <span> ${
            data_use.gender
          }</span> a deserving candidate for your program.
        </span>
        <span class="margin_tops">
          Please feel free to contact me if you need any further information.
        </span>
                  <span class="margin_tops">Sincerely,</span>
                  <span class="margin_tops">${data_use.facultyName}</span>
                </div>
        </body>
      </html>
    `);

    // Wait for the content to be fully loaded before printing
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
      setTimeout(() => {
        printWindow.close();
      }, 100);
    };
  };
  return (
    <>
      <div className="main_letter_box">
        <span>{moment(data_use.newdate).format("DD/MM/YYYY")}</span>

        <span className="margin_tops">{data_use.facultyName}</span>
        <span>{data_use.facultyTitle}</span>
        <span>{data_use.facultyDepartment}</span>
        <span>{data_use.facultyInstitution}</span>
        <span>
          {data_use.facultyCity},{data_use.facultyState},{data_use.facultyZip}
        </span>
        <span>{data_use.facultyEmailcnf}</span>
        <span>{data_use.mobile}</span>

        <span className="margin_tops">{data_use.Committee}</span>
        <span>{data_use.universityName}</span>
        <span>{data_use.universityAddress}</span>
        <span>
          {data_use.universityCity},{data_use.universityState},
          {data_use.universityZip}
        </span>
        <span className="margin_tops">
          Dear Members of the Admissions Committee,
        </span>
        <span className="margin_tops">
          I am writing to highly recommend <span>{loginData.firstname} </span>
          {loginData.lastname} for admission to {data_use.universityName}. I
          have had the pleasure of teaching <span>{loginData.firstname} </span>
          {loginData.lastname} in {data_use.selected_program} and have found
          <span> {data_use.gender}</span> to be an outstanding student with
          exceptional academic abilities and a strong commitment to{" "}
          <span>{data_use.gender2} </span>
          studies.
        </span>
        <span className="margin_tops">
          <span>{loginData.firstname} </span> {loginData.lastname} consistently
          demonstrated a deep understanding of the course material and a keen
          ability to apply theoretical concepts to practical situations.{" "}
          <span> </span>
          <span className="tex_capita">{data_use.gender2} </span>
          analytical skills, combined with <span>{data_use.gender2} </span>
          enthusiasm for learning, make {data_use.gender} an ideal candidate for
          your esteemed program.
        </span>
        <span className="margin_tops">
          In addition to {data_use.gender2} academic prowess,
          <span> {loginData.firstname}</span> <span>{loginData.lastname} </span>
          has shown remarkable interpersonal skills and a collaborative spirit.
          {data_use.gender3} actively participated in group discussions,
          contributed valuable insights, and supported {data_use.gender2} peers
          in understanding complex concepts.
        </span>
        <span className="margin_tops">
          I am confident that {loginData.firstname} {loginData.lastname} will
          continue to excel and contribute significantly to
          {data_use.universityName}.
          <span className="tex_capita">{data_use.gender2} </span>
          dedication, intellect, and passion for {data_use.gender2} field make
          <span> {data_use.gender}</span> a deserving candidate for your
          program.
        </span>
        <span className="margin_tops">
          Please feel free to contact me if you need any further information.
        </span>
        <span className="margin_tops">Sincerely,</span>
        <span className="margin_tops">{data_use.facultyName}</span>
        <div className="button_container">
          <Button
            type="primary"
            size="large"
            onClick={() => handlePrint(false)}
          >
            Print
          </Button>
        </div>
      </div>
    </>
  );
};

export default AcademicExcellence;
